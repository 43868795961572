import React, { createRef } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// Application Configuration
import config from "../Config";
import dayjs from "dayjs";

import {
  Typography,
  makeStyles,
  Box,
  useMediaQuery,
  useTheme,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
  InputLabel,
  Button,
  TextField,
  Paper,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import html2canvas from "html2canvas";
import { Code, Print } from "@material-ui/icons";
import { doc, onSnapshot } from "firebase/firestore";
import { dbQA } from "../../../components/Firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backdropFilter: "blur(2px)", // Add a backdrop filter for blur effect
    transform: "scale(1.05)", // Add a scaling effect
  },
  gridContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },

  button: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: "Poppins",
  },
  list: {
    width: 250,
  },
  input: {
    fontWeight: "bold",
    borderRadius: "10px",
    fontFamily: "Poppins",
  },
  clickButton: {
    fontWeight: "bold",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    minHeight: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    textAlign: "center",
    textTransform: "none",
    marginLeft: "0.5rem",
    backgroundColor: "#187CAE",
    color: "white",
    fontSize: "1.6vh",
    fontFamily: "Poppins",
  },
  tableHead: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "16px",
  },
  tableContent: {
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  card: {
    marginTop: "1rem",
    marginBottom: "1rem",
    backgroundColor: "#f5f5f5",
    borderRadius: "0px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.3)",
    padding: "1rem",
    marginLeft: "1rem",
  },
  serviceName: {
    marginBottom: "0.5rem",
    fontFamily: "Poppins",
    fontWeight: "bold",
  },
  serviceInfo: {
    marginBottom: "0.25rem",
    display: "inline-block",
    fontFamily: "Poppins",
  },
  itemText: {
    fontFamily: "Poppins",
    color: "Black",
    fontSize: "14px",
    fontWeight: 500,
  },
}));

function ProDetails() {
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const stateParam = searchParams.get("state");

  // Destructure the state data
  const { Services, FirstName, LastName, ProId } = JSON.parse(
    decodeURIComponent(stateParam)
  );

  // Now you can use the destructured state data in your component
  // console.log(Services, FirstName, LastName, ProId);

  const history = useHistory();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const ref = createRef(null);

  const [scheduledquotes, setscheduledquotes] = React.useState([]);
  const [totalRevenue, settotalRevenue] = React.useState();

  const [isQAToggled, setQAToggled] = React.useState(
    localStorage.getItem("isQAToggled") === "true" || false
  );

  const [checkState, setCheckState] = React.useState(false);
  const [weekNo, setWeekNo] = React.useState("");
  const [monthNo, setMonthNo] = React.useState("");
  const [totalJobsDone, setTotalJobsDone] = React.useState();
  const [paidAmount, setPayedAmount] = React.useState();
  const [toBePaidAmount, setToBePayedAmount] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [loadingList, setLoadingList] = React.useState(false);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const weeks = ["1", "2", "3", "4", "5"];

  const handleKeyPress = async (
    e,
    price,
    index,
    ServiceID,
    UserID,
    Subscription
  ) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of creating a new line

      const { data } = await axios.post(
        config.PRO_UPDATE_SERVICE,
        {
          UID: ProId,
          Index: index,
          JobStatus: "",
          ServicePlan: "",
          ProPrice: price,
          ServiceID: ServiceID,
          Subscription: Subscription,
          UserID: UserID,
          WeekNo: weekNo,
          MonthNo: monthNo,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
          },
        }
      );
      alert(data.message);
    }
  };

  const fetchDataMonth = async (e) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        config.CURRENT_PRO_SCHEDULE,
        //"http://localhost:4008/proCurrentDaySchedule",
        {
          UID: ProId,
          MonthNo: e.target.value,
          WeekNo: weekNo,
          // MonthNo: monthNo,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Mode": isQAToggled ? "QA" : "PROD",
          },
        }
      );

      setscheduledquotes(data);
      console.log(scheduledquotes, "Updated scheduled");
    } catch (error) {
      // Handle error and display error message
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataWeek = async (e) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        config.CURRENT_PRO_SCHEDULE,
        // "http://localhost:4008/proCurrentDaySchedule",
        {
          UID: ProId,
          MonthNo: monthNo,
          WeekNo: e.target.value,
          // MonthNo: monthNo,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Mode": isQAToggled ? "QA" : "PROD",
          },
        }
      );

      setscheduledquotes(data);
      console.log(scheduledquotes, "Updated scheduled");
    } catch (error) {
      // Handle error and display error message
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const jobType = [
    // { color: "#D4EDBC", type: "Spring CleanUp - Full" },
    // { color: "#D4EDBC", type: "Spring CleanUp Basic - no aerate" },
    // { color: "#BFE1F6", type: "Mow Weekly" },
    // { color: "#BFE1F6", type: "Mow Bi-Weekly" },
    // { color: "#BFE1F6", type: "Mow Weekly - Fertilizer" },
    // { color: "#BFE1F6", type: "Mow Bi-Weekly - Fertilizer" },
    // { color: "#BFE1F6", type: "Mow Weekly - Fertilizer + Spot Seeding" },
    // { color: "#BFE1F6", type: "Mow Bi-Weekly - Fertilizer + Spot Seeding" },
    // { color: "#BFE1F6", type: "Mow Weekly - Fertilizer + Bag Clipping" },
    // { color: "#BFE1F6", type: "Mow Bi-Weekly - Fertilizer + Bag Clipping" },
    // { color: "#D4EDBC", type: "Mulch" },
    // { color: "#D4EDBC", type: "Hedge Trimming" },
    // { color: "pink", type: "Landscape - mulch, stonepath" },
    // { color: "#d6d1d1", type: "Hold, Ask Eric" },
    { color: "#D4EDBC", type: "Spring CleanUp" },
    { color: "#D4EDBC", type: "Fall CleanUp" },
    { color: "#D4EDBC", type: "Mulch" },
    { color: "#BFE1F6", type: "Weekly Mow" },
    { color: "#BFE1F6", type: "Bi-Weekly Mow" },
    { color: "#BFE1F6", type: "Weekly Mow + Fertilizer" },
    { color: "#BFE1F6", type: "Bi-Weekly Mow + Fertilizer" },
    { color: "#BFE1F6", type: "Weekly Mow (S)" },
    { color: "#BFE1F6", type: "Bi-Weekly Mow (S)" },
    { color: "#D4EDBC", type: "Weekly Mow (CDH)" },
    { color: "#D4EDBC", type: "Bi-Weekly (JBC)" },
    { color: "#D4EDBC", type: "Weekly Mow (JUN)" },
    { color: "#D4EDBC", type: "Spring CleanUp (JUN)" },
    { color: "#D4EDBC", type: "Fall CleanUp (JUN)" },
    { color: "#D4EDBC", type: "Spring CleanUp (JBC)" },
    { color: "#D4EDBC", type: "Fall CleanUp (JBC)" },
    { color: "#D4EDBC", type: "Spring CleanUp CDH)" },
    { color: "#D4EDBC", type: "Fall CleanUp (CDH)" },
    { color: "#d6d1d1", type: "Hold, Ask Eric" },
  ];

  const jobStatus = [
    { color: "#D4EDBC", type: "Complete Paid" },
    { color: "#bfe1f6", type: "Complete Not Paid" },
    { color: "#e5cff1", type: "Not Started" },
    { color: "#ffe59f", type: "Service This Week" },
    { color: "#BFE1F6", type: "Service Next Week" },
    { color: "#d6d1d1", type: "Not Customer Yet" },
    { color: "#f4b5b5", type: "Cancelled Service" },
    { color: "#f5f6aa", type: "Need an estimate and description" },
  ];

  React.useEffect(async () => {
    // Check if the admin user is logged in
    const admin = JSON.parse(window.localStorage.getItem("isAdmin"));
    if (admin === false) {
      history.push("/");
      return;
    } else {
      currentDaySchedule();
      return;
    }
  }, [checkState]);

  React.useEffect(async () => {
    var jobNumber = 0;
    var paid = 0;
    var toPay = 0;
    scheduledquotes?.forEach((item) => {
      console.log(item.JobStatusPro, "Jobv");
      if (
        item.JobStatusPro === "Complete Paid" ||
        item.JobStatusPro === "Complete Not Paid"
      ) {
        jobNumber += 1;
      }
      if (item.JobStatusPro === "Complete Paid") {
        paid += item.ProPrice;
      }
      if (item.JobStatusPro === "Complete Not Paid") {
        toPay += item.ProPrice;
      }
    });

    setTotalJobsDone(jobNumber);
    setToBePayedAmount(toPay);
    setPayedAmount(paid);
    settotalRevenue(
      scheduledquotes?.reduce((total, quote) => {
        if (quote.JobStatusPro !== "Cancelled Service") {
          return total + quote.ProPrice;
        }
        return total;
      }, 0)
    );
  }, [scheduledquotes]);

  const [draggedItem, setDraggedItem] = React.useState(null);

  const currentDaySchedule = async () => {
    var currentDate = dayjs();
    currentDate = currentDate.add(-17.5, "hour"); //Shankar: This is to sync the week number with the weekly job list generated at 8am EST time
    const currentYear = currentDate.year().toString();
    const currentMonth = currentDate.format("MMMM").toString();
    const startOfMonth = currentDate.startOf("month");
    const currentWeekOfMonth = Math.ceil(
      (currentDate.diff(startOfMonth, "day") + startOfMonth.day() + 1) / 7
    );

    setWeekNo(weekNo ? weekNo : currentWeekOfMonth);
    setMonthNo(monthNo ? monthNo : currentMonth);

    const { data } = await axios.post(
      config.CURRENT_PRO_SCHEDULE,
      //"http://localhost:4008/proCurrentDaySchedule",
      {
        UID: ProId,
        WeekNo: `${weekNo}`,
        MonthNo: `${monthNo}`,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
        },
      }
    );

    setscheduledquotes(data);
  };

  React.useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  // User has switched back to the tab
  const onFocus = () => {
    console.log("Tab is in focus");
    setCheckState(Math.random().toString(36).slice(2));
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    console.log("Tab is blurred");
  };

  const handleDragStart = (e, item) => {
    setDraggedItem(item);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    const items = Array.from(scheduledquotes);
    const draggedItemIndex = items.findIndex((item) => item === draggedItem);
    items.splice(draggedItemIndex, 1);
    items.splice(index, 0, draggedItem);
    setscheduledquotes(items);
    setDraggedItem(null);
  };

  const updateData = async () => {
    setLoadingList(true);
    const { data } = await axios.post(
      config.UPDATE_SCHEDULE_LIST,
      {
        ProId: ProId,
        WeekNo: weekNo,
        ListToUpdate: scheduledquotes,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-Mode": "QA", // Set the custom header "X-Mode" with the mode value
        },
      }
    );
    alert(data.message);
    setLoadingList(false);
  };

  const downloadScreenshot = () => {
    const node = document.getElementById("tableToPrint");

    // Remove cells beyond the fifth column
    const cellsToKeep = node.querySelectorAll("td:nth-child(n+6)");
    cellsToKeep.forEach((cell) => (cell.style.display = "none"));

    // Remove headers beyond the fifth column
    const headersToKeep = node.querySelectorAll("th:nth-child(n+6)");
    headersToKeep.forEach((header) => (header.style.display = "none"));

    // useCors to draw image from different origin
    html2canvas(node, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      // Show the previously hidden cells and headers
      cellsToKeep.forEach((cell) => (cell.style.display = ""));
      headersToKeep.forEach((header) => (header.style.display = ""));

      // Create a link element to trigger the download
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.download = `${dayjs().format("YYYY-MM-DD")}.png`;
      a.href = canvas.toDataURL();
      a.click();

      // Clean up the link element
      document.body.removeChild(a);
    });
  };

  const weeklyApi = async () => {
    setLoadingList(true);

    try {
      const { data } = await axios.post(
        config.PRO_SCHEDULE_API,
        // "http://localhost:4008/ThisWeekServiceList",
        {
          service: "LawnMowing,SpringCleanup,Mulch",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Mode": isQAToggled ? "QA" : "PROD",
          },
        }
      );
    } catch (error) {
      // Handle error and display error message
      console.error("Error in Weekly Api:", error);
    } finally {
      setCheckState(Math.random().toString(36).slice(2));
      setLoadingList(false);
    }
  };

  return (
    <>
      {/* Loading Screen Backdrop */}
      <Backdrop open={loadingList} className={classes.backdrop}>
        <CircularProgress color="primary" size={50} thickness={5} />
        <Typography
          variant="h6"
          color="inherit"
          style={{ fontWeight: "bolder", marginLeft: "1%", fontSize: "3vh" }}
        >
          Hang tight, we're almost there!
        </Typography>
      </Backdrop>

      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => {
                history.push("/AdminProSignup");
              }}
            >
              <ExitToAppIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {FirstName} {LastName} / {ProId}
            </Typography>
            <Button
              // color="primary"
              variant="contained"
              startIcon={<Print />}
              onClick={downloadScreenshot}
              style={{ textTransform: "none" }}
            >
              Print
            </Button>
            <Button
              variant="contained"
              startIcon={<Code />}
              onClick={weeklyApi}
              style={{ textTransform: "none", marginLeft: "10px" }}
            >
              Weekly API
            </Button>
          </Toolbar>
        </AppBar>

        <FormControl
          sx={{ m: 1, width: 300 }}
          variant="outlined"
          style={{
            height: "30px",
            marginTop: "3%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "16px",
              marginRight: "8px",
            }}
          >
            <label htmlFor="month-select">Select Month:</label>
            <select
              id="month-select"
              value={monthNo}
              onChange={(e) => {
                setMonthNo(e.target.value);
                fetchDataMonth(e);
              }}
              style={{
                width: "150px",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#fff",
                fontSize: "14px",
                color: "#333",
              }}
            >
              <option value="">Select a month</option>
              {months.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "16px",
              marginRight: "8px",
            }}
          >
            <label htmlFor="weeks-select">Select Week:</label>
            <select
              id="weeks-select"
              value={weekNo}
              onChange={(e) => {
                setWeekNo(e.target.value);
                fetchDataWeek(e);
              }}
              style={{
                width: "150px",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#fff",
                fontSize: "14px",
                color: "#333",
              }}
            >
              <option value="">Select a Week</option>
              {weeks.map((week, index) => (
                <option key={index} value={week}>
                  {week}
                </option>
              ))}
            </select>
          </div>
        </FormControl>

        {/* Grid for Task Summary */}

        <div
          style={{
            display: "flex",
            marginTop: "2%",
            justifyContent: "center",
            marginBottom: "2%",
          }}
        >
          <Paper
            style={{
              maxWidth: "300px",
              width: "100%",
              padding: "1%",
              backgroundColor: "#E2F6E9",
              marginRight: "3%",
            }}
          >
            <h5 style={{ padding: "1%", color: "#596672" }}>
              Expected Total PayOut
            </h5>
            <h2 style={{ color: "#3E995B" }}>
              {totalRevenue ? (
                <span>${totalRevenue?.toFixed(2)}</span>
              ) : (
                <span>$0</span>
              )}
            </h2>
          </Paper>

          <Paper
            style={{
              maxWidth: "300px",
              width: "100%",
              padding: "1%",
              backgroundColor: "#E5F6FF",
              marginRight: "3%",
            }}
          >
            <h5 style={{ padding: "1%", color: "#596672" }}>Paid Amount</h5>
            <h2 style={{ color: "#2C71FF" }}>
              {paidAmount ? (
                <span>${paidAmount.toFixed(2)}</span>
              ) : (
                <span>$0</span>
              )}
            </h2>
          </Paper>

          <Paper
            style={{
              maxWidth: "300px",
              width: "100%",
              padding: "1%",
              backgroundColor: "#E5F6FF",
              marginRight: "3%",
            }}
          >
            <h5 style={{ padding: "1%", color: "#596672" }}>Pending PayOut</h5>
            <h2 style={{ color: "#2C71FF" }}>
              {toBePaidAmount ? (
                <span>${toBePaidAmount.toFixed(2)}</span>
              ) : (
                <span>$0</span>
              )}
            </h2>
          </Paper>

          <Paper
            style={{
              maxWidth: "300px",
              width: "100%",
              padding: "1%",
              backgroundColor: "#E5F6FF",
              marginRight: "3%",
            }}
          >
            <h5 style={{ padding: "1%", color: "#596672" }}>Job's Done</h5>
            <h2 style={{ color: "#2C71FF" }}>
              {totalJobsDone ? (
                <span>
                  {totalJobsDone}/{scheduledquotes?.length}
                </span>
              ) : (
                <span>0/{scheduledquotes?.length}</span>
              )}
            </h2>
          </Paper>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "80%",
            margin: "0 auto",
            marginBottom: "3vh",
            background: "#F9FAFC",
            padding: "10px",
          }}
        >
          <Typography
            className={classes.tableHead}
            style={{ fontSize: "20px", textAlign: "left" }}
          >
            Services
          </Typography>
          <Button
            onClick={updateData}
            variant="contained"
            color="primary"
            size="small"
            style={{
              textTransform: "none",
            }}
            disabled={loadingList}
          >
            {loadingList ? <CircularProgress size={24} /> : "Save Priority"}
          </Button>
        </div>

        {scheduledquotes.length > 0 ? (
          <Table
            ref={ref}
            id="tableToPrint"
            size="small"
            style={{ marginTop: "2%", width: "80%", margin: "0 auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.tableHead}
                  style={{ maxWidth: "10px" }}
                >
                  #
                </TableCell>
                <TableCell className={classes.tableHead}>ServDate</TableCell>
                <TableCell className={classes.tableHead} align="center">
                  Address
                </TableCell>
                <TableCell className={classes.tableHead} align="center">
                  Job Type
                </TableCell>
                <TableCell className={classes.tableHead} align="center">
                  Pay($)
                </TableCell>
                <TableCell className={classes.tableHead} align="center">
                  Job Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scheduledquotes
                ? scheduledquotes.map((payload, index) => (
                    <TableRow
                      draggable
                      onDragStart={(e) => handleDragStart(e, payload)}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, index)}
                    >
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          maxWidth: "3%",
                          fontSize: "1.85vh",
                        }}
                        contentEditable={true}
                        onBlur={(event) => {
                          const newNumber = parseInt(event.target.innerText);
                          if (
                            !isNaN(newNumber) &&
                            newNumber >= 1 &&
                            newNumber <= scheduledquotes.length
                          ) {
                            const draggedItem = scheduledquotes[index];
                            const newIndex = newNumber - 1;

                            setscheduledquotes((prevQuotes) => {
                              const updatedQuotes = [...prevQuotes];
                              updatedQuotes.splice(index, 1); // Remove the dragged item
                              updatedQuotes.splice(newIndex, 0, draggedItem); // Insert the dragged item at the new index

                              // Update the index values for all items
                              const updatedQuotesWithIndex = updatedQuotes.map(
                                (quote, i) => ({
                                  ...quote,
                                  index: i,
                                })
                              );

                              return updatedQuotesWithIndex;
                            });
                          }
                        }}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault(); // Prevent the default behavior of adding a new line on Enter press
                            event.target.blur(); // Trigger the blur event to update the array
                          }
                        }}
                      >
                        {payload.hasOwnProperty("index")
                          ? payload.index + 1
                          : index + 1}
                      </TableCell>

                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.85vh",
                          minWidth: "120px",
                        }}
                      >
                        {payload.ServiceDate}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.85vh",
                          color: payload.hasOwnProperty("New") ? "red" : "",
                          minWidth: "300px",
                        }}
                        align="left"
                      >
                        {payload.hasOwnProperty("ServiceAddr")
                          ? payload.ServiceAddr?.split(", USA")
                          : payload.Address?.split(", USA")}
                      </TableCell>
                      <TableCell style={{ minWidth: "270px" }}>
                        <FormControl
                          sx={{ m: 1, width: 300 }}
                          fullWidth
                          variant="outlined"
                          style={{ maxheight: "30px", height: "30px" }}
                        >
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={payload.ServicePlan}
                            onChange={async (e) => {
                              setscheduledquotes((prevPayload) => {
                                const updatedPayload = prevPayload.map(
                                  (quote, i) => {
                                    if (i === index) {
                                      return {
                                        ...quote,
                                        ServicePlan: e.target.value,
                                      };
                                    }
                                    return quote;
                                  }
                                );
                                return updatedPayload;
                              });
                              const { data } = await axios.post(
                                config.PRO_UPDATE_SERVICE,
                                {
                                  UID: ProId,
                                  Index: index,
                                  ServicePlan: e.target.value,
                                  JobStatus: "",
                                  ProPrice: "",
                                  ServiceID: payload.ServiceID,
                                  Subscription: payload.ServiceCat,
                                  UserID: payload.UID,
                                  WeekNo: weekNo,
                                  MonthNo: monthNo,
                                },
                                {
                                  headers: {
                                    "Content-Type": "application/json",
                                    "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
                                  },
                                }
                              );
                              // alert(data.message);
                            }}
                            style={{
                              maxheight: "30px",
                              height: "30px",
                              borderRadius: "5px",
                            }}
                          >
                            {jobType.map((name, index) => (
                              <MenuItem
                                disableRipple
                                key={index}
                                value={name.type}
                              >
                                <div
                                  style={{
                                    fontSize: "1.6vh",
                                    background: name.color,
                                    width: "100%",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {name.type}
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>

                      <TableCell
                        style={{ fontSize: "2vh" }}
                        contentEditable={true}
                        onBlur={(event) => {
                          payload.ProPrice = event.target.innerText;
                        }}
                        onKeyPress={(event) =>
                          handleKeyPress(
                            event,
                            +event.target.innerText,
                            index,
                            payload.ServiceID,
                            payload.UID,
                            payload.ServiceCat
                          )
                        }
                      >
                        {payload.ProPrice}
                      </TableCell>
                      <TableCell>
                        <FormControl
                          sx={{ m: 1, width: 300 }}
                          fullWidth
                          variant="outlined"
                          style={{ maxheight: "30px", height: "30px" }}
                        >
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={payload.JobStatusPro}
                            onChange={async (e) => {
                              if (e.target.value === "Complete Paid") {
                                const completeNotPaidOption =
                                  scheduledquotes.find(
                                    (quote) =>
                                      quote.JobStatusPro === "Complete Not Paid"
                                  );
                                if (!completeNotPaidOption) {
                                  alert(
                                    'Please select "Complete Not Paid" first and then click "Complete Paid"'
                                  );
                                  return;
                                } else {
                                  setscheduledquotes((prevPayload) => {
                                    const updatedPayload = prevPayload.map(
                                      (quote, i) => {
                                        if (i === index) {
                                          return {
                                            ...quote,
                                            JobStatusPro: e.target.value,
                                          };
                                        }
                                        return quote;
                                      }
                                    );

                                    return updatedPayload;
                                  });
                                  const { data } = await axios.post(
                                    config.PRO_UPDATE_SERVICE,
                                    {
                                      UID: ProId,
                                      Index: index,
                                      JobStatus: e.target.value,
                                      ServicePlan: "",
                                      ProPrice: "",
                                      ServiceID: payload.ServiceID,
                                      Subscription: payload.ServiceCat,
                                      UserID: payload.UID,
                                      WeekNo: weekNo,
                                      MonthNo: monthNo,
                                    },
                                    {
                                      headers: {
                                        "Content-Type": "application/json",
                                        "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
                                      },
                                    }
                                  );
                                }
                              } else {
                                setscheduledquotes((prevPayload) => {
                                  const updatedPayload = prevPayload.map(
                                    (quote, i) => {
                                      if (i === index) {
                                        return {
                                          ...quote,
                                          JobStatusPro: e.target.value,
                                        };
                                      }
                                      return quote;
                                    }
                                  );

                                  return updatedPayload;
                                });
                                const { data } = await axios.post(
                                  config.PRO_UPDATE_SERVICE,
                                  {
                                    UID: ProId,
                                    Index: index,
                                    JobStatus: e.target.value,
                                    ServicePlan: "",
                                    ProPrice: "",
                                    ServiceID: payload.ServiceID,
                                    Subscription: payload.ServiceCat,
                                    UserID: payload.UID,
                                    WeekNo: weekNo,
                                    MonthNo: monthNo,
                                  },
                                  {
                                    headers: {
                                      "Content-Type": "application/json",
                                      "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
                                    },
                                  }
                                );
                              }

                              // alert(data.message);
                            }}
                            style={{
                              maxheight: "30px",
                              height: "30px",
                              borderRadius: "3px",
                            }}
                          >
                            {jobStatus.map((name, index) => (
                              <MenuItem key={index} value={name.type}>
                                <div
                                  style={{
                                    fontSize: "1.6vh",
                                    background: name.color,
                                    width: "100%",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {name.type}
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        ) : (
          <h1
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            No jobs found for this Week
          </h1>
        )}
      </div>
    </>
  );
}

export default ProDetails;
