import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Backdrop,
  CircularProgress,
  Card,
  CardContent,
} from "@material-ui/core";
import axios from "axios";
import config from "../Config";
import { storageQA } from "../../../components/Firebase";
import { storageNYU } from "../../../components/Firebase";

import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

const useStyles = makeStyles((theme) => ({
  greyInput: {
    border: "none !important",
    borderRadius: "5px",
    padding: "8px 10px",
    marginTop: "2px",
    marginBottom: "10px",
    backgroundColor: "#d1d2d2",
  },
}));

const BlogCreate = () => {
  const classes = useStyles();
  const fileInputRef = React.useRef(null);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [blogData, setBlogData] = React.useState({
    Title: "",
    Description: "",
    Content: {
      Paragraph1: "",
    },
    ImageURL: "",
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState("");

  const addContentField = () => {
    const nextContentKey = `Paragraph${
      Object.keys(blogData.Content).length + 1
    }`;
    setBlogData((prevData) => ({
      ...prevData,
      Content: {
        ...prevData.Content,
        [nextContentKey]: "", // Add a new key with an empty value
      },
    }));
  };

  const removeContentField = (contentKey) => {
    if (contentKey !== "Paragraph1") {
      const newState = { ...blogData.Content };

      // Remove the specified key from the copy
      delete newState[contentKey];

      setBlogData((prevData) => ({
        ...prevData,
        Content: newState,
      }));
    }
  };

  const handleContentChange = (key, value) => {
    setBlogData((prevData) => ({
      ...prevData,
      Content: {
        ...prevData.Content,
        [key]: value,
      },
    }));
  };

  const handleContentPaste = (key, event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text/plain");
    const cleanedText = pastedText.replace(/\s+/g, " ");
    handleContentChange(key, cleanedText);
  };

  const handleImageUpload = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };

  const handleSubmitTheBlog = async (e) => {
    e.preventDefault();

    const contentKeys = Object.keys(blogData.Content);

    if (!blogData.Title) {
      alert("Title is empty");
      return;
    }

    if (!blogData.Description) {
      alert("Description is empty");
      return;
    }

    for (const key of contentKeys) {
      if (!blogData.Content[key]) {
        alert(`${key} is empty`);
        return;
      }
    }

    setIsSubmitting(true);

    try {
      if (image) {
        // Specify the path to the "blogs" folder and the image name
        const imageRef = ref(storageNYU, "Blogs/" + image.name);
        const snapshot = await uploadBytes(imageRef, image);
        const url = await getDownloadURL(snapshot.ref);

        // Update the blogData with the image URL
        setBlogData((data) => ({
          ...data,
          ImageURL: url,
        }));

        const { data } = await axios.post(config.BLOG_CREATE, {
          BlogDetails: {
            Title: blogData["Title"],
            Description: blogData["Description"],
            Content: blogData["Content"],
            ImageURL: url,
          },
        });

        if (data.status === "success") {
          setBlogData((prevData) => ({
            ...prevData,
            Title: "",
            Description: "",
            Content: {
              Paragraph1: "",
            },
            ImageURL: "",
          }));
          setImage(null);
          fileInputRef.current.value = "";
          alert(data.message);
        } else {
          alert(data.message);
        }
      } else {
        // If no image is selected, send data to the backend without an image URL
        const { data } = await axios.post(config.BLOG_CREATE, {
          BlogDetails: {
            Title: blogData["Title"],
            Description: blogData["Description"],
            Content: blogData["Content"],
            ImageURL: "",
          },
        });

        if (data.status === "success") {
          setBlogData((prevData) => ({
            ...prevData,
            Title: "",
            Description: "",
            Content: {
              Paragraph1: "",
            },
            ImageURL: "",
          }));
          alert(data.message);
        } else {
          alert(data.message);
        }
      }
    } catch (error) {
      console.error("Error uploading blog:", error);
      alert("Error uploading blog");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#b3e2ec",
        minHeight: "100vh", // Set the background to cover the full screen height
      }}
    >
      <Grid
        xl={12}
        lg={12}
        sm={12}
        xs={12}
        md={12}
        container
        style={{
          backgroundColor: "#b3e2ec",
          width: "100%",
          paddingLeft: "7%",
          paddingRight: "7%",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container xl={12} alignItems="center">
          <Grid item xs={12}>
            <form
              autoComplete="off"
              style={{
                display: "grid",
                placeItems: "center",
                padding: "3vh",
                width: matchesSm ? "100%" : "60%",
                margin: "3% auto 0",
                background: "white",
                borderRadius: "5px",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontFamily: "poppins",
                  textAlign: matchesSm ? "justify" : "center",
                  fontSize: "3vh",
                  lineHeight: "30px",
                  fontWeight: "bolder",
                }}
              >
                Create a Blog{" "}
              </Typography>

              <Grid
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "100%",
                }}
                lg={12}
                item
              >
                <label
                  htmlFor="firstname"
                  style={{ fontWeight: "bold", textAlign: "left" }}
                >
                  Title
                </label>
                <input
                  required
                  type="text"
                  placeholder="Enter a fitting title for the blog"
                  id="fname"
                  className={classes.greyInput}
                  value={blogData.Title}
                  onChange={(e) => {
                    setBlogData((key) => ({
                      ...key,
                      Title: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "100%",
                }}
                lg={12}
                item
              >
                <label
                  htmlFor="lastname"
                  style={{ fontWeight: "bold", textAlign: "left" }}
                >
                  Description
                </label>
                <textarea
                  required
                  type="text"
                  placeholder="Enter a catching description for the blog"
                  id="lname"
                  className={classes.greyInput}
                  value={blogData.Description}
                  onChange={(e) => {
                    // const trimmedValue = e.target.value.replace(/\s+/g, ' ').trim();
                    setBlogData((key) => ({
                      ...key,
                      Description: e.target.value,
                    }));
                  }}
                ></textarea>
              </Grid>
              <Grid
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "100%",
                }}
                lg={12}
                item
              >
                <label
                  htmlFor="lastname"
                  style={{ fontWeight: "bold", textAlign: "left" }}
                >
                  Upload Image
                </label>
                <input
                  className={classes.greyInput}
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  ref={fileInputRef}
                />
              </Grid>
              <Grid
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "100%",
                }}
                lg={12}
                item
              >
                {Object.keys(blogData.Content).map((contentKey) => (
                  <>
                    <label
                      htmlFor={`content-${contentKey}`}
                      style={{ fontWeight: "bold", textAlign: "left" }}
                    >
                      {`Content ${contentKey}`}
                      {contentKey !== "Paragraph1" && (
                        <span>
                          <button
                            onClick={() => removeContentField(contentKey)}
                            style={{
                              marginLeft: "20px",
                              backgroundColor: "red",
                              color: "white",
                              fontWeight: "bold",
                              fontFamily: "poppins",
                              width: "2vw",
                              borderRadius: "10px",
                              textAlign: "center",
                              fontSize: "1.8vh",
                            }}
                          >
                            X
                          </button>
                        </span>
                      )}
                    </label>

                    <textarea
                      required
                      type="text"
                      placeholder={`Use /* Your Heading Here */ to create a heading and then Enter ${contentKey} text`}
                      id={`content-${contentKey}`}
                      className={classes.greyInput}
                      value={blogData.Content[contentKey]}
                      onChange={(e) => {
                        handleContentChange(contentKey, e.target.value);
                      }}
                      onPaste={(e) => handleContentPaste(contentKey, e)}
                      style={{
                        wordWrap: "break-word",
                        resize: "vertical",
                        // Add any additional styles for the textarea.
                      }}
                    />
                  </>
                ))}

                {/* Plus icon to add more content fields */}
                <button
                  onClick={addContentField}
                  style={{
                    fontSize: "1.8vh",
                    fontWeight: "bold",
                    margin: "0",
                    padding: "5px",
                    marginRight: "auto",
                    borderRadius: "10px",
                    backgroundColor: "green",
                    color: "white",
                  }}
                >
                  + Add Paragraph
                </button>
              </Grid>
              <button
                className="buttonPrimary2"
                id="submit"
                style={{
                  fontSize: "2vh",
                  backgroundColor: "#187CAE",
                  marginTop: "20px",
                  marginBottom: "10px",
                  borderRadius: "5px",
                  width: matchesSm ? "100%" : "30%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                onClick={(e) => {
                  handleSubmitTheBlog(e);
                }}
              >
                {isSubmitting ? (
                  <CircularProgress
                    style={{ color: "white", width: "20px", height: "20px" }}
                  />
                ) : (
                  "Submit The Blog"
                )}
              </button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BlogCreate;
