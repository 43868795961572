// Bank.js    Created 9/4/2022    Rev 4.8

// Purpose:
// This is a subcomponent for Prosignup page.
//  This code provides UI for bank information.

import { Divider, Select, FormControl, MenuItem } from "@material-ui/core";
import React from "react";
import useStyles from "../../New Home/Styles";
import AccountDetails from "../../../components/assets/images/AccountDetails.png";

function Bank({ formData, setFormData }) {
  const classes = useStyles();

  return (
    <div>
      <h2 className={classes.h2}>Bank Integration</h2>
      <p className={classes.p}>
        Works with Stripe, a trusted billing platform, to process all payments
        and keep our providers secure.
      </p>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Divider
          style={{
            backgroundColor: "black",
            width: "80vw",
            marginBottom: "2%",
          }}
        />
      </div>

      <div
        style={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <form className={classes.loginForm}>
          <label htmlFor="firstname">Legal Entity Type</label>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              variant="standard"
              color="primary"
              className="signUpInput"
              style={{
                maxHeight: "45px",
              }}
              id="demo-simple-select"
              value={formData.entityType}
              label="Entity Type"
              onChange={(e) => {
                setFormData({ ...formData, entityType: e.target.value });
              }}
            >
              <MenuItem value="Individual">Individual</MenuItem>
              <MenuItem value="Business">Business</MenuItem>
            </Select>
          </FormControl>

          <label htmlFor="address">Legal Address</label>
          <input
            required
            type="text"
            id="laddress"
            className="signUpInput"
            placeholder="Enter your full address"
            style={{
              border: "1px solid #EFF2F7 !important",
              borderRadius: "5px",
            }}
            onChange={(e) =>
              setFormData({ ...formData, legalAddress: e.target.value })
            }
          />
          <label htmlFor="ssn">Last 4 of SSN</label>
          <input
            required
            type="text"
            placeholder="SSN"
            id="ssn"
            value={formData.ssn}
            onChange={(e) => setFormData({ ...formData, ssn: e.target.value })}
            className="signUpInput"
            style={{ marginBottom: "20px" }}
          ></input>

          <label htmlFor="ssn">Account Details</label>

          <div style={{ display: "flex", width: "100%", marginTop: "10px" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                marginRight: "5vw",
              }}
            >
              <label className={classes.p} htmlFor="RNo">
                Routing Number
              </label>
              <input
                required
                type="text"
                id="RNo"
                value={formData.routingNumber}
                onChange={(e) =>
                  setFormData({ ...formData, routingNumber: e.target.value })
                }
                className="signUpInput"
              ></input>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label htmlFor="ANo" className={classes.p}>
                Account Number
              </label>
              <input
                required
                type="text"
                id="ANo"
                value={formData.accountNumber}
                onChange={(e) =>
                  setFormData({ ...formData, accountNumber: e.target.value })
                }
                className="signUpInput"
              ></input>
            </div>
          </div>

          <img
            src={AccountDetails}
            style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }}
            alt="AccountDetails"
          />
        </form>
      </div>
    </div>
  );
}

export default Bank;
