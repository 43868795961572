export const ServiceList = [
    {
      name: "WeeklyMowing",
    },
    {
      name: "BiWeeklyMowing",
    },
    {
      name: "OneTimeMowing",
    },
  ];
  