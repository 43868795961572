import React from "react";
import "./card.css";
import {
  CardHolder,
  CardNumber,
  CardSecurityCode,
  ValidThruMonth,
  ValidThruYear,
} from "reactjs-credit-card/form";
import { authQA as auth, dbQA as db } from "../../../../components/Firebase";
import { ServiceList } from "./ServiceList";
import {
  doc,
  addDoc,
  getDoc,
  setDoc,
  getDocs,
  arrayUnion,
  query,
  where,
  collection,
  serverTimestamp,
  updateDoc,
  deleteField,
} from "firebase/firestore";

import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import { useCardForm } from "reactjs-credit-card";
import { useState } from "react";
import { UserContext } from "../../../../components/UserContext";
import { useStyles } from "./Styles.js";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import Card from "reactjs-credit-card/card";
import MaterialTable from "material-table";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// import Nav_QA from "../../components/Nav_QA";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { Alert, AlertTitle } from "@mui/material";

import Slide from "@mui/material/Slide";
import axios from "axios";
import {
  Grid,
  Button,
  Container,
  Box,
  Typography,
  Paper,
  Divider,
  styled,
} from "@material-ui/core";
import { forwardRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DeleteIcon from "@mui/icons-material/Delete";
//import Alert from "@mui/material/Alert";
import { color } from "@mui/system";
import { Rating } from "@mui/material";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { ListItem } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import FeedbackIcon from "@mui/icons-material/Feedback";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { grey } from "@mui/material/colors";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteIcon style={{ color: "black" }} {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

var OutlinedTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    border: "1px solid white",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "transparent",
  },
}));

function LawnberDashboard() {
  //states
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [user, setUser] = React.useContext(UserContext);
  const [numberValid, setNumberValid] = useState(true);
  const [savedCards, setSavedCards] = React.useState([]);
  const [addCard, setAddCard] = React.useState([]);
  const [defaultCard, setDefaultCard] = React.useState([]);
  const [getPrice, setGetprice] = React.useState([]);
  const [serviceId, setServiceId] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const [show, setShow] = React.useState(true);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [cardData, setCardData] = React.useState(null);
  const [serHistory, setSerHistory] = React.useState([]);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);
  const [setCancel] = React.useState(false);
  const [pmData, setPmData] = React.useState(null);
  const [nickname, setNickName] = React.useState("");
  const [currentPlan, setCurrentPlan] = React.useState([]);
  const [checkState, setCheckState] = React.useState(false);
  const [emailVerified, setEmailVerified] = React.useState(true);
  const [day, setDay] = React.useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showReasons, setShowReasons] = useState(false);
  const [rating, setRating] = useState(null);
  const [starRating, setStarRating] = useState(null);
  const [showOtherTextBox, setShowOtherTextBox] = useState(false);
  const [otherTextBoxValue, setOtherTextBoxValue] = useState(null);
  const [showDialog1, setShowDialog1] = useState(false);
  const [showDialog2, setShowDialog2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTippingDialog, setShowTippingDialog] = useState(false);
  const [showOtherTippingDialog, setShowOtherTippingDialog] = useState(false);
  const [customTip, setCustomTip] = useState(null);
  const [showComplainDialog, setShowComplainDialog] = useState(false);
  const [complainText, setComplainText] = useState(null);
  const [showComplainResolveDialog, setShowComplainResolveDialog] =
    useState(null);
  const [rework, setRework] = useState(null);
  const [refund, setRefund] = useState(null);
  const [cancelReasons, setCancelReasons] = React.useState({
    tooExpensive: false,
    noLongerRequired: false,
    switchProvider: false,
    other: false,
  });
  const { tooExpensive, noLongerRequired, switchProvider, other } =
    cancelReasons;
  const [addCardData, setAddCardData] = React.useState({});

  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  });

  //variables
  const classes = useStyles();
  const getFormData = useCardForm();
  const services = [
    {
      id: 0,
      name: "Weekly Mowing",
    },
    {
      id: 1,
      name: "Bi-Weekly Mowing",
    },
  ];

  //reference path functions
  const stripeRef = (uid) => {
    const striperef = doc(db, "UserAcct", uid, "Payments", "Stripe");
    return striperef;
  };
  const serviceRef = (uid) => {
    const service = doc(db, "UserAcct", uid, "Services", "ServicePlan");
    return service;
  };
  const serviceHistoryRef = (uid) => {
    const service = doc(db, "UserAcct", uid, "Services", "ServiceHistory");
    return service;
  };
  const history = useHistory();

  // Render dom for the first time or when state changes
  React.useEffect(() => {
    const checkUser = async () => {
      localStorage.setItem("userQA", JSON.stringify(user));
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUser(user);
        } else {
          setUser(null);
          console.log("No user");
          history.push("/QAlogin");
          return;
        }
      });
    };
    checkUser();
  }, [emailVerified]);

  //Go to firestore db and get necessary information
  React.useEffect(() => {
    setTimeout(() => {
      getService();
    }, 3000);
  }, [checkState]);

  // Fetches all the cards associated with the user
  React.useEffect(() => {
    const getCardsFromStripe = async () => {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const { data } = await axios.post(
            process.env.REACT_APP_CARDS_QA,
            // "http://localhost:4008/QA/cards",
            {
              email: user.email,
            }
          );
          if (data.status === "Error") {
            alert(data.Error);
            setSavedCards(data.savedCards);
            return;
          }
          setSavedCards(data.savedCards);
        } else {
          console.log("No user");
        }
      });
    };
    getCardsFromStripe();
  }, [addCard]);

  // Fetches default card from firestore for the user
  React.useEffect(() => {
    getDefaultCard();
  }, []);

  /**** dailog box open/close functions ***/
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
  };

  const handleDeleteClose = () => {
    setDeleteConfirm(false);
  };
  const showReason = () => {
    setShowOptions(false);
    setShowReasons(true);
  };
  const showFeedback = () => {
    setShowOptions(false);
    setShowFeedbackDialog(true);
  };
  const handleCheckBoxChange = (event) => {
    setCancelReasons({
      ...cancelReasons,
      [event.target.name]: event.target.checked,
    });
    if (event.target.name == "other" && event.target.checked) {
      setShowOtherTextBox(true);
    } else setShowOtherTextBox(false);
  };

  //Used for Cancelling request from user
  const handleMoreOptions = () => {
    setShowDialog2(true);
    //
  };
  const handleCancelSubscription = async () => {
    try {
      setGetprice([]);
      const response = await axios.post(
        "https://be-operation-services.herokuapp.com/JobChange",
        {
          newService: "Cancel",
          oldService: currentPlan[0],
          servID: serviceId,
        }
      );
      // console.log(response);
      if (response.status === 200) {
        await setDoc(
          serviceRef(user.uid),
          {
            CurrentPlan: "",
            ServiceDay: "",
          },
          { merge: true }
        ).catch((err) => alert(err));
      }
      await setCheckState(Math.random().toString(36).slice(2));
    } catch (error) {
      console.log("Error occured : ", error);
    }
  };
  /*****************************/

  // Fetches service selected by the user
  const getService = () => {
    var list = [];
    localStorage.setItem("user", JSON.stringify(user));
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const docSnap = await getDoc(serviceRef(user.uid));
        const HistorySnap = await getDoc(serviceHistoryRef(user.uid));
        const userInfo = await getDoc(doc(db, "UserAcct", user.uid));
        if (docSnap.exists()) {
          setUserDetails({
            name: userInfo.data().FirstName + " " + userInfo.data().LastName,
          });
          const dictValues = [];
          for (var i = 0; i < ServiceList.length; i++) {
            dictValues.push(
              docSnap.data().Services[`M${i}_` + ServiceList[i]["name"]]
            );
          }

          setGetprice(dictValues);
          setSelectedValue([docSnap.data().CurrentPlan]);
          setCurrentPlan([docSnap.data().CurrentPlan]);
          setServiceId(docSnap.data().ServiceId);
          if (
            docSnap.data().CurrentPlan === "" &&
            docSnap.data().ServiceDay === ""
          ) {
            setDay("Cancelled");
          } else {
            calculateDay(docSnap.data().ServiceDay);
          }

          if (HistorySnap.exists()) {
            list = HistorySnap.data().History;
            setSerHistory(list.reverse());
          } else {
            setSerHistory([
              {
                Date: "dummy",
                OrderId: "dummy",
                Service: "dummy",
                Price: "dummy",
              },
            ]);
          }
          setShow(true);
        } else {
          setShow(true);
          const userInfo = await getDoc(doc(db, "UserAcct", user.uid));
          setUserDetails({
            name: userInfo.data().FirstName + " " + userInfo.data().LastName,
          });
          console.log("No such document!");
        }
      } else {
        console.log("No user");
      }
    });
  };

  //Function: Fetches serviceDay from Database
  const calculateDay = (apiDay) => {
    let tempDay = apiDay.slice(apiDay.indexOf("_") + 1);
    switch (tempDay) {
      case "MON":
        setDay("Monday");
        break;
      case "TUE":
        setDay("Tuesday");
        break;
      case "WED":
        setDay("Wednesday");
        break;
      case "THU":
        setDay("Thursday");
        break;
      case "FRI":
        setDay("Friday");
        break;
      case "SAT":
        setDay("Saturday");
        break;
      case "SUN":
        setDay("Sunday");
        break;
      case "":
        setDay("Pending");
        break;
      default:
        break;
    }
  };

  // Fetch default card of the customer
  const getDefaultCard = async () => {
    localStorage.setItem("user", JSON.stringify(user));
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const docSnap = await getDoc(stripeRef(user.uid));
        if (docSnap.exists()) {
          setDefaultCard([
            docSnap.data().DefaultCard["Brand"],
            docSnap.data().DefaultCard["Index"],
            docSnap.data().DefaultCard["Id"],
            docSnap.data().DefaultCard["Digits"],
          ]);
        } else {
          console.log("No such document!");
        }
      } else {
        console.log("no user");
      }
    });
  };

  // shows/changes plans of a user
  const handleChange = async (name, index) => {
    const proServicesRef = doc(db, "ProServices", serviceId);
    console.log("pressed", name, index);
    if (!selectedValue.includes(name)) {
      setSelectedValue([name]);
      console.log("selected value", selectedValue);
      await setDoc(
        serviceRef(user.uid),
        {
          CurrentPlan: name,
        },
        { merge: true }
      ).catch((err) => alert(err));
      await setDoc(
        proServicesRef,
        {
          JobRequest: name,
          Price: getPrice[index],
        },
        { merge: true }
      ).catch((err) => alert(err));
      toast.success(`Plan changed to ${name}`, { autoClose: 2000 });
      await axios
        .post(process.env.REACT_APP_JOB_CHANGE_QA, {
          newService: name,
          oldService: currentPlan[0],
          servID: serviceId,
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setSelectedValue(selectedValue.filter((i) => i !== name));
    }
    setOpenConfirm(false);
    setCheckState(Math.random().toString(36).slice(2));
  };

  // Function: Add a new card using customer details
  const handleAddCard = (e) => {
    e.preventDefault();
    const [formData] = getFormData();
    // console.log(formData);

    const sendPaymentMethodDetails = async () => {
      // post data to backend and recieve Id in the front end in return
      const { data } = await axios.post(
        process.env.REACT_APP_ADD_CARD_QA,
        // "http://localhost:4008/QA/addCard",
        {
          email: user.email,
          number: formData.number.value,
          exp_month: formData.validMonth.value,
          exp_year: formData.validYear.value,
          cvc: formData.securityCode.value,
        }
      );
      if (data.status === "Success") {
        setAddCard(data.paymentMethodId);
        await setDoc(
          stripeRef(user.uid),
          {
            Cards: {
              [data.digits]: {
                Id: data.paymentMethodId,
                BillingAddress: {
                  CardName: formData.holder.value,
                  NickName: nickname,
                  State: "",
                  City: "",
                  StreetAddress: "",
                  Zipcode: "",
                },
              },
            },
          },
          { merge: true }
        ).catch((err) => alert(err));
      } else {
        alert("Card not added");
        console.log(data.error);
        return;
      }
    };

    // complete add card request
    sendPaymentMethodDetails();
    //toast.success(`New card added`, { autoClose: 2000 });
  };

  // remove error function if focused on CardNumber
  const handleFocus = () => {
    setNumberValid(true);
  };

  // Set default payment method based on user selection
  const handleSetDefault = (rowData) => {
    // Send the payemnt id to backend to set default card
    const sendPaymentMethodId = async () => {
      // post selected payment method id to backend
      const { data } = await axios.post(
        // process.env.REACT_APP_SET_DEFAULT_QA,
        "https://be-operation-services.herokuapp.com/QA/setDefaultCard",
        // "http://localhost:4008/QA/setDefaultCard",
        {
          email: user.email,
          paymentMethodId: savedCards[rowData.tableData.id]["paymentMethod"],
        }
      );
      console.log(data);
      if (data.status === "Success") {
        setDefaultCard([
          rowData.cards,
          rowData.tableData.id,
          rowData.paymentMethod,
          rowData.digits,
        ]);
        await setDoc(
          stripeRef(user.uid),
          {
            DefaultCard: {
              Id: savedCards[rowData.tableData.id]["paymentMethod"],
              Brand: savedCards[rowData.tableData.id]["cards"],
              Index: rowData.tableData.id,
              Digits: savedCards[rowData.tableData.id]["digits"],
            },
          },
          { merge: true }
        ).catch((err) => alert(err));
      } else {
        alert("Server error");
        console.log(data.error);
        return;
      }
    };

    //process the request to set set default card
    sendPaymentMethodId();
  };

  // Delete the payment method based on user selection
  const handleDelete = async (index) => {
    // setDeleteCard(select);
    const sendDeletePaymentId = async () => {
      const { data } = await axios.post(
        process.env.REACT_APP_DELETE_QA,
        // "http://localhost:4008/QA/deleteCard",
        {
          paymentMethodId: savedCards[index]["paymentMethod"],
        }
      );
      if (data.status === "Success") {
        await setDoc(
          stripeRef(user.uid),
          {
            Cards: {
              [savedCards[index]["digits"]]: deleteField(),
            },
          },
          { merge: true }
        ).catch((err) => alert(err));
      } else {
        alert("Could not Delete card");
        console.log(data.error);
        return;
      }
    };
    sendDeletePaymentId();
  };

  // Check already selected service and restrict from clicking again to change
  const checkSelected = (name, id) => {
    if (currentPlan[0] === name) {
      setOpenConfirm(false);
    } else {
      setCardData([name, id]);
      setOpenConfirm(true);
    }
  };

  return (
    <>
      {/* <Nav_QA /> */}
      <ToastContainer />
      <div
        style={{ backgroundColor: "white", width: "100vw", height: "100vh" }}
      >
        {/* {emailVerified ? (
           ""
         ) : (
           <Alert severity="warning">
             Please verify your email address as soon as possible
           </Alert>
         )} */}

        <h4
          style={{
            fontSize: "30px",
            margin: "0px",
            paddingTop: "30px",
            fontWeight: "bolder",
          }}
        >
          Service
        </h4>
        <p className={classes.p}>Modify your requested service.</p>

        {
          <Grid container xs={12} md={12} lg={12} xl={12} spacing={0}>
            <Grid item xs={12} md={8} lg={9} className={classes.grid}>
              {userDetails["name"] ? (
                <h3
                  className={classes.dashboardGrid}
                  style={{
                    paddingBottom: "20px",
                    fontWeight: "bolder",
                    display: "flex",
                    fontSize: "19px",
                    paddingLeft: "2%",
                  }}
                >
                  Hello, {userDetails["name"]}
                </h3>
              ) : (
                " "
              )}

              {show ? (
                <h4
                  className={classes.dashboardGrid}
                  style={{
                    paddingBottom: "20px",
                    fontWeight: "light",
                    display: "flex",
                    fontSize: "19px",
                    paddingLeft: "2%",
                  }}
                >
                  Service day is {day}
                </h4>
              ) : (
                ""
              )}

              {show && (
                <h4
                  className={classes.dashboardGrid}
                  style={{
                    paddingBottom: "20px",
                    fontWeight: "bolder",
                    display: "flex",
                    fontSize: "18px",
                    paddingLeft: "2%",
                  }}
                >
                  Edit a plan
                </h4>
              )}

              {show ? (
                <Grid
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  container
                  spacing={1}
                  style={{ width: "100%", paddingLeft: "1%" }}
                >
                  {services.length > 0 &&
                    services.map((data, index) => {
                      return (
                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={9}
                          xl={3}
                          xs={12}
                          style={{ width: "100%" }}
                          key={data.id}
                          spacing={5}
                          className={classes.dashboardGrid}
                        >
                          <div
                            key={data.id}
                            className="gradientCard"
                            style={{
                              background: selectedValue.includes(data.name)
                                ? "#74b0ce"
                                : "#e8f2f7",
                              boxShadow:
                                "-6px 6px 14px #a1a1a1,6px -6px 14px #ffffff",
                              border: selectedValue.includes(data.name)
                                ? "5px solid black"
                                : "none",
                            }}
                            onClick={() => {
                              checkSelected(data.name, data.id);
                            }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                border: selectedValue.includes(data.name)
                                  ? "4px solid black"
                                  : "4px solid black",
                                marginTop: "10%",
                                borderRadius: "28px",
                                margin: "0px",
                                padding: "5px 8px",
                                fontWeight: "600",
                                width: "80%",
                                color: selectedValue.includes(data.name)
                                  ? "black"
                                  : "black",
                              }}
                            >
                              {data.name}
                            </div>
                            <div>
                              {getPrice.length > 0 ? (
                                <h3
                                  style={{
                                    color: selectedValue.includes(data.name)
                                      ? "black"
                                      : "black",
                                    marginTop: "30%",
                                    fontWeight: "700",
                                  }}
                                >
                                  ${getPrice[index]}
                                </h3>
                              ) : (
                                <h3
                                  style={{
                                    color: selectedValue.includes(data.name)
                                      ? "black"
                                      : "black",
                                    marginTop: "30%",
                                    fontWeight: "700",
                                  }}
                                >
                                  <CircularProgress color="inherit" />
                                </h3>
                              )}
                            </div>
                          </div>
                          <Dialog
                            open={openConfirm}
                            onClose={handleConfirmClose}
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <DialogContent>
                              <h4
                                style={{
                                  fontWeight: "600",
                                }}
                              >
                                Do you want to change your plan?
                              </h4>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleConfirmClose}
                                style={{
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                }}
                              >
                                No
                              </Button>
                              <Button
                                onClick={() =>
                                  handleChange(cardData[0], cardData[1])
                                }
                                style={{
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                }}
                              >
                                Yes
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <Dialog open={showOptions} onClose={handleClose}>
                            <div className={classes.card}>
                              <Alert
                                severity="warning"
                                role="button"
                                // icon={<Acc />}
                                onClose={() => setShowOptions(false)}
                                closeText="Doesn't Work!"
                                sx={{
                                  "& .MuiAlert-icon": {
                                    color: "white",
                                    fontSize: "30px",
                                    backgroundImage:
                                      "linear-gradient(225deg, #1d2122, #181c1d)",
                                    boxShadow:
                                      "-5px 5px 15px #000000,10px -10px 40px #FFFFFF",
                                    padding: "4%",
                                    borderRadius: "50%",
                                  },
                                  backgroundColor: "transparent",
                                }}
                              >
                                <h2 style={{ color: "white" }}>Alert!</h2>
                              </Alert>
                              <DialogTitle
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "18px",
                                  paddingLeft: "4%",
                                  color: "white",
                                }}
                              >
                                Change Services
                              </DialogTitle>

                              <DialogActions>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  className={classes.dialogButton}
                                  // style={{
                                  //   borderColor: "rgb(24, 124, 174)",
                                  //   borderRadius: "20px",
                                  //   borderWidth: "3px",
                                  // }}
                                  onClick={showFeedback}
                                >
                                  Provide feedback
                                </Button>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  className={classes.dialogButton2}
                                  style={{
                                    //   borderColor: "#c0392b",
                                    //   borderRadius: "20px",
                                    //   borderWidth: "3px",
                                    backgroundColor: "rgb(255, 127, 127)",
                                  }}
                                  // variant="outlined"
                                  // style={{
                                  //   borderColor: "#c0392b",
                                  //   borderRadius: "20px",
                                  //   borderWidth: "3px",
                                  // }}
                                  onClick={showReason}
                                >
                                  Cancel Subscription
                                </Button>
                              </DialogActions>
                            </div>
                          </Dialog>
                          <Dialog
                            open={showFeedbackDialog}
                            onClose={handleClose}
                          >
                            <div className={classes.card}>
                              <Alert
                                severity="warning"
                                role="button"
                                // icon={<Acc />}
                                onClose={() => setShowFeedbackDialog(false)}
                                closeText="Doesn't Work!"
                                sx={{
                                  "& .MuiAlert-icon": {
                                    color: "white",
                                    fontSize: "30px",
                                    backgroundImage:
                                      "linear-gradient(225deg, #1d2122, #181c1d)",
                                    boxShadow:
                                      "-5px 5px 15px #000000,10px -10px 40px #FFFFFF",
                                    padding: "4%",
                                    borderRadius: "50%",
                                    overflow: "hidden",
                                  },
                                  backgroundColor: "transparent",
                                }}
                              >
                                <h2 style={{ color: "white" }}>Alert!</h2>
                              </Alert>
                              <DialogTitle
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "18px",
                                  paddingLeft: "17%",
                                  color: "white",
                                }}
                              >
                                Feedback
                              </DialogTitle>
                              <DialogContent>
                                <Rating
                                  name="simple-controlled"
                                  value={rating}
                                  onChange={(event, newValue) => {
                                    setRating(newValue);
                                  }}
                                />
                              </DialogContent>

                              <DialogActions>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  className={classes.dialogButton}
                                  // style={{
                                  //   borderColor: "rgb(24, 124, 174)",
                                  //   borderRadius: "20px",
                                  //   borderWidth: "3px",
                                  // }}
                                  onClick={() => setShowFeedbackDialog(false)}
                                >
                                  Submit
                                </Button>
                              </DialogActions>
                            </div>
                          </Dialog>
                          <Dialog open={showReasons} onClose={handleClose}>
                            <div className={classes.card}>
                              <Alert
                                severity="warning"
                                role="button"
                                // icon={<Acc />}
                                onClose={() => setShowReasons(false)}
                                closeText="Doesn't Work!"
                                sx={{
                                  "& .MuiAlert-icon": {
                                    color: "white",
                                    fontSize: "30px",
                                    backgroundImage:
                                      "linear-gradient(225deg, #1d2122, #181c1d)",
                                    boxShadow:
                                      "-5px 5px 15px #000000,10px -10px 40px #FFFFFF",
                                    padding: "4%",
                                    borderRadius: "50%",
                                  },
                                  backgroundColor: "transparent",
                                }}
                              >
                                <h2 style={{ color: "white" }}>Alert!</h2>
                              </Alert>
                              <DialogTitle
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "18px",
                                  color: "white",
                                }}
                              >
                                Cancel Service
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText
                                  style={{ color: "white" }}
                                  className={classes.p}
                                >
                                  Please choose a reason
                                </DialogContentText>
                                <FormControl
                                  sx={{ m: 3 }}
                                  component="fieldset"
                                  variant="standard"
                                >
                                  <FormGroup>
                                    <FormControlLabel
                                      style={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        color: "white",
                                        fontFamily: "Poppins",
                                      }}
                                      control={
                                        <Checkbox
                                          sx={{
                                            color: "#fff",
                                            "&.Mui-checked": {
                                              color: "#fff",
                                            },
                                          }}
                                          checked={tooExpensive}
                                          onChange={handleCheckBoxChange}
                                          name="tooExpensive"
                                        />
                                      }
                                      label="Too expensive"
                                    />
                                    <FormControlLabel
                                      style={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        color: "white",
                                        fontFamily: "Poppins",
                                      }}
                                      control={
                                        <Checkbox
                                          sx={{
                                            color: "#fff",
                                            "&.Mui-checked": {
                                              color: "#fff",
                                            },
                                          }}
                                          checked={noLongerRequired}
                                          onChange={handleCheckBoxChange}
                                          name="noLongerRequired"
                                        />
                                      }
                                      label="Service not required"
                                    />
                                    <FormControlLabel
                                      style={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        color: "white",
                                        fontFamily: "Poppins",
                                      }}
                                      control={
                                        <Checkbox
                                          sx={{
                                            color: "#fff",
                                            "&.Mui-checked": {
                                              color: "#fff",
                                            },
                                          }}
                                          checked={switchProvider}
                                          onChange={handleCheckBoxChange}
                                          name="switchProvider"
                                        />
                                      }
                                      label="Switching Service Provider"
                                    />
                                    <FormControlLabel
                                      style={{
                                        fontSize: "24px",
                                        fontWeight: "400",
                                        color: "white",
                                        fontFamily: "Poppins",
                                      }}
                                      control={
                                        <Checkbox
                                          sx={{
                                            color: "#fff",
                                            "&.Mui-checked": {
                                              color: "#fff",
                                            },
                                          }}
                                          checked={other}
                                          onChange={handleCheckBoxChange}
                                          name="other"
                                        />
                                      }
                                      label="other"
                                    />
                                    {showOtherTextBox ? (
                                      // <CssTextField label="Other reason" id="custom-css-outlined-input" />
                                      // <CssTextField
                                      //   id="custom-css-outlined-input"
                                      //   label="Other reason"
                                      //   value={otherTextBoxValue}
                                      //   onChange={(event) => {
                                      //     console.log(event.target.value);
                                      //     setOtherTextBoxValue(
                                      //       event.target.value
                                      //     );
                                      //   }}
                                      // />
                                      <OutlinedTextField
                                        label="Other"
                                        defaultValue=""
                                        id="reddit-input"
                                        variant="filled"
                                        style={{ marginTop: 11 }}
                                      />
                                    ) : null}
                                  </FormGroup>
                                </FormControl>
                              </DialogContent>

                              <DialogActions>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  className={classes.dialogButton}
                                  // style={{
                                  //   borderColor: "rgb(24, 124, 174)",
                                  //   borderRadius: "20px",
                                  //   borderWidth: "3px",
                                  // }}
                                  onClick={() => setShowReasons(false)}
                                >
                                  Keep your Service
                                </Button>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  className={classes.dialogButton2}
                                  style={{
                                    //   borderColor: "#c0392b",
                                    //   borderRadius: "20px",
                                    //   borderWidth: "3px",
                                    backgroundColor: "rgb(255, 127, 127)",
                                  }}
                                  // variant="outlined"
                                  // style={{
                                  //   borderColor: "#c0392b",
                                  //   borderRadius: "20px",
                                  //   borderWidth: "3px",
                                  // }}
                                  onClick={() => {
                                    handleCancelSubscription();
                                    setShowReasons(false);
                                  }}
                                >
                                  Cancel Service
                                </Button>
                              </DialogActions>
                            </div>
                          </Dialog>
                          <Dialog open={showDialog1} onClose={handleClose}>
                            <DialogTitle>Layer-1</DialogTitle>

                            <DialogActions>
                              <Button
                                onClick={() => {
                                  setShowDialog2(true);
                                  setShowDialog1(false);
                                }}
                              >
                                Show Dialog-2
                              </Button>
                              <Button onClick={() => setShowDialog1(false)}>
                                Close Dialog-1
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <Dialog open={showDialog2} onClose={handleClose}>
                            <div className={classes.card}>
                              <Alert
                                severity="warning"
                                role="button"
                                // icon={<Acc />}
                                onClose={() => setShowDialog2(false)}
                                closeText="Doesn't Work!"
                                sx={{
                                  "& .MuiAlert-icon": {
                                    color: "white",
                                    fontSize: "30px",
                                    backgroundImage:
                                      "linear-gradient(225deg, #1d2122, #181c1d)",
                                    boxShadow:
                                      "-5px 5px 15px #000000,10px -10px 40px #FFFFFF",
                                    padding: "4%",
                                    borderRadius: "50%",
                                  },
                                  backgroundColor: "transparent",
                                }}
                              >
                                <h2 style={{ color: "white" }}>Alert!</h2>
                              </Alert>
                              <DialogTitle
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "18px",
                                  paddingLeft: "5%",
                                  color: "white",
                                }}
                              >
                                Show more Options
                              </DialogTitle>

                              <DialogActions>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  className={classes.dialogButton}
                                  // style={{
                                  //   borderColor: "rgb(24, 124, 174)",
                                  //   borderRadius: "32px",
                                  //   borderWidth: "3px",
                                  //   fontFamily: "Lexend",
                                  //   textTransform: "capitalize",
                                  //   fontWeight: "bold",

                                  // }}

                                  onClick={() => {
                                    setShowOptions(true);
                                    setShowDialog2(false);
                                  }}
                                >
                                  Change your service
                                </Button>
                                <Button
                                  // variant="outlined"
                                  size="small"
                                  variant="outlined"
                                  className={classes.dialogButton2}
                                  style={{
                                    // //   borderColor: "#c0392b",
                                    // //   borderRadius: "20px",
                                    // //   borderWidth: "3px",
                                    backgroundColor: "rgb(255, 127, 127)",
                                  }}
                                  onClick={() => setShowDialog2(false)}
                                >
                                  Cancel
                                </Button>
                              </DialogActions>
                            </div>
                          </Dialog>
                        </Grid>
                      );
                    })}
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    xs={6}
                    style={{
                      width: "100%",
                      paddingBottom: "20px",
                    }}
                    spacing={5}
                    className={classes.dashboardGrid}
                  >
                    <div
                      className="gradientCard"
                      style={{
                        background: "#187cae",
                        marginLeft: "20px",
                        boxShadow:
                          "-6px 6px 14px #a1a1a1,6px -6px 14px #ffffff",
                        borderRadius: "15px",
                      }}
                    >
                      <h2 style={{ color: "white", textAlign: "left" }}>
                        {
                          "Thank you for signing up. We will contact the service crew in your area and notify you as soon as it is available"
                        }
                      </h2>
                    </div>
                  </Grid>
                </>
              )}
              {show && (
                <div onClick={handleMoreOptions} className="cancelButton">
                  <h4 style={{ marginRight: "75%", marginTop: "3%" }}>
                    More Options
                  </h4>
                </div>
              )}

              {show && (
                <Divider
                  className={classes.dashboardGrid}
                  style={{
                    backgroundColor: "black",
                    height: "0%",
                    width: "70vw",
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                />
              )}

              {show && (
                <div className={classes.dashboardGrid}>
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      {
                        title: "Brand",
                        field: "cards",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        },
                      },
                      {
                        title: "Digits",
                        field: "digits",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        },
                      },
                      {
                        title: "Expiry",
                        field: "expiry",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        },
                      },
                      {
                        title: "",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          maxWidth: 300,
                        },
                        render: (rowData) => {
                          const SetDefaultbutton = (
                            <div
                              style={{
                                fontSize: "14px",
                                background: "#D1D2D2",
                                borderRadius: "69px",
                                fontWeight: "400",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                                padding: "3px",
                                alignItems: "center",
                                textAlign: "center",
                                color: "#000000",
                                fontStyle: "normal",
                                lineHeight: "22px",
                                left: "63.58%",
                                right: "30.3%",
                                top: "56.35%",
                                bottom: "42.28%",
                                width: "100px",
                              }}
                              onClick={() => {
                                handleSetDefault(rowData);
                              }}
                            >
                              Set Default
                            </div>
                          );
                          const Defaultbutton = (
                            <div
                              style={{
                                fontSize: "14px",
                                background: "#1F724A",
                                borderRadius: "69px",
                                fontWeight: "400",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                                padding: "3px",
                                alignItems: "center",
                                textAlign: "center",
                                color: "#F5FFFA",
                                fontStyle: "normal",
                                lineHeight: "22px",
                                left: "63.58%",
                                right: "30.3%",
                                top: "56.35%",
                                bottom: "42.28%",
                                width: "100px",
                              }}
                            >
                              Default
                            </div>
                          );

                          if (
                            (defaultCard &&
                              defaultCard[2] === rowData.paymentMethod) ||
                            savedCards.length === 1
                          ) {
                            return Defaultbutton;
                          } else {
                            return SetDefaultbutton;
                          }
                        },
                      },

                      {
                        title: "",

                        render: (rowData) => {
                          const deletePM = (id) => {
                            const deleteIndex = id;
                            const updatedRow = [...savedCards];
                            updatedRow.splice(deleteIndex, 1);
                            handleDelete(id);
                            setSavedCards(updatedRow);
                            setDeleteConfirm(false);
                          };

                          const Deletebutton = (
                            <Tooltip title="Delete">
                              <IconButton>
                                <DeleteIcon
                                  style={{ color: "black" }}
                                  onClick={() => {
                                    setPmData(rowData.tableData.id);
                                    setDeleteConfirm(true);
                                  }}
                                ></DeleteIcon>
                                <Dialog
                                  open={deleteConfirm}
                                  onClose={handleDeleteClose}
                                  aria-describedby="alert-dialog-slide-description"
                                >
                                  <DialogContent>
                                    <h4
                                      style={{
                                        fontWeight: "600",
                                      }}
                                    >
                                      Do you want to Delete the payment method?
                                    </h4>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      onClick={handleDeleteClose}
                                      style={{
                                        fontWeight: "600",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      No
                                    </Button>
                                    <Button
                                      onClick={() => deletePM(pmData)}
                                      style={{
                                        fontWeight: "600",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      Yes
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </IconButton>
                            </Tooltip>
                          );
                          if (
                            defaultCard &&
                            defaultCard[2] != rowData.paymentMethod
                          ) {
                            return Deletebutton;
                          }
                        },
                      },
                    ]}
                    data={savedCards}
                    title={
                      <h4
                        style={{
                          fontWeight: "bolder",
                          fontSize: "18px",
                        }}
                        // className={classes.dashboardGrid}
                      >
                        Payment Methods
                      </h4>
                    }
                    localization={{
                      body: {
                        emptyDataSourceMessage: (
                          <>
                            {getPrice.length > 0 ? (
                              <h4 style={{ color: "white", fontSize: "18px" }}>
                                No card has been added. Click Add New Card
                                below, to add a new Card
                              </h4>
                            ) : (
                              ""
                            )}
                          </>
                        ),
                      },
                    }}
                    components={{
                      Container: (props) => (
                        <Paper
                          {...props}
                          elevation={0}
                          style={{ width: "70vw" }}
                        />
                      ),
                    }}
                    options={{
                      paging: false,
                      sorting: false,
                      headerStyle: {
                        fontWeight: "500",
                        color: "#252424",
                      },
                      rowStyle: {
                        backgroundColor: "white",
                        color: "black",
                      },
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      showTitle: true,
                      search: false,
                    }}
                  />
                </div>
              )}

              {show && (
                <Grid
                  item
                  lg={3}
                  sm={8}
                  xl={3}
                  xs={10}
                  style={{
                    width: "100%",
                    paddingTop: "3vh",
                  }}
                  spacing={1}
                >
                  <div>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.dashboardGrid}
                    >
                      <div
                        onClick={handleClickOpen}
                        style={{
                          fontSize: "14px",
                          border: "4px solid #187CAE",
                          borderRadius: "28px",
                          fontWeight: "600",
                          padding: "5px 20px",
                          marginLeft: "0%",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                          color: "#187CAE",
                          marginLeft: "10px",
                        }}
                      >
                        {/* <AddRoundedIcon fontSize="small" /> */}+ Add new
                        card
                      </div>
                      {/* <button className="buttonPrimary" onClick={handleDone}>
                       Done
                     </button> */}
                    </Grid>

                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <Box
                          sx={{
                            width: "auto",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <form
                            onSubmit={handleAddCard}
                            className={classes.loginForm}
                          >
                            <Card fixClass="fix-new" cardClass="card-new" />
                            <CardNumber
                              placeholder="Card Number"
                              className={`input-text${
                                !numberValid ? " error" : ""
                              }`}
                              onFocus={handleFocus}
                            />
                            <CardHolder
                              placeholder="Name on Card"
                              className="input-text"
                            />
                            <input
                              id="nickName"
                              placeholder="Nick Name"
                              className="input-text"
                              value={nickname}
                              onChange={(e) => setNickName(e.target.value)}
                            />
                            <div className="flex-wrapper">
                              <div className="semi flex-wrapper">
                                <ValidThruMonth
                                  placeholder="Card Holder"
                                  className="input-text semi"
                                />
                                <ValidThruYear
                                  placeholder="Card Holder"
                                  className="input-text semi"
                                />
                              </div>
                              <CardSecurityCode
                                placeholder="CVV"
                                className="input-text semi"
                              />
                            </div>
                            <button
                              className="buttonPrimary2"
                              onClick={handleClose}
                              style={{ backgroundColor: "#187CAE" }}
                              type="submit"
                            >
                              Add Card
                            </button>
                          </form>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </div>
                </Grid>
              )}

              {show && (
                <Divider
                  className={classes.dashboardGrid}
                  style={{
                    backgroundColor: "black",
                    height: "0%",
                    width: "70vw",
                    marginTop: "2%",
                    marginBottom: "2%",
                    // marginLeft: "200px",
                  }}
                />
              )}
              <Dialog open={showTippingDialog} onClose={handleClose}>
                <div className={classes.card}>
                  <Alert
                    // severity="warning"
                    role="button"
                    icon={
                      <ThumbUpIcon sx={{ color: grey[50] }} fontSize="large" />
                    }
                    onClose={() => {
                      setShowTippingDialog(false);
                      setShowOtherTippingDialog(false);
                    }}
                    sx={{
                      "& .MuiAlert-message": {
                        //   color: "white",
                        //   fontSize: "30px",
                        //   backgroundImage:
                        //     "linear-gradient(225deg, #1d2122, #181c1d)",
                        //   boxShadow:
                        //     "-5px 5px 15px #000000,10px -10px 40px #FFFFFF",
                        //   padding: "4%",
                        //   borderRadius: "50%",
                        //   overflow: "hidden",
                        width: "400px",
                      },
                      "& .MuiAlert-icon": {
                        //   color: "white",
                        //   fontSize: "30px",
                        //   backgroundImage:
                        //     "linear-gradient(225deg, #1d2122, #181c1d)",
                        //   boxShadow:
                        //     "-5px 5px 15px #000000,10px -10px 40px #FFFFFF",
                        //   padding: "4%",
                        //   borderRadius: "50%",
                        //   overflow: "hidden",

                        marginRight: "0%",
                      },
                      background: "transparent",
                    }}
                  >
                    <h2 style={{ color: "white", marginLeft: "7%" }}>
                      Job well done and appreciation
                    </h2>
                  </Alert>
                  <DialogTitle
                    style={{
                      fontWeight: "bolder",
                      fontSize: "16px",

                      color: "white",
                      fontFamily: "Poppins",
                    }}
                  >
                    Would you like to add an appreciation below to thank the
                    pro?
                  </DialogTitle>
                  <DialogContent>
                    <ListItem
                      className={classes.chipList}
                      disablePadding={window.width < 769 ? true : false}
                    >
                      <Chip
                        className={classes.tippingChip}
                        label={"10$"}
                        onClick={() => {
                          console.log("10$");
                          setShowOtherTippingDialog(false);
                        }}
                      />
                      <Chip
                        className={classes.tippingChip}
                        label={"20$"}
                        onClick={() => {
                          console.log("20$");
                          setShowOtherTippingDialog(false);
                        }}
                      />
                      <Chip
                        className={classes.tippingChip}
                        label={"30$"}
                        onClick={() => {
                          console.log("30$");
                          setShowOtherTippingDialog(false);
                        }}
                      />
                      <Chip
                        className={classes.tippingChip}
                        label={"Other"}
                        onClick={() => {
                          console.log("Other");
                          setShowOtherTippingDialog(true);
                        }}
                      />
                      {showOtherTippingDialog ? (
                        <>
                          {/* <OutlinedTextField
                             label="Custom Tip"
                             defaultValue={customTip}
                             id="customtip"
                             variant="outlined"
                             onChange={(e) => setCustomTip(e.target.value)}
                             style={{ marginTop: 11 }}
                           /> */}
                          <TextField
                            className={classes.otherTippingTextBox}
                            onChange={(e) => setCustomTip(e.target.value)}
                            onClose={handleClose}
                            id="outlined-basic"
                            label="Other"
                            variant="outlined"
                            InputProps={{
                              inputProps: { style: { color: "#fff" } },
                            }}
                          />
                          <Button
                            size="small"
                            variant="outlined"
                            className={classes.dialogButton}
                            // style={{
                            //   borderColor: "rgb(24, 124, 174)",
                            //   borderRadius: "20px",
                            //   borderWidth: "3px",
                            // }}
                            onClick={() => console.log(customTip)}
                          >
                            Submit
                          </Button>
                        </>
                      ) : null}
                    </ListItem>
                  </DialogContent>
                </div>
              </Dialog>
              <Dialog open={showComplainDialog} onClose={handleClose}>
                <div className={classes.card}>
                  <Alert
                    severity="warning"
                    role="button"
                    icon={
                      <FeedbackIcon sx={{ color: grey[50] }} fontSize="large" />
                    }
                    onClose={() => setShowComplainDialog(false)}
                    sx={{
                      "& .MuiAlert-icon": {
                        //   color: "white",
                        //   fontSize: "30px",
                        //   backgroundImage:
                        //     "linear-gradient(225deg, #1d2122, #181c1d)",
                        //   boxShadow:
                        //     "-5px 5px 15px #000000,10px -10px 40px #FFFFFF",
                        //   padding: "4%",
                        //   borderRadius: "50%",
                        //   overflow: "hidden",
                      },
                      backgroundColor: "transparent",
                    }}
                  >
                    <h2
                      style={{
                        color: "white",
                        overflow: "hidden",
                      }}
                    >
                      Feedback
                    </h2>
                  </Alert>
                  <DialogTitle
                    style={{
                      fontWeight: "bolder",
                      fontSize: "18px",
                      paddingLeft: "20%",
                      color: "white",
                    }}
                  >
                    Please describe your experience here
                  </DialogTitle>
                  <DialogContent>
                    <OutlinedTextField
                      label="Feedback"
                      value={complainText}
                      id="reddit-input"
                      variant="filled"
                      onChange={(e) => setComplainText(e.target.value)}
                      className={classes.feedbackTextbox}
                      InputProps={{
                        inputProps: { style: { color: "#fff" } },
                      }}
                    />
                    <Button
                      size="small"
                      variant="outlined"
                      className={classes.dialogButton3}
                      style={
                        {
                          //   borderColor: "rgb(24, 124, 174)",
                          //   borderRadius: "20px",
                          //   borderWidth: "3px",
                        }
                      }
                      onClick={() => {
                        console.log(complainText);
                      }}
                    >
                      Submit
                    </Button>
                    <div
                      onClick={() => {
                        console.log(complainText);
                        setShowComplainResolveDialog(true);
                        setShowComplainDialog(false);
                      }}
                    >
                      <h6 className={`${classes.cancelButton2} cancelButton1`}>
                        Submit & Resolve
                      </h6>
                    </div>
                  </DialogContent>
                </div>
              </Dialog>
              <Dialog open={showComplainResolveDialog} onClose={handleClose}>
                <div className={classes.card}>
                  <Alert
                    icon={
                      <SupportAgentIcon
                        sx={{ color: grey[50] }}
                        fontSize="large"
                      />
                    }
                    severity="warning"
                    role="button"
                    onClose={() => setShowComplainResolveDialog(false)}
                    sx={{
                      "& .MuiAlert-icon": {
                        // color: "white",
                        // fontSize: "30px",
                        // backgroundImage:
                        //   "linear-gradient(225deg, #1d2122, #181c1d)",
                        // boxShadow:
                        //   "-5px 5px 15px #000000,10px -10px 40px #FFFFFF",
                        // padding: "4%",
                        // borderRadius: "50%",
                        // overflow: "hidden",
                        // minWidth: "50px",
                      },
                      backgroundColor: "transparent",
                    }}
                  >
                    <h2 style={{ color: "white" }}>Issue resolve</h2>
                  </Alert>
                  <DialogTitle
                    style={{
                      fontWeight: "bolder",
                      fontSize: "18px",
                      paddingLeft: "33%",
                      color: "white",
                    }}
                  >
                    How can we resolve?
                  </DialogTitle>
                  <DialogContent>
                    <Button
                      size="small"
                      variant="outlined"
                      className={classes.dialogButton}
                      style={{ marginLeft: "33%", marginRight: "5%" }}
                      // style={{
                      //   borderColor: "rgb(24, 124, 174)",
                      //   borderRadius: "20px",
                      //   borderWidth: "3px",
                      // }}
                      onClick={() => console.log(rework)}
                    >
                      Rework
                    </Button>

                    <Button
                      size="small"
                      variant="outlined"
                      className={classes.dialogButton}
                      // style={{
                      //   borderColor: "rgb(24, 124, 174)",
                      //   borderRadius: "20px",
                      //   borderWidth: "3px",
                      // }}
                      onClick={() => console.log(refund)}
                    >
                      Refund
                    </Button>
                  </DialogContent>
                </div>
              </Dialog>

              {show && (
                <div className={classes.dashboardGrid}>
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      {
                        title: "Date",
                        field: "Date",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        },
                      },
                      {
                        title: "Order No.",
                        field: "OrderId",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        },
                      },
                      {
                        title: "Service",
                        field: "Service",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        },
                      },
                      {
                        title: "Price",
                        field: "Price",
                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        },
                      },
                      {
                        title: "Rating",
                        field: "Rating",

                        cellStyle: {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        },
                        render: (row) => (
                          <Rating
                            name="simple-controlled"
                            value={rating}
                            onChange={(event, newValue) => {
                              if (newValue && newValue > 2) {
                                setRating(newValue);
                                setShowTippingDialog(true);
                              } else if (newValue && newValue < 3) {
                                setRating(newValue);
                                setShowComplainDialog(true);
                              }
                            }}
                          />
                        ),
                      },
                      // {
                      //   title: "Claim",
                      //   cellStyle: {
                      //     whiteSpace: "nowrap",
                      //     fontWeight: "bold",
                      //     maxWidth: 300,
                      //   },
                      //   render: (rowData) => {
                      //     const claim = (
                      //       <div
                      //         style={{
                      //           fontSize: "14px",
                      //           background: "#1F724A",
                      //           borderRadius: "69px",
                      //           fontWeight: "400",
                      //           whiteSpace: "nowrap",
                      //           cursor: "pointer",
                      //           padding: "3px",
                      //           alignItems: "center",
                      //           textAlign: "center",
                      //           color: "#F5FFFA",
                      //           fontStyle: "normal",
                      //           lineHeight: "22px",
                      //           left: "63.58%",
                      //           right: "30.3%",
                      //           top: "56.35%",
                      //           bottom: "42.28%",
                      //           width: "70px",
                      //         }}
                      //       >
                      //         Claim
                      //       </div>
                      //     );

                      //     return claim;
                      //   },
                      // },
                    ]}
                    data={serHistory}
                    title={
                      <h4
                        style={{
                          fontWeight: "bolder",
                          fontSize: "18px",
                        }}
                      >
                        Service History
                      </h4>
                    }
                    localization={{
                      body: {
                        emptyDataSourceMessage: (
                          <>
                            {getPrice.length > 0 ? (
                              <h4
                                style={{
                                  color: "black",
                                  fontSize: "17px",
                                  fontWeight: "bold",
                                }}
                              >
                                No service has been completed yet.
                              </h4>
                            ) : (
                              <CircularProgress color="inherit" />
                            )}
                          </>
                        ),
                      },
                    }}
                    components={{
                      Container: (props) => (
                        <Paper
                          {...props}
                          elevation={0}
                          style={{ width: "70vw" }}
                        />
                      ),
                    }}
                    options={{
                      paging: true,
                      sorting: false,
                      pageSize: 2,
                      pageSizeOptions: [2, 5, 10, 25, 50, 75, 100],
                      headerStyle: {
                        fontWeight: "500",
                        color: "#252424",
                      },
                      rowStyle: {
                        backgroundColor: "white",
                        color: "black",
                      },
                      actionsColumnIndex: -1,
                      addRowPosition: "first",
                      showTitle: true,
                      search: false,
                    }}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        }
      </div>
    </>
  );
}

export default LawnberDashboard;
