// Preference.js    Created 9/4/2022    Rev 4.8

// Purpose:
// This is a subcomponent for Prosignup page.
//  This code provides UI for Preferences.

import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import React from "react";
import useStyles from "../../New Home/Styles";
import Zipcodedata from "../../../Constant/ZIPCODE_JSON_DATA.json";
import "../Login.css";

function Preference({ formData, setFormData, nextStep, prevStep }) {
  const classes = useStyles();
  const [daysSelected, setDaysSelected] = React.useState({
    D0_SUN: false,
    D1_MON: true,
    D2_TUE: true,
    D3_WED: true,
    D4_THU: true,
    D5_FRI: true,
    D6_SAT: true,
  });
  const [areasWorking, setAreasWorking] = React.useState([]);
  const { D0_SUN, D1_MON, D2_TUE, D3_WED, D4_THU, D5_FRI, D6_SAT } =
    daysSelected;
  const error =
    [D0_SUN, D1_MON, D2_TUE, D3_WED, D4_THU, D5_FRI, D6_SAT].filter((v) => v)
      .length < 4;

  // Function to get selected days from the checkbox
  const handleDaysSelected = (event) => {
    setDaysSelected({
      ...daysSelected,
      [event.target.name]: event.target.checked,
    });
  };

  console.log(daysSelected);
  console.log("Area of work", areasWorking)
  
  return (
    <div>
      <h2 className={classes.h2}>Preferences</h2>
      <p className={classes.p}>
        We need to know a little bit more about your preference.
      </p>
      <Divider
        style={{
          backgroundColor: "black",
          width: "80vw",
        }}
      />
      <List
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <label style={{ marginTop: "10px" }}>
          Select minimum 4 days of the week do you operate?
        </label>
        <FormControl
          sx={{ m: 1 }}
          error={error}
          component="fieldset"
          variant="standard"
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={D0_SUN}
                  onChange={handleDaysSelected}
                  name="D0_SUN"
                  color="secondary"
                />
              }
              label="Sunday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={D1_MON}
                  onChange={handleDaysSelected}
                  name="D1_MON"
                />
              }
              label="Monday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={D2_TUE}
                  onChange={handleDaysSelected}
                  name="D2_TUE"
                />
              }
              label="Tuesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={D3_WED}
                  onChange={handleDaysSelected}
                  name="D3_WED"
                />
              }
              label="Wednesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={D4_THU}
                  onChange={handleDaysSelected}
                  name="D4_THU"
                />
              }
              label="Thursday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={D5_FRI}
                  onChange={handleDaysSelected}
                  name="D5_FRI"
                />
              }
              label="Friday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={D6_SAT}
                  onChange={handleDaysSelected}
                  name="D6_SAT"
                />
              }
              label="Saturday"
            />
          </FormGroup>
          {
            error ? <div style={{color: "red"}}>Select atleast 4 days</div> : null
          }
        </FormControl>
      </List>

      <div style={{ display: "grid", placeItems: "center" }}>
        <label htmlFor="Phone" style={{ marginTop: "10px" }}>
          Area of Work
        </label>
        <Autocomplete
          className="signUpInput"
          multiple={true}
          variant="standard"
          id="tags-outlined"
          color="primary"
          value={formData.areaofWork}
          placeholder="Enter all accessible zipcodes"
          style={{ width: "30%" }}
          options={Zipcodedata}
          onChange={(event, value) => {
            setFormData({ ...formData, areaofWork: value });
            setAreasWorking(value);
          }}
          renderInput={(params) => <TextField required {...params} />}
        />

        <Button
          className={classes.buttonPrimary}
          style={{
            fontWeight: "bold",
            marginTop: "2%",
            width: "90%",
            maxWidth: "400px",
            minWidth: "180px!important",
            backgroundColor:
              areasWorking.length < 1 
                ? "#D3D3D3"
                : "#187CAE",
          }}
          onClick={(e) => {
            e.preventDefault();
            if (areasWorking.length < 1 || error) {
              alert("Fill all the details!");
              return;
            } else {
              setFormData({ ...formData, daysSelected: daysSelected });
              nextStep();
            }
          }}
        >
          Next
        </Button>
        <Button
          style={{
            fontWeight: "bold",
            width: "100%",
            maxWidth: "400px",
            marginTop: "2%",
            border: "2px solid #ED9278",
            borderRadius: "28px",
            fontSize: "100%",
            padding: "5px 10px",
            whiteSpace: "nowrap",
            minWidth: "180px!important",
            minHeight: "40px",
          }}
          onClick={(e) => {
            e.preventDefault();
            prevStep();
          }}
        >
          <Typography
            style={{ color: "#ED9278", fontSize: "100%", fontWeight: "bold" }}
          >
            Previous
          </Typography>
        </Button>
      </div>
    </div>
  );
}

export default Preference;
