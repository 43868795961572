import React from "react";
import { stack as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { UserContext } from "./UserContext";
import {
  AppBar,
  Box,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { auth } from "./Firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";

function Navbar({ pageTitle }) {
  const [user, setUser] = React.useContext(UserContext);
  const theme = useTheme();

  // MUI Responsiveness:
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    function checkUser() {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUser(user);
        } else {
          setUser(null);
          console.log("No user");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{
        backgroundColor: "white",
        width: "100%",
        maxWidth: "100vw",
        height: "70px",
      }}
    >
      <Toolbar>
        <NavLink
          to="/"
          style={{ textDecoration: "none", backgroundColor: "transparent" }}
        >
          <img
            src={require("./assets/images/Logo_SVG_black.svg").default}
            className="logo"
            height="45vh"
            alt="Alltramatic logo"
          />
        </NavLink>

        <h3 style={{ paddingLeft: "3%", color: "black" }}>{pageTitle}</h3>

        <Box flexGrow={1} />

        <div className="NavLinks" style={{ display: "flex" }}>
          {user ? (
            <>
              <NavLink to="/prodashboard" style={{ textDecoration: "none" }}>
                <div className="appbarNavlink">Dashboard</div>
              </NavLink>
              <NavLink to="/proSchedule" style={{ textDecoration: "none" }}>
                <div className="appbarNavlink">Schedule</div>
              </NavLink>
              <NavLink to="/profile" style={{ textDecoration: "none" }}>
                <div className="appbarNavlink">Profile</div>
              </NavLink>
              <NavLink to="/" style={{ textDecoration: "none" }}>
                <div
                  className="appbarNavlink"
                  onClick={() => {
                    signOut(auth)
                      .then(() => {
                        // Sign-out successful.
                        console.log("signed out");
                        setUser(null);
                        localStorage.removeItem("user");
                      })
                      .catch((error) => {
                        // An error happened.
                        console.log(error);
                      });
                  }}
                >
                  Log out
                </div>
              </NavLink>
            </>
          ) : (
            <NavLink
              className="appbarNavlink"
              style={{ textDecoration: "none" }}
              to="/login"
            >
              Login
            </NavLink>
          )}
        </div>
      </Toolbar>

      {matchesSm && (
        <div>
          <Menu right>
            <NavLink
              style={{ backgroundColor: "transparent", color: "black" }}
              to="/"
            >
              Home
            </NavLink>
            <NavLink
              style={{ backgroundColor: "transparent", color: "black" }}
              to="/login"
            >
              Login
            </NavLink>

            {user ? (
              <>
                <div className="bm-item">
                  <NavLink
                    to="/prodashboard"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Dashboard
                  </NavLink>
                </div>
                <div className="bm-item">
                  <NavLink
                    to="/proSchedule"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Schedule
                  </NavLink>
                </div>
                <div className="bm-item">
                  <NavLink
                    to="/profile"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Profile
                  </NavLink>
                </div>
                <NavLink
                  to="/"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  <div
                    onClick={() => {
                      signOut(auth)
                        .then(() => {
                          // Sign-out successful.
                          console.log("signed out");
                          setUser(null);
                          localStorage.removeItem("user");
                        })
                        .catch((error) => {
                          // An error happened.
                          console.log(error);
                        });
                    }}
                  >
                    Log out
                  </div>
                </NavLink>
              </>
            ) : null}
          </Menu>
        </div>
      )}
    </AppBar>
  );
}

export default Navbar;
