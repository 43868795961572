import React from "react";
import {
  AppBar,
  Box,
  Button,
  Paper,
  Toolbar,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Divider,
} from "@material-ui/core";
import { stack as Menu } from "react-burger-menu";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { UserContext } from "../../../components/UserContext";
import { NavLink, useHistory } from "react-router-dom";
import { auth, db } from "../../../components/Firebase";
import useStyles from "../../New Home/Styles";
import { PersonAddAlt1 } from "@mui/icons-material";
import { PersonAdd } from "@material-ui/icons";
import { Alert } from "@mui/material";
import Navbar from "../../../components/Navbar";
import { collection, getDocs } from "firebase/firestore";
import axios from "axios";
import config from "../../QA/Config";

function Customers() {
  const [user, setUser] = React.useContext(UserContext);
  const [customerData, setCustomerData] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    function checkUser() {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (userCred) => {
        if (userCred) {
          setUser(userCred);

          const data = await axios
            .post(config.PRO_CUSTOMERLIST, {
              UID: userCred.uid,
            }, {
              headers: {
                "Content-Type": "application/json",
                "X-Mode": 'PROD', // Set the custom header "X-Mode" with the mode value
              }
            })
            .then((response) =>
              setCustomerData((prevState) => [...prevState, response.data])
            );

          console.log("data", data);

          return;
        } else {
          setUser(null);
          history.push("/login");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  console.log("customerData", customerData);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Navbar pageTitle="Customers" />

      <div style={{ marginLeft: "2%" }}>
        <h6
          style={{
            fontWeight: "bolder",
            display: "flex",
            fontSize: "100%",
            paddingTop: "2vw",
            paddingBottom: "1%",
          }}
        >
          Customer's List
        </h6>

        {customerData[0]?.length > 1 ? (
          customerData[0].map((payload, index) => (
            <Card
              elevation={2}
              key={payload.index}
              style={{
                backgroundColor: "white",
                width: "98.5%",
                marginBottom: "10px",
                padding: "5px",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {payload.SpringCleanup ? (
                    <>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          color: customerData[0][index].New ? "red" : "black",
                          textAlign: "left",
                        }}
                      >
                     {index+1}.   {payload.SpringCleanup?.ServiceAddr.split(", USA")}
                      </Typography>
                      <Typography style={{ display: "flex" }}>
                        {payload.SpringCleanup?.ServiceCat}[
                        {payload.SpringCleanup?.SubscriptionType}]{" "}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          textAlign: "left",
                          color: customerData[0][index].New ? "red" : "black",
                        }}
                      >
                       {index+1}. {payload.LawnMowing?.ServiceAddr.split(", USA")}
                      </Typography>
                      <Typography style={{ display: "flex" }}>
                        {payload.LawnMowing?.ServiceCat}[
                        {payload.LawnMowing?.SubscriptionType}]{" "}
                      </Typography>
                    </>
                  )}
                </Box>
              </div>
            </Card>
          ))
        ) : (
          <Card
            elevation={2}
            style={{
              backgroundColor: "white",
              width: "98.5%",
              marginBottom: "10px",
              padding: "5px",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ fontWeight: "bold", display: "flex" }}>
                  No customers were found.
                </Typography>
              </Box>
            </div>
          </Card>
        )}
      </div>
    </>
  );
}

export default Customers;
