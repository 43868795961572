// CompanyInformation.js    Created 9/4/2022    Rev 4.8

// Purpose:
// This is a subcomponent for Prosignup page.
//  This code provides UI for Comapny information.

import { Divider, Button, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "../../New Home/Styles";
import axios from "axios";

function CompanyInformation({ formData, setFormData, nextStep, prevStep }) {
  const classes = useStyles();
  const [phoneError, setPhoneError] = React.useState();
  const [locationFound, setLocationFound] = React.useState();
  const [badaddress, setbadaddress] = React.useState();

  const API_KEY = process.env.REACT_APP_POSITION_STACK_API_KEY;

  return (
    <div>
      <h2 className={classes.h2}>Company Information</h2>
      <p className={classes.p}>
        We need to know a little bit more about your organization.
      </p>
      <div style={{ display: "grid", placeItems: "center" }}>
        <Divider
          style={{
            backgroundColor: "black",
            width: "80vw",
            marginBottom: "1%",
            marginTop: "1%",
          }}
        />
      </div>
      <div
        style={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <form className={classes.loginForm} style={{ maxWidth: "400px" }}>
          <label htmlFor="companyName" style={{ marginBottom: "5px" }}>
            Company Name
          </label>
          <input
            required
            type="text"
            placeholder="Alltramatic"
            id="cname"
            value={formData.companyName}
            style={{ marginBottom: "20px" }}
            className="signUpInput"
            onChange={(e) =>
              setFormData({ ...formData, companyName: e.target.value })
            }
          ></input>

          <label htmlFor="badress">Business Address</label>
          <input
            required
            autoComplete="disabled"
            type="text"
            placeholder="USA"
            style={{
              backgroundColor: badaddress ? "#ea9898" : "",
              marginBottom: "20px",
            }}
            id="businessAddress"
            value={formData.businessAdress}
            onChange={(e) => {
              setFormData({ ...formData, businessAdress: e.target.value });

              const params = {
                apiKey: API_KEY,
                searchtext: e.target.value,
              };

              axios
                .get("https://geocoder.ls.hereapi.com/6.2/geocode.json", {
                  params,
                })
                .then((response) => {
                  var Position =
                    response.data.Response.View[0].Result[0].Location
                      .DisplayPosition;
                  setLocationFound(Position);
                  if (locationFound) setbadaddress(false);
                  else {
                    setbadaddress(true);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setbadaddress(true);
                });
            }}
            className="signUpInput"
          ></input>

          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                marginRight: "5vw",
              }}
            >
              <label htmlFor="badress">Phone</label>
              <input
                required
                type="tel"
                placeholder="100-000-000"
                id="phoneno"
                style={{ backgroundColor: phoneError ? "#ea9898" : "" }}
                value={formData.phone}
                onChange={(e) => {
                  setFormData({ ...formData, phone: e.target.value });
                  var Validphoneno =
                    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                  if (e.target.value.match(Validphoneno)) {
                    setPhoneError(false);
                    return;
                  } else {
                    setPhoneError(true);
                    return;
                  }
                }}
                className="signUpInput"
              ></input>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label htmlFor="badress">Zipcode</label>
              <input
                readOnly
                type="tel"
                placeholder="11791"
                id="zipcode"
                value={JSON.parse(localStorage.getItem("ZIPCODE"))}
                style={{ backgroundColor: "#dbdbdb" }}
                className="signUpInput"
              ></input>
            </div>
          </div>
          <Button
            className={classes.buttonPrimary}
            style={{
              fontWeight: "bold",
              marginTop: "4%",
              backgroundColor:
                !formData.companyName ||
                !formData.businessAdress ||
                !formData.phone ||
                phoneError ||
                badaddress
                  ? "#D3D3D3"
                  : "#187CAE",
            }}
            onClick={(e) => {
              e.preventDefault();
              console.log(
                formData.zipcode,
                "Data"
              );
              if (
                !formData.companyName ||
                !formData.businessAdress ||
                !formData.phone
              ) {
                alert("Fill all the details!");
                return;
              }
              if (badaddress) {
                alert("Enter correct address with zipcode");
              }
              if (phoneError) {
                alert("Enter valid Phone Number");
              } else {
                nextStep();
              }
            }}
          >
            Next
          </Button>
          {/* <Button
            style={{
              fontWeight: "bold",
              width: "100%",
              maxWidth: "400px",
              marginTop: "3%",
              border: "2px solid #ED9278",
              borderRadius: "28px",
              fontSize: "100%",
              padding: "5px 10px",
              whiteSpace: "nowrap",
              minWidth: "180px!important",
              minHeight: "45px",
            }}
            onClick={(e) => {
              e.preventDefault();
              prevStep();
            }}
          >
            <Typography
              style={{ color: "#ED9278", fontSize: "100%", fontWeight: "bold" }}
            >
              Previous
            </Typography>
          </Button> */}
        </form>
      </div>
    </div>
  );
}

export default CompanyInformation;
