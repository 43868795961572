import React from "react";
import { useHistory } from "react-router-dom";
import {
  LawnMowerBG,
  Container1,
  TextContainer2,
  ItemText2,
  TextContainer3,
  TextContainer4,
} from "../../New Home/NewHome_style";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import LooksOneRoundedIcon from "@mui/icons-material/LooksOneRounded";
import LooksTwoRoundedIcon from "@mui/icons-material/LooksTwoRounded";
import Looks3RoundedIcon from "@mui/icons-material/Looks3Rounded";
import Looks4RoundedIcon from "@mui/icons-material/Looks4Rounded";
import Looks5RoundedIcon from "@mui/icons-material/Looks5Rounded";
import Icon1 from "../../../components/assets/images/Icon-1.png";
import Icon2 from "../../../components/assets/images/Icon-2.png";
import Icon3 from "../../../components/assets/images/Icon-3.png";
import Icon4 from "../../../components/assets/images/Icon-4.png";
import Icon5 from "../../../components/assets/images/Icon-5.png";
import Icon6 from "../../../components/assets/images/Icon-6.png";
import Icon7 from "../../../components/assets/images/Icon-7.png";
import Icon8 from "../../../components/assets/images/Icon-8.png";

import {
  Grid,
  Box,
  CardContent,
  Typography,
  Paper,
  Divider,
  InputBase,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Review1 from "../../../components/assets/images/Proreview-1.jpg";
import Review2 from "../../../components/assets/images/Proreview-2.jpg";
import Review3 from "../../../components/assets/images/Proreview-3.jpg";
import Review4 from "../../../components/assets/images/Proreview-4.jpg";
import Review5 from "../../../components/assets/images/Proreview-5.jpg";
import Review6 from "../../../components/assets/images/Proreview-6.jpg";
import Review7 from "../../../components/assets/images/Proreview-7.jpg";
import Review8 from "../../../components/assets/images/Proreview-8.jpg";

import useStyles from "../../New Home/Styles";
import QANavbar from "../../../components/QANavbar";
import { collection, getDoc, getDocs, query, where } from "firebase/firestore";
import { authQA, dbQA } from "../../../components/Firebase";
import { UserContext } from "../../../components/UserContext";
import { onAuthStateChanged } from "firebase/auth";

function LandingPage() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setemail] = React.useState();
  const [zipcode, setzipcode] = React.useState();
  const [error, setError] = React.useState();
  const [emailError, setEmailError] = React.useState();
  const imageRef = React.useRef(null);
  const [userQA, setUserQA] = React.useContext(UserContext);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  // React.useEffect(() => {
  //   function checkUser() {
  //     const admin = JSON.parse(window.localStorage.getItem("userQA"));
  //     if (admin) {
  //       setUserQA(admin);
  //     } else {
  //       setUserQA(null);
  //       history.push("/QAadminLogin");
  //     }
  //   }
  //   checkUser();
  // }, []);
  // eslint-disable-line react-hooks/exhaustive-deps

  const Faqs = [
    {
      name: "How much does it cost to use LawnStarter?",
      review:
        "Nothing! Alltramatic takes a small portion of the price but never charges our providers to use the service. You get paid exactly what you see when a job is offered to you.",
    },
    {
      name: "What type of jobs do you offer?",
      review:
        "With Alltramatic, most job offers are residential lawn maintanence. New service providers will be assigned with basic lawn maintanence offers. As you earned higher ratings from the customers, you will be enabled to provide additional services.",
    },
    {
      name: "Are you a lead generation service?",
      review:
        "Not at all. Alltramatic is here to help you find, schedule and communicate with customers without you having to waste money on leads that go nowhere. Each time you see a job offered it's from someone our customer who has already signed up for service. We find customers and take care of the paper work, so you spend your value time with your crew and family!",
    },
    {
      name: "How and When do I get paid for my services?",
      review:
        "With Alltramatic, getting paid is easy. Just enter your bank info after you create an account and we'll deposit the money you earn once a week. For security, We use Stripe, who helps large companies like Lyft, Target and Amazon process their payments, so your information is secure!",
    },
    {
      name: "Do I have to bid on jobs? ",
      review:
        "Nope! Alltramatic uses property data and software to price each lawn based on square footage and other factors. You don't have to worry about bidding on jobs or visiting properties to price them yourself. Once you provide the first service, the software will offer you add-on services for our customers.",
    },
    {
      name: "Do I have to follow the daily job schedules assigned?",
      review:
        "With Alltramatic, the Smart Scheduler software will adjust automatically to your route perference for weekly customers and communicate that to the customer ahead of your arrival. For the 1-time customers, you can choose to accept the job offered. You have options to reschedule a weekly customer of unexpected events such as weather or equipment maintenance",
    },
    {
      name: "How do I handle special situations?",
      review:
        "With Alltramatic, most normal situations will be handled by the automated system and provide you different effective options to send and received message to the customer, reschedule, document a job site issue and address customer claims. In most cases, many issues can be resolved in the weekly visits, but be prepared to give higher priority to handle special request from the customers. ",
    },
  ];

  const secondBlock = [
    {
      fontSize: "large",
      color: "warning",
      placeItems: "left",
      text: "José, Plainview, NY",
      text1: "Jun, 2022",
      description:
        "“I was not sure about this company if it is just like another Angi or Thumbtack. To my surprise, it is very easy to signup, took a few days to start getting customer orders from them and the daily scheduling software is easy to use outside on the road. I do both my own customers and theirs. Payout is reliable.”",
    },
    {
      fontSize: "large",
      color: "warning",
      text: "Antonio, Seaford, NY",

      text1: "Aug, 2022",

      description:
        "“The 1st couple of weeks after signup, I got a couple of 1-time orders and few weekly orders. Then now I got 10+ weekly orders. A few of them are slightly outside of my area, but enough for me to add a day and replan my routes. Like the fast payout and don’t have to deal with the paperwork. The downside is they are not my customers, but am getting more weekly jobs.”",
    },
    {
      fontSize: "small",
      color: "warning",
      text: "Ramon, Oyter Bay, NY",
      text1: "Jul, 2022",
      description:
        "“The payout is aight. some orders are priced a little lower than I would like, but some are a little bit better with the tips. orders are usually for basic maintenance, but was told it can add extra services later. waiting for that to happen, you know what I’m saying.”",
    },
    {
      fontSize: "large",
      color: "warning",
      text: "Jay, Mineola, NY",

      text1: "Aug, 2022",
      description:
        "“Lawn mowing, fertilization, bush trimming. We can do it all“I’m fairly new to the biz doing solo. Made a few attempts to prompt ads on my own, but takes too much effort with little results. This gives me  extra jobs without upfront cost. Seems working out ok for the past couple weeks. Simple and similar to some of my previous uber and delivery jobs. If this gets me 50 per week, going to think about getting a helper. Software is cool.”",
    },
  ];

  const StartFunction = () => {
    console.log(email, zipcode, error, "error zipenail");
    if (email && zipcode && !error && !emailError) {
      const EmailQuery = query(
        collection(dbQA, "ProCompany"),
        where("Email", "==", email)
      );
      getDocs(EmailQuery)
        .then((snapdata) => {
          console.log("snapdata");
          if (snapdata.empty) {
            history.push("/QAsignup");
            return;
          } else {
            alert("Username is taken");
            return;
          }
        })
        .catch((err) => console.log(err));
    }
    if (error) {
      alert("Enter proper zipcode");
      return;
    }
    if (emailError) {
      alert("Enter proper Email address");
      return;
    }
    if (!zipcode || !email) {
      alert("Please enter email and zipcode");
      return;
    }
  };

  return (
    <>
      <QANavbar pageTitle="Landing Page" />
      <Container1>
        <TextContainer2>
          <ItemText2>
            <h1 style={{ marginTop: "-5%" }}>
              {" "}
              Build your dream home and manage your properties
            </h1>
          </ItemText2>
        </TextContainer2>

        <TextContainer3 style={{ marginTop: "1%", padding: "1%" }}>
          <ItemText2>
            <p style={{ color: "white", fontFamily: "Poppins" }}>
              If you are a lawn care professional, interested in getting more
              high quality customers and keeping the most pay for your hard
              work, Join us.
            </p>
          </ItemText2>
        </TextContainer3>
      </Container1>
      {/* Background Main BG */}
      <div className={classes.backgroundBG} ref={imageRef}>
        <LawnMowerBG />
      </div>

      {/* //div for second layout on screen */}
      <div
        style={{
          display: "grid",
          placeItems: "center",
          paddingTop: "2%",
          marginBottom: "5%",
        }}
      >
        <Grid
          container
          lg={10}
          sm={12}
          xl={12}
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xl={12} lg={12} sm={12} xs={12}>
            <p
              className={classes.p}
              style={{
                color: "black",
                marginBottom: "2%",
                fontSize: "2.6vh",
                marginLeft: "5%",
                marginRight: "5%",
                textAlign: "justify",
                fontFamily: "Poppins",
              }}
            >
              Quick easy sign up and let us take care of finding you the best
              customers, while you do what you love the most. Make our
              neighborhood lawns beautiful! If you are providing services in
              Nassau, NY join us!
            </p>
          </Grid>

          {/* INPUTS */}
          <Grid item xl={12} lg={12} sm={12} xs={12}>
            <div style={{ display: "grid", placeItems: "center" }}>
              <Paper
                component="form"
                elevation={false}
                className={classes.searchBoxLandPage}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  style={{ width: "80%" }}
                  placeholder="Enter Email"
                  inputProps={{ "aria-label": "Enter Email" }}
                  onChange={(e) => {
                    setemail(e.target.value);
                    localStorage.setItem(
                      "EMAIL",
                      JSON.stringify(e.target.value)
                    );
                    var validEmail =
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (e.target.value.match(validEmail)) {
                      setEmailError(false);
                    } else {
                      setEmailError(true);
                    }
                  }}
                />
                {/* <Box sx={{ flex: 1 }} /> */}
                <Divider orientation="vertical" flexItem />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  style={{ width: "35%", paddingLeft: "10px" }}
                  placeholder="Zipcode"
                  inputProps={{ "aria-label": "Zipcode" }}
                  onChange={(e) => {
                    setzipcode(e.target.value);
                    localStorage.setItem(
                      "ZIPCODE",
                      JSON.stringify(e.target.value)
                    );
                    var validZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
                    if (e.target.value.match(validZipCode)) {
                      setError(false);
                    } else {
                      setError(true);
                    }
                  }}
                />
                <Button
                  color="primary"
                  disableElevation
                  type="button"
                  className={classes.InstantQuoteButtonLandPage}
                  variant="contained"
                  onClick={StartFunction}
                >
                  Start
                </Button>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </div>

      <h2
        className={classes.h2}
        style={{ color: "black", fontSize: matchesSm ? "2.7vh" : "" }}
      >
        Partner with us and grow together!
      </h2>

      {/* Responsive Cards: Features of Alltramatic */}

      <Divider style={{ marginTop: "2%" }} />

      <div style={{ display: "grid", placeItems: "center", paddingTop: "0" }}>
        <Grid
          container
          lg={10}
          sm={12}
          xl={12}
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{
            display: "flex",
            flexDirection: matchesSm ? "column-reverse" : "row",
            marginTop: matchesSm ? "20px" : "0px",
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            sm={10}
            xs={11}
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <div>
              <p
                className={classes.p}
                style={{
                  marginTop: "3%",
                  fontSize: matchesSm ? "2.3vh" : "2.6vh",
                  textAlign: matchesSm ? "justify" : "left",
                  marginBottom: "3%",
                  width: matchesSm ? "90%" : "70%",
                  marginLeft: matchesSm ? "10px" : "5%",
                }}
              >
                We have the technology and data algorithm to connect great
                customers with simple online prices and services.
              </p>
            </div>
          </Grid>
          <Grid item xl={6} lg={6} sm={12} xs={12}>
            <img
              src={Review8}
              style={{ width: "80%", height: "100%" }}
              alt="banner"
            />
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div style={{ display: "grid", placeItems: "center" }}>
        <Grid
          container
          lg={10}
          sm={12}
          xl={12}
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{
            display: "flex",
            flexDirection: matchesSm ? "column" : "row",
            marginTop: matchesSm ? "20px" : "0px",
          }}
        >
          <Grid item xl={6} lg={6} sm={12} xs={12}>
            <img
              src={Review2}
              style={{ width: "80%", height: "100%" }}
              alt="banner"
            />
          </Grid>
          <Grid item xl={6} lg={6} sm={11} xs={12}>
            <div style={{ marginLeft: matchesSm ? "3%" : "10%" }}>
              <p
                className={classes.p}
                style={{
                  marginTop: "3%",
                  fontSize: matchesSm ? "2.3vh" : "2.6vh",
                  textAlign: matchesSm ? "justify" : "left",
                  marginBottom: "3%",
                  width: matchesSm ? "90%" : "70%",
                  marginLeft: matchesSm ? "10px" : "5%",
                }}
              >
                You keep more than 90% of the pay. When you take great care of
                the customers, there are additional incentives to get pay above
                100%
              </p>
            </div>
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div style={{ display: "grid", placeItems: "center" }}>
        <Grid
          container
          lg={10}
          sm={12}
          xl={12}
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{
            display: "flex",
            flexDirection: matchesSm ? "column-reverse" : "row",
            marginTop: matchesSm ? "20px" : "0px",
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            sm={10}
            xs={11}
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <div style={{ marginLeft: matchesSm ? "3%" : "10%" }}>
              <p
                className={classes.p}
                style={{
                  marginTop: "3%",
                  fontSize: matchesSm ? "2.3vh" : "2.6vh",
                  textAlign: matchesSm ? "justify" : "left",
                  marginBottom: "3%",
                  width: matchesSm ? "90%" : "70%",
                  marginLeft: matchesSm ? "10px" : "5%",
                }}
              >
                We use data to help you update your services to drive efficiency
                and high quality with customer satisfaction. See details
                here(link)
              </p>
            </div>
          </Grid>
          <Grid item xl={6} lg={6} sm={12} xs={12}>
            <img
              src={Review3}
              style={{ width: "80%", height: "100%" }}
              alt="banner"
            />
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div style={{ display: "grid", placeItems: "center" }}>
        <Grid
          container
          lg={10}
          sm={12}
          xl={12}
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{
            display: "flex",
            flexDirection: matchesSm ? "column" : "row",
            marginTop: matchesSm ? "20px" : "0px",
          }}
        >
          <Grid item xl={6} lg={6} sm={12} xs={12}>
            <img
              src={Review5}
              style={{ width: "80%", height: "100%" }}
              alt="banner"
            />
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            sm={10}
            xs={11}
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <div style={{ marginLeft: matchesSm ? "3%" : "10%" }}>
              <p
                className={classes.p}
                style={{
                  marginTop: "3%",
                  fontSize: matchesSm ? "2.3vh" : "2.6vh",
                  textAlign: matchesSm ? "justify" : "left",
                  marginBottom: "3%",
                  width: matchesSm ? "90%" : "70%",
                  marginLeft: matchesSm ? "10px" : "5%",
                }}
              >
                You join our professional community and we will provide coverage
                for you when you need it.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div style={{ display: "grid", placeItems: "center" }}>
        <Grid
          container
          lg={10}
          sm={12}
          xl={12}
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{
            display: "flex",
            flexDirection: matchesSm ? "column-reverse" : "row",
            marginTop: matchesSm ? "20px" : "0px",
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            sm={10}
            xs={11}
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <div style={{ marginLeft: matchesSm ? "3%" : "10%" }}>
              <p
                className={classes.p}
                style={{
                  marginTop: "3%",
                  fontSize: matchesSm ? "2.3vh" : "2.6vh",
                  textAlign: matchesSm ? "justify" : "left",
                  marginBottom: "3%",
                  width: matchesSm ? "90%" : "70%",
                  marginLeft: matchesSm ? "10px" : "5%",
                }}
              >
                Fast payouts directly to your account.
              </p>
            </div>
          </Grid>
          <Grid item xl={6} lg={6} sm={12} xs={12}>
            <img
              src={Review1}
              style={{ width: "80%", height: "100%" }}
              alt="banner"
            />
          </Grid>
        </Grid>
      </div>

      <Divider style={{ marginBottom: "3%" }} />

      <div
        style={{
          display: "grid",
          placeItems: "center",
          background: "rgb(44,75,90)",
          marginTop: "2%",
        }}
      >
        <Grid
          container
          lg={10}
          sm={12}
          xl={12}
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xl={6}
            lg={6}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          ></Grid>
          <Grid item xs={12} style={{ marginBottom: "5%" }}>
            <h2
              className={classes.h2}
              style={{ color: "white", marginTop: "3%" }}
            >
              Sign Up Now!
            </h2>
            <p
              className={classes.p}
              style={{ color: "white", marginTop: "10px" }}
            >
              Get the benefits of being a pro in 5 easy steps
            </p>
          </Grid>
          <Timeline style={{ width: "100vw" }}>
            <TimelineItem>
              <TimelineOppositeContent>
                <img src={Icon8} style={{ marginRight: "20%" }} alt="icon" />
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <LooksOneRoundedIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className={classes.Timelinep}>
                  <Typography
                    variant="h6"
                    component="span"
                    style={{
                      color: "white",
                      fontSize: "3vh",
                      lineHeight: "10px",
                    }}
                  >
                    Pro Signup
                  </Typography>
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    Service Professionals must create a Pro account using an
                    email id and zipcode in order to get started.
                  </Typography>
                </div>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem position="left">
              <TimelineOppositeContent>
                <img src={Icon5} style={{ marginLeft: "20%" }} alt="icon" />
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <LooksTwoRoundedIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div
                  className={classes.Timelinep}
                  style={{ paddingRight: "10%" }}
                >
                  <Typography
                    variant="h6"
                    component="span"
                    style={{
                      color: "white",
                      fontSize: "3vh",
                      lineHeight: "10px",
                    }}
                  >
                    Download the mobile Pro App
                  </Typography>
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    Pros must use the App to set up their service area and allow
                    it to auto track job completion
                  </Typography>
                </div>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent>
                <img
                  src={Icon6}
                  style={{ marginRight: "20%", minWidth: "50px" }}
                  alt="icon"
                  width="12%"
                />
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Looks3RoundedIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className={classes.Timelinep}>
                  <Typography
                    variant="h6"
                    component="span"
                    style={{
                      color: "white",
                      fontSize: "3vh",
                      lineHeight: "10px",
                    }}
                  >
                    Jobs Assigned
                  </Typography>
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    Jobs Assigned Customer service requests are then scheduled
                    based on the pro’s preferences. Jobs can be accepted at the
                    pro’s convenience.{" "}
                  </Typography>
                </div>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem position="left">
              <TimelineOppositeContent>
                <img
                  src={Icon7}
                  style={{ marginLeft: "20%", minWidth: "50px" }}
                  alt="icon"
                  width="12%"
                />
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Looks4RoundedIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div
                  className={classes.Timelinep}
                  style={{ paddingRight: "10%" }}
                >
                  <Typography
                    variant="h6"
                    component="span"
                    style={{
                      color: "white",
                      fontSize: "3vh",
                      lineHeight: "10px",
                    }}
                  >
                    Provide Service
                  </Typography>
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    Pros must set up their work preferences such as job
                    location.
                  </Typography>
                </div>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent>
                <img
                  src={Icon4}
                  style={{ marginRight: "20%", minWidth: "50px" }}
                  alt="icon"
                  width="12%"
                />
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <Looks5RoundedIcon />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <div className={classes.Timelinep}>
                  <Typography
                    variant="h6"
                    component="span"
                    style={{
                      color: "white",
                      fontSize: "3vh",
                      lineHeight: "10px",
                    }}
                  >
                    Recieve Payment
                  </Typography>
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    Once the job is successfully completed the payment is
                    recieved by the pro directly into the pro's account
                  </Typography>
                </div>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Grid>
        <button
          style={{ fontSize: "2vh", marginBottom: "5%" }}
          className="buttonPrimary"
          onClick={() => {
            window.scrollTo({
              top: imageRef.current.offsetTop,
              behavior: "smooth",
              color: "#233D53",
            });
          }}
        >
          Pro Sign Up
        </button>
      </div>

      {/* //FAQS DIVISION */}
      <div>
        <h2 className={classes.h2} style={{ color: "black", marginTop: "3%" }}>
          FAQs
        </h2>

        <Divider
          style={{ marginBottom: "3%", marginLeft: "5%", marginRight: "5%" }}
        />
        {Faqs.map((label) => {
          return (
            <>
              <Grid
                container
                sm="12"
                xs="12"
                style={{ paddingLeft: "5%", paddingRight: "5%" }}
              >
                <Grid item sm="12" xs="12">
                  <Typography
                    variant="h5"
                    align="justify"
                    style={{
                      fontWeight: "bold",
                      marginLeft: "10px",
                      fontFamily: "Poppins",
                    }}
                  >
                    {label.name}
                  </Typography>
                  <div className={classes.component}>
                    <p
                      className={classes.p}
                      style={{
                        fontSize: "2.6vh",
                        textAlign: "justify",
                        marginBottom: "3%",
                      }}
                    >
                      {label.review}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </>
          );
        })}
      </div>

      <div
        style={{
          display: "grid",
          placeItems: "center",
          paddingTop: "3%",
          background: "rgb(44,75,90)",
          marginTop: "3%",
        }}
      >
        <Grid
          container
          lg={12}
          sm={12}
          xl={12}
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xl={6}
            lg={6}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          ></Grid>
          <Grid item xs={12}></Grid>
          <ItemText2>
            <h3>Hear from some of our Pro's!</h3>
          </ItemText2>

          <Box
            sx={{
              width: "85%",
            }}
          >
            <Grid
              style={{ background: "rgb(44,75,90)" }}
              container
              direction="row"
              justifyContent="space-evenly"
              className={classes.root}
            >
              {secondBlock.map((data, index) => {
                return (
                  <>
                    <Grid
                      container
                      xs={12}
                      md={6}
                      lg={5}
                      style={{ padding: "2%" }}
                    >
                      <Paper className={classes.paper} variant="elevation">
                        <div
                          className={classes.component}
                          style={{
                            marginTop: "3%",
                          }}
                        >
                          {data.icon}
                        </div>
                        <CardContent>
                          <p
                            className={classes.p}
                            style={{
                              fontSize: "2.5vh",
                              textAlign: "justify",
                              padding: "5%",
                              color: "black",
                              minHeight: "180px",
                            }}
                          >
                            {data.description}
                          </p>

                          <h3
                            style={{
                              color: "black",
                              textAlign: "left",
                              paddingLeft: "5%",
                            }}
                          >
                            {data.text}
                          </h3>
                          <h3
                            style={{
                              color: "black",
                              textAlign: "left",
                              paddingLeft: "5%",
                            }}
                          >
                            {data.text1}
                          </h3>
                        </CardContent>
                      </Paper>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </div>
    </>
  );
}

export default LandingPage;
