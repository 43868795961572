import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC5L18tS49f_SMp1Vd4qZZYrBvUp5P0l2w",
  authDomain: "user-database-2021.firebaseapp.com",
  databaseURL: "https://user-database-2021-default-rtdb.firebaseio.com",
  projectId: "user-database-2021",
  storageBucket: "user-database-2021.appspot.com",
  messagingSenderId: "49535356389",
  appId: "1:49535356389:web:dda40ef6f048607b81b30c",
  measurementId: "G-WRPZXB55VX",
};

const firebaseConfigQA = {
  apiKey: "AIzaSyD6UAUXPqavwOaLDw8AaYCFEwi-CccUgCI",
  authDomain: "qadb-d5afd.firebaseapp.com",
  projectId: "qadb-d5afd",
  storageBucket: "qadb-d5afd.appspot.com",
  messagingSenderId: "711276359328",
  appId: "1:711276359328:web:ca798d7d6a170b6eebe3c5",
  measurementId: "G-C76ZV460VT",
};

const firebaseConfigNYU = {
  apiKey: "AIzaSyAgtLBzw_MfZjj17-r5wq-k3qGmDMKkDwU",
  authDomain: "nyustudentrent-78f60.firebaseapp.com",
  projectId: "nyustudentrent-78f60",
  storageBucket: "nyustudentrent-78f60.appspot.com",
  messagingSenderId: "904494659892",
  appId: "1:904494659892:web:551b488f03aaaf44e14d61",
  measurementId: "G-ZT8RCGBTMJ",
};

// Initialize Firebase
const firebaseapp = initializeApp(firebaseConfig);
const firebaseAppQA = initializeApp(firebaseConfigQA, "QADB");
const firebaseAppNYU = initializeApp(firebaseConfigNYU, "NYUDB");

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(firebaseapp);
const db = getFirestore(firebaseapp);
const authQA = getAuth(firebaseAppQA);
const dbQA = getFirestore(firebaseAppQA);
const storageQA = getStorage(firebaseAppQA);
const storageNYU = getStorage(firebaseAppNYU);

export { auth, db, authQA, dbQA, storageQA, storageNYU };

// firebase.initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_USERDB_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_USERDB_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_USERDB_DBURL,
//   projectId: process.env.REACT_APP_FIREBASE_USERDB_PROJID,
//   storageBucket: process.env.REACT_APP_FIREBASE_USERDB_StorageBucket,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_USERDB_MSGID,
//   appId: process.env.REACT_APP_FIREBASE_USERDB_APPID,
//   measurementId: process.env.REACT_APP_FIREBASE_USERDB_MEASUREMENTID,
// });

export const Map_Config = {
  apiKey: "AIzaSyCXavpOMv15WdZlnuAFZ3sn6H_bxKMjEYY",
  authDomain: "map-database-2022.firebaseapp.com",
  databaseURL: "https://map-database-2022-default-rtdb.firebaseio.com",
  projectId: "map-database-2022",
  storageBucket: "map-database-2022.appspot.com",
  messagingSenderId: "779334742314",
  appId: "1:779334742314:web:5e69b8bd00bf90f841ae53",
  measurementId: "G-N58YR2GLT5",
};

export const MapDb2_Config = {
  apiKey: "AIzaSyCIEL6v3bxfnBu9V_uSa5x_-lY-jSPDpnk",
  authDomain: "mapdb2-e25fd.firebaseapp.com",
  projectId: "mapdb2-e25fd",
  storageBucket: "mapdb2-e25fd.appspot.com",
  messagingSenderId: "843808197072",
  appId: "1:843808197072:web:217475e9695b208502d1d5",
  measurementId: "G-HHZ9WFMB4B",
};

export const User_Config = {
  apiKey: process.env.REACT_APP_FIREBASE_USERDB_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_USERDB_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_USERDB_DBURL,
  projectId: process.env.REACT_APP_FIREBASE_USERDB_PROJID,
  storageBucket: process.env.REACT_APP_FIREBASE_USERDB_StorageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_USERDB_MSGID,
  appId: process.env.REACT_APP_FIREBASE_USERDB_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_USERDB_MEASUREMENTID,
};

// const firebaseapp = initializeApp(User_Config);

// // Initialize Firebase Authentication and get a reference to the service
// const authUser = getAuth(firebaseapp);
// const userDB = getFirestore(firebaseapp);

// export {authUser, userDB}

export const Purchase_Config = {
  apiKey: process.env.REACT_APP_FIREBASE_PURCHASEDB_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_PURCHASEDB_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PURCHASEDB_PROJID,
  storageBucket: process.env.REACT_APP_FIREBASE_PURCHASEDB_StorageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_PURCHASEDB_MSGID,
  appId: process.env.REACT_APP_FIREBASE_PURCHASEDB_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_PURCHASEDB_MEASUREMENTID,
};

export const AGV_Config = {
  apiKey: process.env.REACT_APP_FIREBASE_AGVDB_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AGVDB_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_AGVDB_DBURL,
  projectId: process.env.REACT_APP_FIREBASE_AGVDB_PROJID,
  storageBucket: process.env.REACT_APP_FIREBASE_AGVDB_StorageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_AGVDB_MSGID,
  appId: process.env.REACT_APP_FIREBASE_AGVDB_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_AGVDB_MEASUREMENTID,
};

export const Media_Config = {
  apiKey: process.env.REACT_APP_FIREBASE_MEDIADB_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_MEDIADB_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_MEDIADB_DBURL,
  projectId: process.env.REACT_APP_FIREBASE_MEDIADB_PROJID,
  storageBucket: process.env.REACT_APP_FIREBASE_MEDIADB_StorageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MEDIADB_MSGID,
  appId: process.env.REACT_APP_FIREBASE_MEDIADB_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEDIADB_MEASUREMENTID,
};

export const Pro_Config = {
  apiKey: "AIzaSyC2OLl3l-PzC3HNrGKYKaGnYg4gJLhUvJE",
  authDomain: "pro-database-a0aa0.firebaseapp.com",
  projectId: "pro-database-a0aa0",
  storageBucket: "pro-database-a0aa0.appspot.com",
  messagingSenderId: "89293184296",
  appId: "1:89293184296:web:f2545166261afcd912c41d",
  measurementId: "G-GMVEHB1F9Q",
};

function Firebase() {}

export default Firebase;
