// Schedule.js    Created 8/8/2022    Rev 4.8

// Purpose:
// Pro Schedule page which reflects today's schedule to the pro user.
// Functions:
// 1. Start and stop button which records the start and stop time of the proUser
// 2. Show today's schedule

// Import all the packages
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../components/UserContext";
import { NavLink, useHistory } from "react-router-dom";
import {
  FormControl,
  Select,
  MenuItem,
  AppBar,
  Toolbar,
  Box,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { stack as Menu } from "react-burger-menu";
import { onAuthStateChanged, signOut } from "firebase/auth";
import EnglishSchedule from "./Components/EnglishSchedule";

//import styling
import "./ProUser.css";
import useStyles from "../../New Home/Styles";

//import assets
import { auth, db } from "../../../components/Firebase";
import SpanishSchedule from "./Components/SpanishSchedule";
import { Language } from "@material-ui/icons";

function Schedule() {
  const [user, setUser] = useContext(UserContext);
  const [language, setLanguage] = React.useState(UserContext);
  const history = useHistory();
  const theme = useTheme();

  // MUI Responsiveness:
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // Select a language
    setLanguage(localStorage.getItem("language"));

    function checkUser() {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (userCred) => {
        if (userCred) {
          setUser(userCred);
          return;
        } else {
          setUser(null);
          console.log("No user");
          history.push("/login");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Renders the particular on the current page.
  const PageDisplay = () => {
    if (language === "English") {
      localStorage.setItem("language", language);
      return <EnglishSchedule />;
    } else if (language === "Español") {
      localStorage.setItem("language", language);
      return <SpanishSchedule />;
    }
  };

  const handleChange = (event) => {
    // setChecked(event.target.checked);
    console.log(event.target.value, "lang");

    if (event.target.value === "English") {
      setLanguage("English");
    }
    if (event.target.value === "Español") {
      setLanguage("Español");
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        style={{ backgroundColor: "white", width: "100%", maxWidth: "100vw" }}
      >
        <Toolbar>
          <NavLink
            to="/"
            style={{ textDecoration: "none", backgroundColor: "transparent" }}
          >
            <img
              src={
                require("../../../components/assets/images/Logo_SVG_black.svg")
                  .default
              }
              className="logo"
              height="45vh"
              alt="Alltramatic logo"
            />
          </NavLink>

          <h3
            style={{
              paddingLeft: matchesSm ? "5%" : "30px",
              color: "black",
              width: matchesSm ? "" : "200px",
            }}
          >
            Schedule
          </h3>

          <Box flexGrow={1} justifyContent="center" />

          <div
            // className="NavLinks"
            style={{ display: "flex", alignItems: "center" }}
          >
            {user ? (
              <>
                {/* Language Selector */}
                <Tooltip title="Choose a Language" placement="left-start">
                  <FormControl
                    style={{
                      minWidth: matchesSm ? "80px" : "60px",
                      // width: "100%",
                      marginLeft: matchesSm ? "-35%" : "0%",
                    }}
                  >
                    <Select
                      value={language}
                      style={{ fontWeight: "bold" }}
                      className="appbarNavlink"
                      onChange={handleChange}
                      variant="standard"
                      disableUnderline
                    >
                      <MenuItem value="English">🇬🇧 En</MenuItem>
                      <MenuItem value="Español">🇪🇸 Es</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
                <NavLink to="/prodashboard" style={{ textDecoration: "none" }}>
                  <div className="appbarNavlink">Dashboard</div>
                </NavLink>
                <NavLink to="/proschedule" style={{ textDecoration: "none" }}>
                  <div className="appbarNavlink">Schedule</div>
                </NavLink>
                <NavLink to="/profile" style={{ textDecoration: "none" }}>
                  <div className="appbarNavlink">Profile</div>
                </NavLink>
                <NavLink to="/" style={{ textDecoration: "none" }}>
                  <div
                    className="appbarNavlink"
                    style={{ marginRight: "0%", paddingRight: "0%" }}
                    onClick={() => {
                      signOut(auth)
                        .then(() => {
                          // Sign-out successful.
                          console.log("signed out");
                          setUser(null);
                          localStorage.removeItem("user");
                        })
                        .catch((error) => {
                          // An error happened.
                          console.log(error);
                        });
                    }}
                  >
                    Logout
                  </div>
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  className="appbarNavlink"
                  style={{ textDecoration: "none" }}
                  to="/login"
                >
                  Login
                </NavLink>
              </>
            )}
          </div>
        </Toolbar>

        {matchesSm && (
          <div>
            <Menu right>
              {user ? (
                <>
                  <div className="bm-item" style={{ display: "grid" }}>
                    <NavLink
                      to="/"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        textDecoration: "none",
                      }}
                    >
                      Home
                    </NavLink>
                  </div>
                  <div className="bm-item" style={{ display: "grid" }}>
                    <NavLink
                      to="/prodashboard"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        textDecoration: "none",
                      }}
                    >
                      Dashboard
                    </NavLink>
                  </div>
                  <div className="bm-item" style={{ display: "grid" }}>
                    <NavLink
                      to="/proschedule"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        textDecoration: "none",
                      }}
                    >
                      Schedule
                    </NavLink>
                  </div>

                  <div className="bm-item" style={{ display: "grid" }}>
                    <NavLink
                      to="/profile"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        textDecoration: "none",
                      }}
                    >
                      Profile
                    </NavLink>
                  </div>
                  <NavLink
                    to="/"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    <div
                      onClick={() => {
                        signOut(auth)
                          .then(() => {
                            // Sign-out successful.
                            console.log("signed out");
                            setUser(null);
                            localStorage.removeItem("user");
                          })
                          .catch((error) => {
                            // An error happened.
                            console.log(error);
                          });
                      }}
                    >
                      Log out
                    </div>
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink
                    style={{ backgroundColor: "transparent", color: "black" }}
                    to="/"
                  >
                    Home
                  </NavLink>
                  <NavLink
                    style={{ backgroundColor: "transparent", color: "black" }}
                    to="/login"
                  >
                    Login
                  </NavLink>
                </>
              )}
            </Menu>
          </div>
        )}
      </AppBar>
      {PageDisplay()}
    </>
  );
}

export default Schedule;
