import {
  AppBar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Stack } from "@mui/material";
import { onAuthStateChanged, signOut } from "firebase/auth";
import React from "react";
import { NavLink } from "react-router-dom";
import { stack as Menu } from "react-burger-menu";
import { UserContext } from "../../../components/UserContext";
import EngDashboard from "./Components/EngDashboard";
import EslDashboard from "./Components/SpanishProUser";
import { authQA, dbQA } from "../../../components/Firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { ArrowDropDown, Language } from "@material-ui/icons";
import dayjs from "dayjs";

function Dashboard() {
  const [language, setLanguage] = React.useState(UserContext);
  const [checked, setChecked] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const [dashboardDetails, setDashboardDetails] = React.useState({});
  const [scheduledquotes, setscheduledquotes] = React.useState([]);
  const todayDate = new Date().toISOString().slice(0, 10);
  const [userQA, setUserQA] = React.useContext(UserContext);
  const [uuid, setuuid] = React.useState();
  const history = useHistory();

  React.useEffect(() => {
    // Select a language
    setLanguage(localStorage.getItem("language"));

    function checkUser() {
      localStorage.setItem("userQA", JSON.stringify(userQA));
      onAuthStateChanged(authQA, async (userCred) => {
        if (userCred) {
          setUserQA(userCred);
          setuuid(userCred.uid);

          const currentDate = dayjs();
          const currentYear = currentDate.year().toString();
          const currentMonth = currentDate.format("MMMM").toString();
          const startOfMonth = currentDate.startOf('month');
          const currentWeekOfMonth = Math.ceil((currentDate.diff(startOfMonth, 'day') + startOfMonth.day()) / 7);

          const serDocString = 'Services' + currentYear
          const weekString = 'Week ' + '- ' + currentWeekOfMonth

          //code to display the real Pro name from the acct read from FB
          const userInfo = await getDoc(doc(dbQA, "ProCompany", userCred.uid));

          const servInfo = await getDoc(doc(dbQA, "ProCompany", userCred.uid, serDocString, currentMonth));

          const customerDoc = await getDocs(collection(dbQA, "ProCompany", userCred.uid, "CustomerList"));
          var totalCustomers = 0;
          const documents = customerDoc.docs.map(doc => totalCustomers += 1);

          //FirstName and LastName are inside ProInfo in db
          setUserDetails({
            name:
              userInfo.data().OwnerInfo.FirstName +
              " " +
              userInfo.data().OwnerInfo.LastName,
          });

          // Check if dashboard summary is present

          const totalPrice = servInfo.data()[weekString].reduce((sum, obj) => sum + obj.ProPrice, 0);


          servInfo.data()[weekString]
            ? setDashboardDetails({
              jobsTotal: servInfo.data()[weekString].length,
              revenueTotal: totalPrice,
              customersTotal:
                totalCustomers,
            })
            : console.log("No Dashboard Data");

          return;
        } else {
          setUserQA(null);
          history.push("/QAadminLogin");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Renders the particular on the current page.
  const PageDisplay = () => {
    if (language === "English") {
      localStorage.setItem("language", language);
      return (
        <EngDashboard
          userDetails={userDetails}
          dashboardDetails={dashboardDetails}
          scheduledquotes={scheduledquotes}
          todayDate={todayDate}
        />
      );
    } else if (language === "Español") {
      localStorage.setItem("language", language);
      return (
        <EslDashboard
          userDetails={userDetails}
          dashboardDetails={dashboardDetails}
          scheduledquotes={scheduledquotes}
        />
      );
    }
  };

  const handleChange = (event) => {
    if (event.target.value === "English") {
      setLanguage("English");
    }
    if (event.target.value === "Español") {
      setLanguage("Español");
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        style={{ backgroundColor: "white", width: "100%", maxWidth: "100vw" }}
      >
        <Toolbar>
          <NavLink
            to="/QA"
            style={{ textDecoration: "none", backgroundColor: "transparent" }}
          >
            <img
              src={
                require("../../../components/assets/images/Logo_SVG_black.svg")
                  .default
              }
              className="logo"
              height="45vh"
              alt="Alltramatic logo"
            />
          </NavLink>

          <h3 style={{ paddingLeft: "30px", color: "black", width: "200px" }}>Pro Dashboard</h3>

          <Box
            flexGrow={1}
            justifyContent="center"
            style={{ marginTop: "10px" }}
          ></Box>

          <div
            className="NavLinks"
            style={{ display: "flex", alignItems: "center" }}
          >
            {userQA ? (
              <>

                {/* Language Selector */}
                <Tooltip title="Choose a Language" placement="left-start">
                  <FormControl style={{ minWidth: "60px" }}>
                    <Select
                      value={language}
                      style={{ fontWeight: "bold" }}
                      className="appbarNavlink"
                      onChange={handleChange}
                      variant="standard"
                      disableUnderline
                    >
                      <MenuItem value="English">🇬🇧 En</MenuItem>
                      <MenuItem value="Español">🇪🇸 Es</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>

                <NavLink to="/QAdashboard" style={{ textDecoration: "none" }}>
                  <div className="appbarNavlink">Dashboard</div>
                </NavLink>
                <NavLink to="/QAschedule" style={{ textDecoration: "none" }}>
                  <div className="appbarNavlink">Schedule</div>
                </NavLink>
                <NavLink to="/QAprofile" style={{ textDecoration: "none" }}>
                  <div className="appbarNavlink">Profile</div>
                </NavLink>
                <NavLink to="/QA" style={{ textDecoration: "none" }}>
                  <div
                    className="appbarNavlink"
                    onClick={() => {
                      signOut(authQA)
                        .then(() => {
                          // Sign-out successful.
                          console.log("signed out");
                          setUserQA(null);
                          localStorage.removeItem("userQA");
                        })
                        .catch((error) => {
                          // An error happened.
                          console.log(error);
                        });
                    }}
                  >
                    Logout
                  </div>
                </NavLink>
              </>
            ) : (
              <NavLink
                className="appbarNavlink"
                style={{ textDecoration: "none" }}
                to="/QAlogin"
              >
                Login
              </NavLink>
            )}
          </div>
        </Toolbar>

        <div>
          <Menu right>
            <NavLink
              style={{ backgroundColor: "transparent", color: "black" }}
              to="/QA"
            >
              Home
            </NavLink>
            <NavLink
              style={{ backgroundColor: "transparent", color: "black" }}
              to="/QAlogin"
            >
              Login
            </NavLink>

            {userQA ? (
              <>
                <div className="bm-item">
                  <NavLink
                    to="/QAdashboard"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Dashboard
                  </NavLink>
                </div>
                <div className="bm-item">
                  <NavLink to="/QAservice" style={{ textDecoration: "none" }}>
                    <div className="appbarNavlink">Services</div>
                  </NavLink>
                </div>
                <div className="bm-item">
                  <NavLink
                    to="/QAschedule"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Schedule
                  </NavLink>
                </div>

                <div className="bm-item">
                  <NavLink
                    to="/QAcustomer"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Customers
                  </NavLink>
                </div>
                <div className="bm-item">
                  <NavLink
                    to="/QAprofile"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Profile
                  </NavLink>
                </div>
                <NavLink
                  to="/QA"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  <div
                    onClick={() => {
                      signOut(authQA)
                        .then(() => {
                          // Sign-out successful.
                          console.log("signed out");
                          setUserQA(null);
                          localStorage.removeItem("userQA");
                        })
                        .catch((error) => {
                          // An error happened.
                          console.log(error);
                        });
                    }}
                  >
                    Log out
                  </div>
                </NavLink>
              </>
            ) : null}
          </Menu>
        </div>
      </AppBar>
      {PageDisplay()}
    </>
  );
}

export default Dashboard;
