// PersonalInfo.js    Created 9/4/2022    Rev 4.8

// Purpose:
// This is a subcomponent for Prosignup page.
//  This code provides UI for Personal Information.

import {
  Checkbox,
  Divider,
  FormControlLabel,
  Button,
  Typography,
} from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import useStyles from "../../../New Home/Styles";

function PersonalInfo({ formData, setFormData, prevStep, nextStep }) {
  const classes = useStyles();
  const [checkBoxTick, setCheckBoxTick] = React.useState(false);
  const [reenterEmail, setReenterEmail] = React.useState();
  const [emailError, setemailError] = React.useState(true);
  const [passwordError, setPasswordError] = React.useState(true);
  const [reenterPassword, setReenterPassword] = React.useState();
  const [repasswordError, setRePasswordError] = React.useState(true);

  const validateEmail = (email) => {
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  const validatePassword = (password) => {
    if (
      password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{6,}$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <h2 className={classes.h2}>Personal Information</h2>
      <p className={classes.p}>
        First, We need to know a little bit more about you.
      </p>
      <Divider
        style={{
          backgroundColor: "black",
          width: "80vw",
          marginBottom: "2%",
        }}
      />
      <div
        style={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <form className={classes.loginForm} style={{ maxWidth: "350px" }}>
          <label htmlFor="firstname">First Name</label>
          <input
            required
            type="text"
            autoComplete="disabled"
            placeholder="James"
            id="fname"
            value={formData.fName}
            className="signUpInput"
            onChange={(e) =>
              setFormData({ ...formData, fName: e.target.value })
            }
          ></input>

          <label htmlFor="lastname">Last Name</label>
          <input
            required
            type="text"
            autoComplete="disabled"
            placeholder="Miller"
            id="lname"
            value={formData.lName}
            onChange={(e) =>
              setFormData({ ...formData, lName: e.target.value })
            }
            className="signUpInput"
          ></input>

          <label htmlFor="email">Email</label>
          <input
            readOnly
            type="email"
            id="email"
            className="signUpInput"
            placeholder="jamesmiller@gmail.com"
            value={JSON.parse(localStorage.getItem("EMAIL"))}
            style={{
              backgroundColor: "#dbdbdb",
            }}
          ></input>

          <label htmlFor="email">Re-enter Email</label>
          <input
            type="email"
            id="email"
            className="signUpInput"
            value={reenterEmail}
            onChange={(e) => {
              setReenterEmail(e.target.value);
              if (
                e.target.value === JSON.parse(localStorage.getItem("EMAIL")) &&
                validateEmail(e.target.value) === true
              ) {
                setemailError(false);
              }
            }}
          ></input>

          <label htmlFor="password">Password</label>
          <input
            required
            autoComplete="disabled"
            type="password"
            id="password"
            className="signUpInput"
            placeholder="Atleast 6 characters"
            value={formData.password}
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
              if (validatePassword(e.target.value) === true) {
                setPasswordError(false);
              }
            }}
          ></input>

          <label htmlFor="password">Re-enterPassword</label>
          <input
            required
            autoComplete="disabled"
            type="password"
            id="password"
            className="signUpInput"
            value={reenterPassword}
            onChange={(e) => {
              setReenterPassword(e.target.value);
              if (
                e.target.value === formData.password
              ) {
                setRePasswordError(false);
              }
            }}
          ></input>

          <div style={{ display: "flex" }}>
            <FormControlLabel
              color="primary"
              control={
                <Checkbox
                  required
                  color="primary"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCheckBoxTick(true);
                    }
                  }}
                />
              }
            />
            <p className={classes.p} style={{ paddingTop: "20px" }}>
              I agree to the{" "}
              <NavLink
                className={classes.p}
                style={{ color: "#187CAE" }}
                to={{ pathname: "/EULA" }}
                target="_blank"
              >
                {" "}
                EULA{" "}
              </NavLink>
              ,{" "}
              <NavLink
                className={classes.p}
                style={{ color: "#187CAE", paddingRight: "3px" }}
                to={{ pathname: "/lawn-care-services-agreement" }}
                target="_blank"
              >
                {" "}
                Lawn Care Service Agreement{" "}
              </NavLink>{" "}
              and
              <NavLink
                className={classes.p}
                style={{ color: "#187CAE", paddingLeft: "5px" }}
                to={{ pathname: "https://stripe.com/legal/connect-account" }}
                target="_blank"
              >
                Stripe Connected Account Agreement{" "}
              </NavLink>{" "}
              (required){" "}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "5%",
            }}
          >
            <Button
              className={classes.buttonPrimary}
              style={{
                fontWeight: "bold",
                marginTop: "1%",
                width: "100%",
                maxWidth: "500px",
                backgroundColor:
                  !formData.password ||
                  !formData.lName ||
                  !formData.fName ||
                  !checkBoxTick ||
                  emailError
                    ? "#D3D3D3"
                    : "#187CAE",
              }}
              onClick={(e) => {
                e.preventDefault();
                if (
                  !formData.password ||
                  !formData.lName ||
                  !formData.fName ||
                  !checkBoxTick
                ) {
                  alert("Fill all the details!");
                  return;
                }
                if (emailError) {
                  alert("Enter the same email");
                }
                if(repasswordError){
                  alert("Enter the same password");
                }
                else if (passwordError) {
                  alert(
                    "Enter the password with Minimum six characters, at least one upper case English letter, one lower case English letter, one number and one special character "
                  );
                } else {
                  nextStep();
                }
              }}
            >
              Next
            </Button>
            <Button
              style={{
                fontWeight: "bold",
                width: "100%",
                maxWidth: "400px",
                marginTop: "5%",
                border: "2px solid #ED9278",
                borderRadius: "28px",
                fontSize: "100%",
                padding: "5px 10px",
                whiteSpace: "nowrap",
                minWidth: "180px!important",
                minHeight: "45px",
              }}
              onClick={(e) => {
                e.preventDefault();
                prevStep();
              }}
            >
              <Typography
                style={{
                  color: "#ED9278",
                  fontSize: "100%",
                  fontWeight: "bold",
                }}
              >
                Previous
              </Typography>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PersonalInfo;
