import React, { useEffect, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  CardActions,
  ListItemText,
  MenuItem,
  MenuList,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { UserContext } from "../../../../components/UserContext";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  YAxis,
  PieChart,
  Pie,
  Cell,
  Label,
  AreaChart,
  Area,
} from "recharts";

//import assets

//import Styling
import {
  Grid,
  Card,
  CardContent,
  Avatar,
  Divider,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  AttachMoney,
  ChevronRight,
  ScheduleOutlined,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { Pending } from "@mui/icons-material";
import { auth, db } from "../../../../components/Firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import moment from "moment";
import FindSchedule from "../../../../components/assets/images/FindSchedule.png";
import AddCustumer from "../../../../components/assets/images/AddCustomer.png";
import CustomService from "../../../../components/assets/images/CustomService.png";
import Hello from "../../../../components/assets/images/hello.png";
import "./Dashboard.css";
import axios from "axios";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
    paddingRight: theme.spacing(10),
    backgroundColor: "white",
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(5),
      padding: theme.spacing(4),
    },
  },
  title: {
    fontWeight: 700,
    color: "#e2f0f3",
  },
  heading: {
    color: "white",
  },
  avatar: {
    backgroundImage: "linear-gradient(225deg, #1d2122, #181c1d)",
    boxShadow: "-5px 5px 15px #000000,10px -10px 40px #FFFFFF",
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  differenceIcon: {
    color: "white",
  },
  differenceValue: {
    color: "white",
    marginRight: theme.spacing(1),
  },
  card: {
    //transform: 'translate(-50%, -50%)',
    backgroundSize: "200%",
    width: "100%",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "-9px 9px 10px #D3D3D3",
    transition: "0.6s",
    backgroundImage: "linear-gradient(45deg, #0079EB 30%, #21CBF3 90%)",
    // backgroundColor: 'black',
    "&:hover": {
      backgroundPosition: "right",
    },
  },

  // background2: {
  //   backgroundColor: "#1E2324",
  //   width: "100%",
  //   paddingBottom: "2%",
  //   paddingTop: "2%",
  // },
  component: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  subcomponent: {
    display: "flex",
    flexDirection: "column",
    marginRight: "10px",
    marginLeft: "10px",
  },
  button2: {
    border: "2px solid #ededed",
    borderRadius: "5px",
    color: "black",
    width: "70%",
    marginTop: theme.spacing(2),
    minHeight: "35px",
    fontSize: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  // All the table styling
  table: {
    width: "90vw",
    height: "100%",
  },
  tableContainer: {
    borderRadius: 15,
    boxShadow: "none",
    margin: "10px 10px",
    height: "100%",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    color: "black",
    borderBottom: "2px solid #187CAE",
    width: 1,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "10px",
    },
  },
  tableHeaderAddressCell: {
    fontWeight: "bold",
    color: "black",
    borderBottom: "2px solid #187CAE",
    width: "100vw",
    [theme.breakpoints.down("sm")]: {
      minWidth: "50vw",
    },
  },
  name: {
    fontWeight: "bold",
    color: "#187CAE",
  },
  status: {
    fontWeight: "bold",
    color: "white",
    backgroundColor: "green",
    borderRadius: "10px",
    padding: "3px 10px",
    display: "grid",
    fontSize: "16px",
    placeItems: "center",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "40vw",
      minWidth: "40%",
      fontSize: "16px",
      minHeight: "100%",
    },
  },
  bigCard: {
    width: "95%",
    display: "flex",
    borderRadius: "10px",
    textAlign: "center",
    backgroundColor: "white",
    paddingTop: "10px",
  },
  grid2: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "15%",
    },
  },
  p: {
    color: "#626687",
    opacity: "0.8",
    fontSize: "18px",
    textAlign: "left",
    lineHeight: "28px",
    // paddingLeft: "-2%",
    maxWidth: "540px",
    [theme.breakpoints.down("sm")]: {
      padding: "2%",
      lineHeight: "24px",
      fontSize: "16px",
      paddingLeft: "5%",
      paddingRight: "5%",
      textAlign: "justify",
    },
  },
  grid3: {
    display: "grid",
    placeItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      placeItems: "center",
    },
  },
  gridCustom: {
    display: "flex",
    width: "82%",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#9AA6B0",
    paddingLeft: "3%",
    margin: "3%",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "15%",
      padding: "3%",
      width: "100%",
    },
  },
}));

export class CustomizedBarLabel extends React.Component {
  render() {
    const { x, y, value } = this.props;
    return (
      <text x={x + 25} y={y} dy={-10} fontSize="14" fill="#666666">
        {value}
      </text>
    );
  }
}

export class CustomizedBarEmptyLabel extends React.Component {
  render() {
    return (
      <text fontSize="44" fill="#666666">
        Sorry
      </text>
    );
  }
}

export class CustomizedRevenueLabel extends React.Component {
  render() {
    const { x, y, value } = this.props;
    return (
      <text
        x={x - 15}
        y={y}
        dy={-10}
        fontSize="14"
        fill="#666666"
        fontWeight={600}
      >
        ${value}
      </text>
    );
  }
}

const CustomizedAxisTick = ({ x, y, payload }) => {
  const dateTip = moment(payload.value).format("ll").slice(0, 6);
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={23}
        y={0}
        dy={14}
        fontSize="0.90em"
        fontFamily="bold"
        textAnchor="end"
        fill="#666666"
      >
        {dateTip.split(",")}
      </text>
    </g>
  );
};

function EslDashboard({
  userDetails,
  dashboardDetails,
  scheduledquotes,
  todayDate,
}) {
  const classes = useStyles();
  const [user, setUser] = useContext(UserContext);
  const [sevenDaySchedule, setSevenDaySchedule] = React.useState([]);
  const [sevenDayEmptySchedule, setSevenDayEmptySchedule] = React.useState([]);
  const [jobcompletedarray, setjobcompletedarray] = React.useState([]);
  const [JobCompletionProgress, setJobCompletionProgress] = React.useState();
  const [JobCompletionEmptyProgress, setJobCompletionEmptyProgress] =
    React.useState([]);
  const [dailyRevenue, setDailyRevenue] = React.useState([]);
  const [dailyEmptyRevenue, setDailyEmptyRevenue] = React.useState([]);
  const revRef = React.useRef(null);
  const executeScroll = () =>
    revRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

  useEffect(() => {
    async function checkUser() {
      const { data } = await axios.post(
        "http://localhost:4008/getAllCustomers"
      );
      console.log(data, "data");
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (userCred) => {
        if (userCred) {
          setUser(userCred);
          return;
        } else {
          setUser(null);
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const COLORS = [
    { start: "#2876bd", end: "#34c3ff" },
    { start: "grey", end: "white" },
  ];

  return (
    <>
      {/* <ProAppBar /> */}
      <Grid
        container
        xs={12}
        sm={12}
        lg={12}
        xl={12}
        style={{
          backgroundColor: "#F2FDFF",
          padding: "2%",
          paddingRight: "0%",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={6}
          style={{ marginLeft: "1%", paddingTop: "5%" }}
        >
          <h2
            className={classes.h1}
            style={{
              textAlign: "left",
              marginBottom: "2%",
              textTransform: "capitalize",
            }}
          >
            Bienvenido, {userDetails["name"]}
          </h2>
          <p
            className={classes.p}
            style={{
              marginTop: "10px",
              color: "#313131",
              textAlign: "left",
            }}
          >
            Aquí puede ver todos los datos, incluido el horario, los ingresos y
            el número total de clientes.
          </p>
        </Grid>
        <Box flexGrow={1} />
        <Grid xs={12} sm={12} md={5} lg={6} xl={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img
              src={Hello}
              width="45%"
              style={{ borderRadius: "18px 0px 0px 0px" }}
            ></img>
          </div>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Grid
          justifyContent="space-between"
          container
          spacing={5}
          style={{ paddingTop: "2%", width: "100%", marginLeft: "2px" }}
          lg={11}
        >
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Trabajos De Servicio
                    </Typography>
                    {dashboardDetails["jobsTotal"] ? (
                      <Typography variant="h4" className={classes.heading}>
                        {dashboardDetails["jobsTotal"]}
                      </Typography>
                    ) : (
                      <Typography variant="h4" className={classes.heading}>
                        0
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Avatar className={classes.avatar}>
                      <ScheduleOutlined className={classes.icon} />
                    </Avatar>
                  </Grid>
                </Grid>
                <div style={{ display: "grid", placeItems: "center" }}>
                  <div className={classes.button2}>
                    <NavLink
                      className="navLink"
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      to="/proschedule"
                    >
                      Check Now
                    </NavLink>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <Card raised="true" className={classes.card}>
              <CardContent>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Venganza
                    </Typography>
                    {dashboardDetails["revenueTotal"] ? (
                      <Typography variant="h4" className={classes.heading}>
                        ${dashboardDetails["revenueTotal"].toFixed(2)}
                      </Typography>
                    ) : (
                      <Typography variant="h4" className={classes.heading}>
                        $0
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Avatar className={classes.avatar}>
                      <AttachMoney className={classes.icon} />
                    </Avatar>
                  </Grid>
                </Grid>
                <div style={{ display: "grid", placeItems: "center" }}>
                  <div className={classes.button2}>
                    <Typography
                      className="navLink"
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={executeScroll}
                    >
                      View Graph
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Clientes
                    </Typography>
                    {dashboardDetails["customersTotal"] ? (
                      <Typography variant="h4" className={classes.heading}>
                        {dashboardDetails["customersTotal"]}
                      </Typography>
                    ) : (
                      <Typography variant="h4" className={classes.heading}>
                        0
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <Avatar className={classes.avatar}>
                      <Pending className={classes.icon} />
                    </Avatar>
                  </Grid>
                </Grid>
                <div style={{ display: "grid", placeItems: "center" }}>
                  <div className={classes.button2}>
                    <NavLink
                      className="navLink"
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      to="/procustomer"
                    >
                      Check Now
                    </NavLink>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <div style={{ display: "grid", placeItems: "center" }}>
            <Divider
              style={{
                backgroundColor: "white",
                marginTop: "2%",
                width: "92vw",
                marginLeft: "3%",
                marginBottom: "1%",
              }}
            />
          </div>
        </Grid>

        <Grid lg={11} container justifyContent="space-between">
          <Grid item xs={12} sm={12} md={4} lg={5} xl={12}>
            <h6
              style={{
                fontWeight: "bolder",
                display: "flex",
                fontSize: "100%",
                paddingTop: "2%",
                paddingBottom: "1%",
              }}
            >
              Número Total De Trabajos En La Semana
            </h6>
            {sevenDaySchedule.length > 0 ? (
              <div className={classes.bigCard}>
                <ResponsiveContainer width="100%" height={271}>
                  <BarChart width={600} height={400} data={sevenDaySchedule}>
                    <XAxis dataKey="name" />
                    <YAxis type="number" hide />
                    <defs>
                      <linearGradient
                        id="TotalJobs"
                        x1="0"
                        y1="1"
                        x2="1"
                        y2="0"
                      >
                        <stop
                          offset="45%"
                          stopColor="#0079EB"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="90%"
                          stopColor=" #21CBF3"
                          stopOpacity={0.6}
                        />
                      </linearGradient>
                    </defs>
                    <Tooltip />
                    <Legend />
                    <Bar
                      radius={4}
                      dataKey="TotalJobs"
                      barSize={60}
                      label={<CustomizedBarLabel />}
                      fill="url(#TotalJobs)"
                      opacity={0.8}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className={classes.bigCard}>
                <ResponsiveContainer width="100%" height={271}>
                  <BarChart
                    width={600}
                    height={400}
                    data={sevenDayEmptySchedule}
                  >
                    <XAxis dataKey="name" />
                    <YAxis type="number" hide />
                    <defs>
                      <linearGradient
                        id="TotalJobs"
                        x1="0"
                        y1="1"
                        x2="1"
                        y2="0"
                      >
                        <stop
                          offset="45%"
                          stopColor="black"
                          stopOpacity={0.8}
                        />
                        <stop offset="90%" stopColor="grey" stopOpacity={0.6} />
                      </linearGradient>
                    </defs>
                    <Bar
                      radius={4}
                      dataKey="TotalJobs"
                      barSize={50}
                      fill="url(#TotalJobs)"
                      opacity={0.3}
                    />
                    <text
                      x="50%"
                      y="20%"
                      style={{ fontSize: 20, fontWeight: "bold", fill: "#777" }}
                      width={200}
                      scaleToFit={true}
                      textAnchor="middle"
                      verticalAnchor="middle"
                    >
                      Datos no disponibles
                    </text>
                    <text
                      x="50%"
                      y="27%"
                      // dy={+12}
                      style={{
                        fontSize: 16,
                        fill: "black",
                      }}
                      width={200}
                      scaleToFit={true}
                      textAnchor="middle"
                      verticalAnchor="middle"
                    >
                      Lo poblaremos si obtenemos los datos.
                    </text>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={12}
            justifyContent="center"
          >
            <h6
              style={{
                fontWeight: "bolder",
                display: "flex",
                fontSize: "100%",
                paddingTop: "2%",
                paddingBottom: "5%",
              }}
            >
              Progreso De Finalización Del Trabajo
            </h6>
            {jobcompletedarray.length > 0 ? (
              <Paper style={{ width: "100%", height: 250 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <defs>
                      {jobcompletedarray.map((entry, index) => (
                        <linearGradient id={`myGradient${index}`}>
                          <stop
                            offset="0%"
                            stopColor={COLORS[index % COLORS.length].start}
                          />
                          <stop
                            offset="100%"
                            stopColor={COLORS[index % COLORS.length].end}
                          />
                        </linearGradient>
                      ))}
                    </defs>
                    <Pie
                      data={jobcompletedarray}
                      // startAngle={200}
                      // endAngle={-20}
                      innerRadius="65%"
                      outerRadius="90%"
                      paddingAngle={0}
                      dataKey="value"
                    >
                      <Label
                        value={JobCompletionProgress + "%"}
                        position="centerBottom"
                        fontSize="20"
                        style={{
                          marginBottom: "5%",
                          fontSize: 20,
                          fontWeight: "bold",
                          fill: "#777",
                        }}
                      />
                      <Label
                        value="completed"
                        position="centerTop"
                        style={{ fontSize: 16, fill: "black" }}
                      />

                      {jobcompletedarray.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={`url(#myGradient${index})`}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Paper>
            ) : (
              <Paper style={{ width: "100%", height: 250 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <defs>
                      {JobCompletionEmptyProgress.map((entry, index) => (
                        <linearGradient id={`myGradient${index}`}>
                          <stop offset="0%" stopColor="black" />
                          <stop offset="100%" stopColor="grey" />
                        </linearGradient>
                      ))}
                    </defs>
                    <Pie
                      data={JobCompletionEmptyProgress}
                      innerRadius="75%"
                      outerRadius="90%"
                      paddingAngle={0}
                      dataKey="value"
                      opacity="0.3"
                    >
                      <Label
                        value="No data available"
                        position="centerBottom"
                        className="label-top"
                        fontSize="20px"
                        style={{
                          marginBottom: "5%",
                          fontWeight: "bold",
                          fill: "#777",
                        }}
                      />
                      {JobCompletionEmptyProgress.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={`url(#myGradient${index})`}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Paper>
            )}
          </Grid>
        </Grid>

        <h6
          ref={revRef}
          style={{
            fontWeight: "bolder",
            display: "flex",
            fontSize: "100%",
            paddingTop: "3%",
            paddingBottom: "2%",
          }}
        >
          Venganza
        </h6>

        {dailyRevenue.length > 0 ? (
          <div>
            <ResponsiveContainer width="100%" height={271}>
              <AreaChart
                data={dailyRevenue}
                margin={{
                  top: 25,
                  right: 30,
                  left: 20,
                  bottom: 10,
                }}
              >
                <defs>
                  <linearGradient id="revenue" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#74BCF4" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#0079EB " stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="date" tick={CustomizedAxisTick} />
                {/* <YAxis /> */}
                <Tooltip />
                <Legend />
                <Area
                  label={<CustomizedRevenueLabel />}
                  type="monotone"
                  dataKey="revenue"
                  fill="url(#revenue)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div>
            <ResponsiveContainer width="100%" height={271}>
              <AreaChart
                data={dailyEmptyRevenue}
                margin={{
                  top: 25,
                  right: 30,
                  left: 20,
                  bottom: 10,
                }}
              >
                <defs>
                  <linearGradient id="revenue" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="black" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="grey" stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="date" tick={CustomizedAxisTick} />
                {/* <YAxis /> */}
                <Tooltip />
                <Legend />
                <text
                  x="50%"
                  y="50%"
                  style={{ fontSize: 20, fontWeight: "bold", fill: "#777" }}
                  width={200}
                  scaleToFit={true}
                  textAnchor="middle"
                  verticalAnchor="middle"
                >
                  No data available
                </text>
                <text
                  x="50%"
                  y="57%"
                  // dy={+12}
                  style={{
                    fontSize: 16,
                    fill: "black",
                  }}
                  width={200}
                  scaleToFit={true}
                  textAnchor="middle"
                  verticalAnchor="middle"
                >
                  We'll populate if we get the data.
                </text>
                <Area
                  label={<CustomizedRevenueLabel />}
                  type="monotone"
                  dataKey="revenue"
                  fill="url(#revenue)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        )}

        <h6
          style={{
            fontWeight: "bolder",
            display: "flex",
            fontSize: "100%",
            paddingTop: "3%",
            paddingBottom: "2%",
          }}
        >
          {/* All Service Offerings */}
        </h6>

        {/* Find Schedules */}
        <Grid className={classes.grid2} lg={11} xs={12} sm={12} xl={12}>
          <Grid xs={12} sm={12} lg={6} xl={7} sx={{ display: { sm: "none" } }}>
            <img src={FindSchedule} className="findSchedule" width="60%" />
          </Grid>
          <Grid className={classes.grid3} xs={12} sm={12} lg={5} xl={5}>
            <h1 className={classes.h1} style={{ textAlign: "left" }}>
              Encuentre Más Sobre Los Horarios
            </h1>
            <img
              src={FindSchedule}
              className="mobilewhoarewe"
              style={{ padding: "2%", marginTop: "3%" }}
              width="50%"
            />
            <p
              className={classes.p}
              style={{
                textAlign: "justify",
                maxWidth: "400px",
                marginTop: "3%",
              }}
            >
              Obtén toda la información sobre el horario. Obtiene la ubicación
              del cliente, el servicio y la cantidad que se paga.
            </p>
            <NavLink
              style={{
                textDecoration: "none",
                color: "white",
                fontWeight: "bold",
                marginTop: "10px",
              }}
              to="/proschedule"
            >
              <Button
                color="primary"
                type="button"
                style={{ marginTop: "5%", borderRadius: "32px" }}
                variant="contained"
              >
                View Schedule
              </Button>
            </NavLink>
          </Grid>
        </Grid>
      </div>
      <div className={classes.root}></div>
    </>
  );
}

export default EslDashboard;
