import {
  AppBar,
  Box,
  FormControl,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import React from "react";
import { NavLink } from "react-router-dom";
import { stack as Menu } from "react-burger-menu";
import { UserContext } from "../../../components/UserContext";
import EngDashboard from "./Components/EngDashboard";
import EslDashboard from "./Components/SpanishProUser";
import { auth, db } from "../../../components/Firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "@firebase/firestore";
import { ArrowDropDown, Language } from "@material-ui/icons";
import dayjs from "dayjs";

function Dashboard() {
  const [language, setLanguage] = React.useState(UserContext);
  const [checked, setChecked] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const [dashboardDetails, setDashboardDetails] = React.useState({});
  const [scheduledquotes, setscheduledquotes] = React.useState([]);
  const todayDate = new Date().toISOString().slice(0, 10);
  const [user, setUser] = React.useContext(UserContext);
  const [uuid, setuuid] = React.useState();
  const history = useHistory();
  const theme = useTheme();

  // MUI Responsiveness:
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    // Select a language
    setLanguage(localStorage.getItem("language"));

    function checkUser() {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (userCred) => {
        if (userCred) {
          setUser(userCred);
          setuuid(userCred.uid);

          const currentDate = dayjs();
          const currentYear = currentDate.year().toString();
          const currentMonth = currentDate.format("MMMM").toString();
          const startOfMonth = currentDate.startOf('month');
          const currentWeekOfMonth = Math.ceil((currentDate.diff(startOfMonth, 'day') + startOfMonth.day()) / 7);

          const serDocString = 'Services' + currentYear
          const weekString = 'Week ' + '- ' + currentWeekOfMonth

          //code to display the real Pro name from the acct read from FB
          const userInfo = await getDoc(doc(db, "ProCompany", userCred.uid));

          const servInfo = await getDoc(doc(db, "ProCompany", userCred.uid, serDocString, currentMonth));

          const customerDoc = await getDocs(collection(db, "ProCompany", userCred.uid, "CustomerList"));
          var totalCustomers = 0;
          const documents = customerDoc.docs.map(doc => totalCustomers += 1);

          //FirstName and LastName are inside ProInfo in db
          setUserDetails({
            name:
              userInfo.data().OwnerInfo.FirstName +
              " " +
              userInfo.data().OwnerInfo.LastName,
          });

          // Check if dashboard summary is present
          {
            userInfo.data().DashboardSummary
              ? setDashboardDetails({
                  jobsTotal: userInfo.data().DashboardSummary.JobsTotal,
                  revenueTotal: userInfo.data().DashboardSummary.RevenueTotal,
                  customersTotal:
                    userInfo.data().DashboardSummary.CustomersTotal,
                })
              : console.log("No Dashboard Data");
          }

          const totalPrice = servInfo.data()[weekString].reduce((sum, obj) => sum + obj.ProPrice, 0);

          // Check current Day Schedule

          servInfo.data()[weekString]
            ? setDashboardDetails({
              jobsTotal: servInfo.data()[weekString].length,
              revenueTotal: totalPrice,
              customersTotal:
                totalCustomers,
            })
            : console.log("No Dashboard Data");

          return;
        } else {
          setUser(null);
          history.push("/login");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Renders the particular on the current page.
  const PageDisplay = () => {
    if (language === "English") {
      localStorage.setItem("language", language);
      return (
        <EngDashboard
          userDetails={userDetails}
          dashboardDetails={dashboardDetails}
          scheduledquotes={scheduledquotes}
          todayDate={todayDate}
        />
      );
    } else if (language === "Español") {
      localStorage.setItem("language", language);
      return (
        <EslDashboard
          userDetails={userDetails}
          dashboardDetails={dashboardDetails}
          scheduledquotes={scheduledquotes}
        />
      );
    }
  };

  const handleChange = (event) => {
    if (event.target.value === "English") {
      setLanguage("English");
    }
    if (event.target.value === "Español") {
      setLanguage("Español");
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        style={{ backgroundColor: "white", width: "100%"}}
      >
        <Toolbar>
          <NavLink
            to="/"
            style={{ textDecoration: "none", backgroundColor: "transparent" }}
          >
            <img
              src={
                require("../../../components/assets/images/Logo_SVG_black.svg")
                  .default
              }
              className="logo"
              height="45vh"
              alt="Alltramatic logo"
            />
          </NavLink>

          <h3
            style={{
              paddingLeft: matchesSm ? "5%" : "30px",
              color: "black",
              width: matchesSm ? "" : "200px",
            }}
          >
            Dashboard
          </h3>

          <Box flexGrow={1} justifyContent="center" />

          <div
            // className="NavLinks"
            style={{ display: "flex", alignItems: "center" }}
          >
            {user ? (
              <>
                {/* Language Selector */}
                <Tooltip title="Choose a Language" placement="left-start">
                  <FormControl
                    style={{
                      minWidth: matchesSm ? "80px" : "60px",
                      // width: "100%",
                      marginLeft: matchesSm ? "-35%" : "0%",
                    }}
                  >
                    <Select
                      value={language}
                      style={{ fontWeight: "bold" }}
                      className="appbarNavlink"
                      onChange={handleChange}
                      variant="standard"
                      disableUnderline
                    >
                      <MenuItem value="English">🇬🇧 En</MenuItem>
                      <MenuItem value="Español">🇪🇸 Es</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
                <NavLink to="/prodashboard" style={{ textDecoration: "none" }}>
                  <div className="appbarNavlink">Dashboard</div>
                </NavLink>
                <NavLink to="/proschedule" style={{ textDecoration: "none" }}>
                  <div className="appbarNavlink">Schedule</div>
                </NavLink>
                <NavLink to="/profile" style={{ textDecoration: "none" }}>
                  <div className="appbarNavlink">Profile</div>
                </NavLink>
                <NavLink to="/" style={{ textDecoration: "none" }}>
                  <div
                    className="appbarNavlink"
                    style={{ marginRight: "0%", paddingRight: "0%" }}
                    onClick={() => {
                      signOut(auth)
                        .then(() => {
                          // Sign-out successful.
                          console.log("signed out");
                          setUser(null);
                          localStorage.removeItem("user");
                        })
                        .catch((error) => {
                          // An error happened.
                          console.log(error);
                        });
                    }}
                  >
                    Logout
                  </div>
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  className="appbarNavlink"
                  style={{ textDecoration: "none" }}
                  to="/login"
                >
                  Login
                </NavLink>
              </>
            )}
          </div>
        </Toolbar>

        {matchesSm && (
          <div>
            <Menu right>
              {user ? (
                <>
                  <div className="bm-item" style={{ display: "grid" }}>
                    <NavLink
                      to="/"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        textDecoration: "none",
                      }}
                    >
                      Home
                    </NavLink>
                  </div>
                  <div className="bm-item" style={{ display: "grid" }}>
                    <NavLink
                      to="/prodashboard"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        textDecoration: "none",
                      }}
                    >
                      Dashboard
                    </NavLink>
                  </div>
                  <div className="bm-item" style={{ display: "grid" }}>
                    <NavLink
                      to="/proschedule"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        textDecoration: "none",
                      }}
                    >
                      Schedule
                    </NavLink>
                  </div>

                  <div className="bm-item" style={{ display: "grid" }}>
                    <NavLink
                      to="/profile"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        textDecoration: "none",
                      }}
                    >
                      Profile
                    </NavLink>
                  </div>
                  <NavLink
                    to="/"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    <div
                      onClick={() => {
                        signOut(auth)
                          .then(() => {
                            // Sign-out successful.
                            console.log("signed out");
                            setUser(null);
                            localStorage.removeItem("user");
                          })
                          .catch((error) => {
                            // An error happened.
                            console.log(error);
                          });
                      }}
                    >
                      Log out
                    </div>
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink
                    style={{ backgroundColor: "transparent", color: "black" }}
                    to="/"
                  >
                    Home
                  </NavLink>
                  <NavLink
                    style={{ backgroundColor: "transparent", color: "black" }}
                    to="/login"
                  >
                    Login
                  </NavLink>
                </>
              )}
            </Menu>
          </div>
        )}
      </AppBar>
      {PageDisplay()}
    </>
  );
}

export default Dashboard;
