import styled from "styled-components";
import newBG from "../../components/assets/images/NewHomeBG.jpg";
import lawnmower from "../../components/assets/images/Home-Automation.png";
import lawnmowerMobile from "../../components/assets/images/ProLawnMowMobile.jpg";

export const WrapRobo = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${newBG});
`;
export const LawnMowerBG = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${lawnmower});
  @media (max-width: 768px) {
    margin-top: -2%;
    background-image: url(${lawnmowerMobile});
  }
`;

export const ItemText = styled.div`
  padding-top: 50vh;
  text-align: left;
  display: flex;
  padding-left: 2vh;
  flex-direction: column;

  h1 {
    letter-spacing: 0.5px;
    padding: 0px;
    font-size: 7vh;
    color: white;
  }

  h2 {
    margin-top: 20vh;
    padding: 10px;
    font-size: 50px;
    color: white;
  }
  p {
    color: #75787c;
    font-size: 2.5vh;
    word-spacing: 2px;
    letter-spacing: 2px;
    font-weight: 500;
    padding-right: 20px;
    padding-bottom: 10px;
    // color: #b6c4d9;
    color: white;
    padding-left: 10px;
  }
  @media (max-width: 768px) {
    margin-top: 15vh;
    font-size: 12px;
    padding-right: 20px;
    text-align: center;
    justify-content: center;
    h1 {
      margin-left: 10px;
      margin-right: 10px;
      padding: 10px;
      font-size: 30px;
    }

    h2 {
      margin-top: 10vh;
    }
    p {
      text-align: center;
      font-size: 1.5vh;
    }
  }
`;

export const Search = styled.div`
  background-color: #26658c;
  height: 50px;
  width: 200px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  opacity: 1;
  font-size: 16px;
  cursor: pointer;
  margin: 50px;

  a {
    text-decoration: none;
    color: white;
  }

  @media (max-width: 768px) {
    margin: 8px;
    width: 200px;
    height: 50px;
  }
`;

export const Container1 = styled.div`
  max-height: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px;

  @media (max-width: 868px) {
    padding: 0 5px;
    padding-top: 5vh;
    margin-top: 0px;
  }
  @media (max-width: 300px) {
    padding: 0 5px;
    padding-top: 0vh;
    margin-top: 5%;
  }
  /* ipad Mini Portrait */
  @media only screen and (width:768px) and (resolution: 163dpi) {
    padding: 0 5px;
       padding-top: 0vh;
       margin-top: 0px;
  
  }
  
  /* ipad Mini Landscape */
  @media only screen and (width:1024px) and (resolution: 163dpi) {
    padding: 0 5px;
       padding-top: 0vh;
       margin-top: 0px;
`;

export const TextContainer = styled.div`
  padding-left: 2%;
  margin-top: 60%;
  text-align: center;
  width: 90vw;
  display: grid;
  place-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.3);
`;

//white transparent background
export const TextContainer2 = styled.div`
  margin-top: 45%;
  padding-left: 30%;
  text-align: center;
  width: 100vw;
  display: grid;
  place-items: center;
  // background: rgba(0, 0, 0, 0);
  @media (max-width: 1080px) {
    margin-top: 50vh;
    padding-left: 0%;
    margin-left: 0%;
  }
`;

export const TextContainer3 = styled.div`
  text-align: center;
  margin-left: 60vh;
  height: 70vh;
  width: 45vw;
  display: grid;
  place-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  @media (max-width: 1080px) {
    margin-left: 0vh;
    width: 75vw;
  }
`;

export const TextContainer4 = styled.div`
  text-align: center;
  margin-left: 4vh;
  width: 45vw;
  margin-top: 50%;
  padding: 3%;
  display: grid;
  place-items: center;
  background-color: #d9d9d9;
  border-radius: 10px;
  @media (max-width: 768px) {
    width: 70vw;
    margin-top: 50vh;
  }
`;

export const ItemText2 = styled.div`
  h1 {
    // letter-spacing: 0.5px;
    padding: 0px;
    font-size: 6vh;
    color: white;
    margin-top: 1%;
    font-family: Poppins;
    
  }

  h2 {
    margin-top: 20vh;
    padding: 10px;
    font-size: 50px;
    color: white;
  }
  h3 {
    font-size: 30px;
    font-family: Trebuchet MS, sans-serif;
    color: white;
  }
  p2{
      font-size:30px;
      font-family:Trebuchet MS, sans-serif;
      color: white;
    }
  }
  p {
    color: #75787c;
    font-size: 2.5vh;
    //word-spacing: 2px;
    letter-spacing: 2px;
    font-weight: 500;
    padding-right: 20px;
    padding-bottom: 0px;
    color: #b6c4d9;
    // color: white;
    padding-left: 10px;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    
    padding-right: 20px;
    text-align: center;
    justify-content: center;
    h1 {
      margin-left: 0px;
      margin-right: 10px;
      padding: 10px;
      font-size: 30px;
      color: white;
    }

    h2 {
      margin-top: 10vh;
    }
    p {
      padding-bottom: 4%;
      font-size: 2vh;
    }
  }
`;
