import { makeStyles } from "@material-ui/core/styles";
import { darken } from "@material-ui/core";
// import "@fontsource/poppins";
// import "@fontsource/lexend";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 4),
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    paddingTop: "10px",
    paddingBottom: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: "white",
    fontFamily: "poppins",
  },

  paper: {
    padding: theme.spacing * 2,
    textAlign: "center",
    backgroundColor: "#D9D9D9",
    bordercolor: "none",
    minHeight: "170px",
  },

  stepper: {
    width: "90%",
    margin: "2%",
    // background: "rgba(35, 61, 83, 0.9)",
    background: "transparent",
    [theme.breakpoints.down("sm")]: {
      margin: "1%",
      width: "90vw",
    },
  },

  proSignupstepper: {
    width: "90%",
    margin: "2%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  title: {
    padding: theme.spacing(0),
    color: "white",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },

  subHeading: {
    padding: theme.spacing(0),
    color: "black",
    marginTop: "-12%",
    // marginLeft:"-15%",
    fontSize: "24px",
    paddingLeft: "10%",
    fontWeight: "700",
    lineheight: "45px",
    alignItems: "center",
    textAlign: "left",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {},
  },
  stepperheading: {
    color: "white",
    paddingTop: "2%",
    fontWeight: "400",
    fontSize: "20px",
  },

  heading: {
    color: "rgb(62, 106, 225)",
    fontWeight: "bold",
    fontSize: "40px",
  },

  steppercomp: {
    color: "white",
    opacity: "0.5",
    fontSize: "22px",
    fontWeight: "600",
  },
  // oo:{
  // MuiSvgIcon-root:{
  // //   // color:"red"
  // color:"white"
  //  }

  // },

  component: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    paddingTop: "10px",
    paddingBottom: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      paddingBottom: "5px",
      paddingLeft: "5px",
      paddingRight: "5px",
      paddingTop: "10px",
    },
  },
  compo1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingTop: "10px",
    paddingBottom: "20px",
    // paddingLeft: "10px",
    // paddingRight: "10px",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      paddingBottom: "5px",
      paddingLeft: "5px",
      paddingRight: "5px",
      paddingTop: "10px",
    },
  },
  compo2: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    paddingTop: "10px",
    paddingBottom: "20px",
    // paddingLeft: "10px",
    paddingRight: "17px",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      paddingBottom: "5px",
      paddingLeft: "5px",
      paddingRight: "5px",
      paddingTop: "10px",
    },
  },

  compo: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    paddingTop: "10px",
    paddingBottom: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      paddingBottom: "5px",
      paddingLeft: "5px",
      paddingRight: "5px",
      paddingTop: "10px",
    },
  },

  image: {
    width: "100%",
    maxWidth: "850px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    height: "auto",
  },
  grid: {
    marginTop: "30px",
    marginLeft: "1%",
    marginBottom: "2%",
    width: "100%",
    // backgroundColor: "grey",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0%",
    },
  },
  root2: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    flexDirection: "column",
    backgroundColor: "white",
    alignItems: "center",
  },
  profileFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "80%",
    paddingRight: "3%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "small",
      // alignItems: "flex-start",
      alignItems: "center",
      width: "80vw",
      justifyContent: "space-between",
    },
  },

  grid: {
    marginTop: "30px",
    marginLeft: "1%",
    marginBottom: "2%",
    width: "100%",
    // backgroundColor: "grey",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0%",
    },
  },

  profileFlex2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "small",
      // alignItems: "flex-start",
      alignItems: "center",
      width: "80vw",
      justifyContent: "space-between",
    },
  },

  buttonResponsive: {
    backgroundColor: "#187cae",
    border: "none",
    borderRadius: "28px",
    margin: "0px",
    padding: "5px 10px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    width: "100%",
    minWidth: "175px",
    height: "100px",
    textAlign: "center",
    alignItems: "center",
    maxHeight: "50px",
    marginBottom: "5%",
    marginLeft: "10%",
    color: "white",
    fontFamily: "Poppins",
    fontSize: "20px",
    pointer: "cursor",
  },

  buttonPrimary: {
    backgroundColor: "#187CAE",
    fontSize: "30px",
    border: "none",
    borderRadius: "28px",
    margin: "0px",
    padding: "5px 10px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    minWidth: "190px!important",
    height: "100px",
    textAlign: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      minHeight: "45px",
    },
  },

  buttonPrimarySmall: {
    backgroundColor: "#187CAE",
    fontSize: "100%",
    border: "none",
    borderRadius: "28px",
    margin: "0px",
    padding: "5px 10px",
    fontWeight: "600",
    whiteSpace: "nowrap",
    minWidth: "120px",
    color: "white",
    height: "40px",
    fontFamily: "Poppins",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      minHeight: "45px",
      color: "white!important",
    },
  },

  buttonPrimary2: {
    border: "4px solid #187CAE",
    fontSize: "100%",
    color: "black",
    borderRadius: "28px",
    marginLeft: "2%",
    padding: "5px 10px",
    fontWeight: "600",
    whiteSpace: "nowrap",
    minWidth: "120px!important",
    height: "40px",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      minHeight: "45px",
      maxHeight: "30px",
    },
  },

  h2: {
    position: "relative",
    // right:"54%",
    marginBottom: "7%",
    fontstyle: "normal",
    fontSize: "32px",
    color: "#000000",
    fontWeight: "500",
    fontFamily: "Poppins",
    // marginTop:"-100px",
    // marginRight: "1200px",
    // textAlign:"left",
    // top:"108vh",
    // left:"88vh",
    flexDirection: "row",
    // paddingBottom:"15%",
    // marginLeft:"-180px",
    // marginRight:"90%",
    // marginBottom:"10px"
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
      // minHeight: "45px",
      width: "100%",
      marginTop: "10vh",
      // marginLeft:"70%",
      fontWeight: "500",
      fontFamily: "Poppins",
      // gridArea:"3 / 3 / 4 / 4"
    },
  },
  p: {
    fontSize: "2vh",
    color: "#9E9E9E",
  },
  p1: {
    fontSize: "2vh",
    color: "#9E9E9E",
    paddingTop: "10px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
      // minHeight: "45px",
      width: "90%",
      color: "red",
      marginTop: "10vh",
      // marginLeft:"5%",
      fontWeight: "500",
      fontFamily: "Poppins",
    },
  },
  p2: {
    fontSize: "2vh",
    color: "#9E9E9E",
    paddingTop: "10px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
      // minHeight: "45px",
      width: "90%",
      color: "red",
      marginTop: "10vh",
      // marginLeft:"5%",
      fontWeight: "500",
      fontFamily: "Poppins",
    },
  },
  dono: {
    position: "absolute",
    marginTop: "500px",
  },
  loginForm: {
    display: "flex",
    textAlign: "left",
    flexDirection: "column",
    width: "300px",
    justifyContent: "center",
    marginTop: "2%",
  },
  loginFormSignup: {
    display: "flex",
    textAlign: "left",
    flexDirection: "column",
    width: "750px",
    justifyContent: "center",
    marginTop: "2%",
  },
  select: {
    backgroundColor: "white",
    border: "2px solid #EFF2F7",
    maxHeight: "45px",
    marginBottom: "10px",
  },
  toggleButton: {
    backgroundColor: "#d3d3d3!important",
    fontWeight: "bold!important",
    marginRight: "5vw !important",
    borderRadius: "5px!important",
    border: "none!important",
    width: "100%!important",
    color: "white!important",
    "&.Mui-selected": {
      backgroundColor: "#3457b1!important",
      color: "white",
      fontWeight: 600,
    },
  },
  quotesCard: {
    backgroundColor: "#EDEEEF",
    padding: "2%",
    width: "100%",
    borderRadius: "8px",
    marginBottom: "2%",
  },

  whoWeAreDescription: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  featureDescription: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  masterFlexWithSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "small",
    },
  },

  subFlexWithSpaceBetween: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    paddingRight: "10%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "small",
      alignItems: "center",
      width: "118vw",
      justifyContent: "center",
      paddingLeft: "13% !important",
    },
    // [theme.breakpoints.down("md")]: {
    //   flexDirection: "column",
    //   fontSize: "small",
    //   alignItems: "center",
    //   width: "500vw",
    //   justifyContent: "center",
    //   paddingLeft: "13% !important",
    // },
  },

  subFlexWithnoSpaceBetween: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "small",
      alignItems: "center",
      width: "80vw",
      justifyContent: "space-between",
    },
  },
  rightGrid: {
    paddingTop: "1vh",
    backgroundColor: "#233D53",
    fontSize: "30%",
    zIndex: "1",
    padding: "2%",
    height: "100vh",
    width: "50%",
    position: "sticky",

    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      height: "100%",
      width: "100%",
      minWidth: "100vw",
      marginLeft: "0%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
      width: "100%",
      minWidth: "100vw",
    },
  },
  landhead1: {
    [theme.breakpoints.down("sm")]: {},
  },
  secondaryButton: {
    fontSize: "100%",
    border: "4px solid #187CAE",
    borderRadius: "28px",
    margin: "0px",
    padding: "5px 10px",
    fontWeight: "600",
    width: "30%",
    whiteSpace: "nowrap",
    minWidth: "150px!important",
    [theme.breakpoints.down("sm")]: {
      width: "78%!important",
      minWidth: "280px",
      marginLeft: "40%",
    },
  },

  appStoreIcon: {
    width: "25%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },

  dashboardGrid: {
    marginLeft: "200px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5%",
    },
  },

  dashboardGrid2: {
    marginLeft: "200px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5%",
    },
  },

  cradStuff: {
    width: "100%",
    height: "20%",
    border: "none !important",
    /* background: linear-gradient(to right top, #280537, #56034c, #890058, #bc005b, #eb1254) !important; */
    background: "linear-gradient(200deg, #1382be, #0c548f) !important",
    marginRight: "0px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px",
    },
  },
  dialogButton: {
    fontFamily: "Lexend",
    borderRadius: "32px",
    textTransform: "capitalize",
    fontWeight: "bold",
    padding: "1vh",
    color: "white",
    border: "2px solid white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "10px",
      maxHeight: "40px",
      lineHeight: "15px",
    },
  },
  dialogButton2: {
    fontFamily: "Lexend",
    borderRadius: "32px",
    textTransform: "capitalize",
    fontWeight: "bold",
    padding: "1vh",
    color: "white",
    border: "2px solid white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "10px",
      maxHeight: "40px",
      lineHeight: "15px",
    },
  },
  card: {
    // background:"linear-gradient(225deg, #1d2122, #181c1d)",
    background: "linear-gradient(45deg, #0079EB 30%, #21CBF3 90%)",
    // minWidth:"200px"
    width: "570px",
    [theme.breakpoints.down("sm")]: {
      width: "350px",
    },
  },
  tippingChip: {
    "&.MuiChip-root": {
      color: "white",
      fontFamily: "Poppins",
      marginLeft: "5px",
      width: "10vw",
      [theme.breakpoints.down("sm")]: {
        width: "65px",
        marginBottom: "15px",
        marginLeft: "0px",
      },
    },
  },
  chipList: {
    "&.MuiListItem-root": {
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        justifyContent: "space-between",
      },
    },
  },
  otherTippingTextBox: {
    color: "#ffffff",
    "&.MuiTextField-root": {
      marginLeft: "5px",
      marginRight: "5px",
      width: "125px",
      color: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
        color: "white",
      },
    },
  },
  feedbackTextbox: {
    "&.MuiTextField-root": {
      marginTop: "11",
      marginLeft: "100px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
        marginLeft: "45px",
      },
    },
  },
  cancelButton2: {
    marginTop: "3%",
    marginLeft: "8%",
    textDecoration: "underline",
    // "&.MuiButton-root": {
    //   marginTop: "3%",
    //   marginLeft: "8%",
    //   textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "45px",
      marginTop: "10px",
      color: "grey",
    },
  },
  dialogButton3: {
    fontFamily: "Lexend",
    // borderRadius: "32px",
    textTransform: "capitalize",
    fontWeight: "bold",
    padding: "1vh",
    marginLeft: "3%",
    color: "white",
    border: "2px solid white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "10px",
      maxHeight: "40px",
      lineHeight: "15px",
      marginLeft: "45px",
    },
  },
  line: {
    border: "5px solid #187CAE",
    width: "10%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
    [theme.breakpoints.between("lg", "md")]: {
      width: "6%",
    },
  },
}));

export default useStyles;
