require('dotenv').config();

/**
 * @file config.js
 * @author PRAMODH UGARGOL
 * @date MAY 11, 2023
 * @version 1.0
 * 
 * This file contains the configuration settings for the application for qa.
 */

/**
 * @constant config
 * @type {Object}
 * 
 * This object contains various properties such as the URL for the API, 
 * lookup data, assigning jobs, changing jobs, registering users, managing saved cards,
 * admin login, getting snow prices, and processing new user accounts. 
 * */

const config = {
	JOB_COMPLETE_PART1: process.env.REACT_APP_JOBCOMPONE,
	JOB_COMPLETE_PART2: process.env.REACT_APP_JOBCOMPTWO,
	MOWING_SCHEDULE: process.env.REACT_APP_MOWSCHEDULE,
	GET_ALL_CUSTOMERS: process.env.REACT_APP_GETCUSTOMERS,
	PROCESS_JOB_COMPLETE: process.env.REACT_APP_PROCESSJOBCOMP,
	CUSTOMER_DETAILS: process.env.REACT_APP_CUSTDETAILS,
	SET_DAY_DATE: process.env.REACT_APP_SETDAYDATE,
	ACCT_STATUS_UPDATE: process.env.REACT_APP_ACCTUPDATE,
	ASSIGN_PRO: process.env.REACT_APP_ASSIGNPRO,
	GET_PRO_USERS: process.env.REACT_APP_GETPROUSERS,
	PRO_SIGNUP: process.env.REACT_APP_PROSIGNUP,
	CURRENT_PRO_SCHEDULE: process.env.REACT_APP_CURRENT_PRO_SCHEDULE,
	PRO_CUSTOMERLIST: process.env.REACT_APP_CURRENT_PRO_CUSTOMERLIST,
	PRO_JOBCOMPLETE: process.env.REACT_APP_CURRENT_PRO_JOBCOMPLETE,
	PRO_SCHEDULE_API: process.env.REACT_APP_PRO_SCHEDULE_API,
	PRO_UPDATE_SERVICE: process.env.REACT_APP_PRO_UPDATE_SERVICE,
	UPDATE_SCHEDULE_LIST: process.env.REACT_APP_UPDATE_SCHEDULE_LIST,
	BLOG_CREATE: process.env.REACT_APP_BLOG_CREATE,
	BLOG_CREATE_QA: process.env.REACT_APP_BLOG_CREATE_QA
};

/**
 * @exports config
 */
export default config;