// LawnCareAgreement.js    Created 9/4/2022    Rev 4.8

// Purpose:
// This is a subcomponent for Prosignup page.
//  This code provides UI for Lawncare agreement.

import React from "react";
import Navbar from "../../../components/Navbar";
import useStyles from "../../New Home/Styles";

function LawnCareAgreement() {
  const classes = useStyles();
  return (
    <>
      <Navbar />
      <div className={classes.root2}>
        <h2 className={classes.h2} style={{ marginTop: "2vh" }}>
          Lawn Care Services Agreement
        </h2>
        <p
          className={classes.p}
          style={{ textAlign: "justify", width: "97%", marginTop: "20px" }}
        >
          This Lawn Care Services Agreement (“Agreement”) by and between you,
          the party clicking through to accept this Agreement, (“You”) and
          Lawnber, Inc. (“Lawnber”) constitutes a binding agreement
          among the Parties. Upon the date of Your acceptance of this Agreement
          (“Effective Date”), You agree to be bound by the terms and conditions
          of this Agreement. You and Lawnber may each be referred to as a
          “Party” or collectively, the “Parties.”
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          If You are entering into this Agreement on behalf of a company,
          organization or another legal entity (an “Entity”), You are agreeing
          to this Agreement for that Entity and representing to Lawnber that
          You have the authority to bind that Entity and its affiliates to this
          Agreement, in which case the term “You” or related capitalized terms
          herein will refer to such Entity and its affiliates. If You do not
          have such authority, or if You do not agree with this Agreement, You
          must not agree to be bound by this Agreement and may not perform
          Services (defined below) hereunder.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          1. Scope: Pursuant to Lawnber’s Terms of Service agreed to by
          Lawnber’s customers (each a “Customer”), Customer is subscribing
          to the Lawnber service for the provision of lawn and yard care
          services (“Services”). You agree to provide the Services to Customer
          pursuant to the terms and conditions of this Agreement and in
          accordance with the Customer’s order (“Order”). In order to provide
          the Services hereunder, You are required to use the online Lawnber
          platform (“Platform”), which is subject to separate terms and
          conditions.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          2.Customers: As between the Parties, Lawnber will retain ownership
          of the Customers. You acknowledge and agree that You will not contact
          or provide Services to Customers other than as permitted hereunder.
          The foregoing will not apply to any of Your customers to whom you
          provided services prior to the Effective Date or that You otherwise
          acquire outside of this Agreement (“Your Clients”).
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          3.Performance of Services: Each Order will include reasonable details,
          at a minimum, about the Services, Fees charged, general description of
          Services required, and the Customer contact information (name,
          physical address, phone number). You and Lawnber agree to
          cooperate in good faith to achieve satisfactory completion of the
          Services in a timely and professional manner.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          Once You agree to perform Services pursuant to an Order, You may not
          modify the Fees (defined below), unless agreed in writing by
          Lawnber. The Parties will each designate a representative to
          interface and facilitate the successful completion of the Services.
          You are not permitted to provide Services through a subcontractor,
          unless agreed in writing by Lawnber.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          Lawnber agrees to provide, at no cost to You, timely and adequate
          assistance and other resources reasonably requested by You to enable
          Your performance of the Services. In performing the Services, You will
          provide the resources, materials, tools, equipment, and utilize
          qualified personnel as it deems necessary to perform the Services or
          any portion thereof. Customer may object to Your personnel by
          specifying its objection to You or Lawnber, in which case the
          Parties will cooperate in good faith to assign new personnel or
          transfer the Customer to another service provider to perform the
          Services. You may replace personnel in the normal course of business,
          provided that You will be responsible for the performance of Services
          by all Personnel.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          If Lawnber reasonably determines that a Customer may be better
          served by a different service provider, Lawnber may transfer the
          Customer to a different service provider without liability, upon
          notice to You. You will control the method and manner of performing
          all work necessary for completion of Services, including but not
          limited to the supervision and control of any personnel performing
          Services.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          4. Fees; Payment Terms; Damage fee: Lawnber will pay You the fees
          to provide the Services as set forth in an Order (“Fees”). Fees will
          be payable in accordance with the Order, except for Fees that Customer
          disputes in good faith. In addition to the Fees, You will be
          responsible for travel expenses, payment of Your personnel, resources,
          materials, tools, equipment, and communications used in connection
          with provision of the Services (“Expenses”). You will be responsible
          for any and all taxes related to the Services, including taxes related
          to your personnel, other than taxes on Consultant’s income.
          Lawnber may charge administrative or service fees, or pass through
          service provider fees, such as payment processor fees (“Service
          Fees”). Service Fees may be charged directly to You or withheld from
          Fees, at Lawnber’s sole discretion. You shall have adequate
          insurance to cover Your performance of the Services and if You do not,
          You will pay the Damage Fee assessed to You by Lawnber to cover
          Your performance.You will name Lawnber as an additional insured on
          Your insurance policy which shall be primary and contain a waiver of
          subrogation against Lawnber and its insurers.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          5. Relationship of the Parties and Background Checks: You are an
          independent contractor and will maintain complete control of and
          responsibility for your personnel, methods, and operations. At no time
          will You hold yourself out as an agent, subsidiary or affiliate of
          Lawnber for any purpose, including reporting to any government
          authority. This Agreement will not be construed so as to create a
          partnership, other joint venture or undertaking, or any agency
          relationship between the Parties, and neither Party shall become
          liable for any representation, act or omission of the other Party or
          have the authority to contractually bind the other Party. Any Fees,
          Expenses, or other amounts paid by Lawnber to You will not be
          considered salary for pension or wage tax purposes and neither You nor
          Your personnel will be entitled to any fringe benefits, including sick
          or vacation pay, or other supplemental benefits of Lawnber. Unless
          otherwise required by law, Lawnber will not be responsible for
          deducting or withholding from Fees or Expenses any taxes,
          unemployment, social security or other expense. You hereby acknowledge
          and agree that Lawnber may conduct credit and background checks on
          You and Your personnel, as reasonably required to determine Your
          suitability to perform Services. You expressly consent to be contacted
          by Lawnber for any and all purposes arising out of or relating to
          this Agreement and the Services hereunder, at any telephone number, or
          physical or electronic address you provide or at which you may be
          reached. You agree Lawnber may contact you in any way, including
          SMS messages (including text messages), calls using prerecorded
          messages or artificial voice, and calls and messages delivered using
          auto telephone dialing system or an automatic texting system. You
          consent to receive SMS messages (including text messages), calls and
          messages (including prerecorded and artificial voice and autodialed)
          from Lawnber at the specific number(s) You have provided to
          Lawnber, or numbers Lawnber can reasonably associate with your
          account (through skip trace, caller ID capture or other means), with
          information or questions about this Agreement and the Services. You
          certify, warrant and represent that the telephone numbers that You
          have provided to Lawnber are your contact numbers. You represent
          that You are permitted to receive calls at each of the telephone
          numbers You have provided to Lawnber. You agree to promptly alert
          Lawnber whenever you stop using a particular telephone number. You
          acknowledge that Your cellular or mobile telephone provider may charge
          you according to the type of plan you carry. You agree that in the
          even of any dispute with a Customer, Lawnber has your consent to
          give the Customer the information about you in this Section 5.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          6. Term and Termination. This Agreement will commence on the Effective
          Date and will remain effective until terminated in accordance with the
          terms of this Section (the “Term”). This Agreement may be terminated
          by either Party with or without cause upon written notice to the other
          Party. Upon termination of this Agreement, You will immediately cease
          performing any Services, and Lawnber will pay You any Fees not yet
          paid for Services provided on or prior to termination. The following
          Sections will survive any termination of the Agreement: 2, 4, 6.4, and
          7-10. Termination of this Agreement will be without prejudice to other
          rights or remedies of any Party under this Agreement or applicable
          law, including, without limitation, any remedies for a breach of this
          Agreement prior to such termination.
        </p>

        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          7. Warranties, Limitation of Liability.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          7.1. You hereby represents and warrants that: You have all authority,
          licenses, permits, and consents necessary to enter into and perform
          its obligations under this Agreement, and will fully comply with all
          applicable laws and regulations in performing the Services; Your
          personnel are, and will have the ability to prove on demand that they
          are, legally entitled to work in the United States; the Services will
          be performed in a timely, professional, and workman-like manner by You
          and Your personnel, consistent with generally-accepted industry
          standards; and You are under no contractual or other restrictions or
          obligations which are inconsistent with the execution of this
          Agreement, or, to its best knowledge, which will interfere with its
          performance of the Consulting Services.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          7.2. EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, Lawnber
          EXPRESSLY DISCLAIMS TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW
          ALL OTHER REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OR CONDITIONS OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY,
          NON-SATISFACTORY QUALITY, NON-INFRINGEMENT OF THIRD PARTY RIGHTS AND
          TITLE, OR ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE,
          AND ALL SUCH REPRESENTATIONS AND WARRANTIES ARE HEREBY EXCLUDED TO THE
          FULLEST EXTENT ALLOWED BY APPLICABLE LAW. THESE DISCLAIMERS AND
          EXCLUSIONS WILL APPLY EVEN IF THE EXPRESS WARRANTY AND LIMITED REMEDY
          SET FORTH ABOVE FAILS OF ITS ESSENTIAL PURPOSE.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          7.3. UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN
          CONTRACT, TORT, NEGLIGENCE OR OTHERWISE) WILL Lawnber, OR ITS
          AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS OR
          LICENSORS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT,
          INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL, PUNITIVE OR OTHER
          SIMILAR DAMAGES, INCLUDING LOST PROFITS, LOST SALES OR BUSINESS,
          BUSINESS INTERRUPTION OR ANY OTHER LOSS INCURRED BY YOU OR A THIRD
          PARTY IN CONNECTION WITH THIS AGREEMENT OR THE SERVICES, REGARDLESS OF
          WHETHER THAT PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF OR COULD
          HAVE FORESEEN SUCH DAMAGES.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          7.4. Lawnber’S AGGREGATE LIABILITY ARISING OUT OF THIS AGREEMENT
          OR OTHERWISE IN CONNECTION WITH ANY SERVICES, WILL IN NO EVENT EXCEED
          THE FEES PAID BY CUSTOMER PRIOR TO THE FIRST EVENT OR OCCURRENCE
          GIVING RISE TO SUCH LIABILITY. EACH PARTY ACKNOWLEDGES AND AGREES THAT
          THE ESSENTIAL PURPOSE OF THIS SECTION IS TO ALLOCATE THE RISKS UNDER
          THIS AGREEMENT BETWEEN THE PARTIES AND LIMIT POTENTIAL LIABILITY GIVEN
          THE FEES, WHICH WOULD HAVE BEEN SUBSTANTIALLY HIGHER IF YOU WERE TO
          ASSUME ANY FURTHER LIABILITY OTHER THAN AS SET FORTH HEREIN.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          7.5. Some jurisdictions do not allow the exclusion of implied
          warranties or limitation of liability for incidental or consequential
          damages, which means that some of the above limitations may not apply.
          IN THESE JURISDICTIONS, EACH PARTY’S LIABILITY WILL BE LIMITED TO THE
          GREATEST EXTENT PERMITTED BY LAW.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          8. Indemnification: You will indemnify and hold Lawnber harmless,
          from and against any claim against Lawnber by a third party,
          including a Customer, related to this Agreement or Your provision of
          Services (each a “Claim”). You will, at Your expense, defend a Claim
          and pay damages finally awarded against Lawnber in connection
          therewith, including the reasonable fees and expenses of the attorneys
          engaged by Lawnber for the defense, provided that (a) Lawnber
          promptly notifies You of the threat or notice of a Claim, and (b)
          Lawnber will have the sole and exclusive control and authority to
          select defense attorneys, defend or settle any Claim. You will fully
          cooperate with Lawnber in connection with any Claim.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          9. Confidentiality: By virtue of this Agreement, the Parties may have
          access to information that is confidential to one another
          (“Confidential Information”). We each agree to disclose only
          information that is required for the performance of obligations under
          the agreement. Confidential Information will be limited to the terms
          of this Agreement, your data residing in the Platform environment,
          Customer data, and all information clearly identified as confidential
          at the time of disclosure. A Party’s Confidential Information will not
          include information that: (a) is or becomes a part of the public
          domain through no act or omission of the other Party; (b) was in the
          other Party’s lawful possession prior to the disclosure and had not
          been obtained by the other Party either directly or indirectly from
          the disclosing Party; (c) is lawfully disclosed to the other Party by
          a third party without restriction on the disclosure; or (d) is
          independently developed by the other Party without use of or reference
          to the other Party’s Confidential Information. The Parties each agree
          to hold each other’s Confidential Information in confidence for a
          period of three years from the date of disclosure. Also, each Party
          agrees to disclose Confidential Information only to those employees or
          agents who are required to protect it against unauthorized disclosure
          in a manner no less protective than under this Agreement. Lawnber
          will protect the confidentiality of Your data residing in the Platform
          environment in accordance with the Lawnber security practices
          specified in the Lawnber Privacy Policy, which is incorporated
          herein by reference. Nothing will prevent either Party from disclosing
          the other Party’s Confidential Information in connection with any
          legal proceeding arising from or in connection with this Agreement or
          from disclosing the Confidential Information to a governmental entity
          as required by law. You agree that Lawnber will communicate with
          you by email, telephone, push notification, and SMS or text message.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          10. General
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          10.1 Non-Solicitation: During the Term and for a period of one year
          thereafter, each Party agrees that it will not: (a) directly or
          indirectly induce any Customer or Your Client, as applicable, to
          patronize that Party or any similar business; (b) directly or
          indirectly request or advise any Customer or Your Client to withdraw,
          curtail, or cancel their business with the other Party; or (c) make
          any statement disparaging the other Party, any member, principal,
          officer, director, shareholder, employee or agent thereof, to any
          person, firm, corporation or other business organization whatsoever.
          Nothing herein will prohibit either Party from performing Services to
          Customers or Your Clients who (x) respond to general advertising by a
          Party, (y) voluntarily withdraw or cancel their business with a Party,
          or (z) contact a Party directly.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          10.2 No Waiver: Neither Party waives any right under this Agreement by
          failing to insist on compliance with any of the terms of this
          Agreement or by failing to exercise any right hereunder. Any waivers
          granted hereunder are effective only if recorded in a writing signed
          by the Party granting such waiver.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          10.3 Feedback: In the event You provide suggestions, comments, or
          other feedback (“Feedback”) to Lawnber with respect to the
          Services or the Platform, You hereby assign to Lawnber all right,
          title, and interest in and to the Feedback and Lawnber will be
          free to reproduce, make, use, create derivative works of, display,
          import, transmit, distribute, license, sell, offer to sell, or
          otherwise dispose of Feedback (and derivative works thereof) without
          obligation of any kind to You.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          10.4 Assignment and Change of Control: You may not assign any part or
          all of this Agreement without Lawnber’s prior written consent.
          Lawnber may assign this Agreement at any time without notice or
          consent. Any attempt to assign in violation of this Section is void in
          each instance.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          10.5 Governing Law, Venue, and Jurisdiction: This Agreement is
          governed by Texas law, excluding its conflicts of law rules. Developer
          irrevocably submits to venue and exclusive personal jurisdiction in
          the federal and state courts in Travis County, Texas, for any dispute
          arising out of this Agreement, and waives all objections to
          jurisdiction and venue of such courts.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          10.6 Dispute Resolution. Any controversy or claim arising out of this
          Agreement, including any controversy or claim as to requirement of
          arbitration, shall be settled by binding arbitration in accordance
          with the rules of the American Arbitration Association (“AAA”). The
          arbitration shall be conducted in Austin, Texas. There shall be one
          arbitrator to be mutually selected by the Parties. If the Parties are
          unable to agree upon an arbitrator within 30 days of the demand for
          arbitration having been filed, an arbitrator shall be appointed by
          AAA. Judgment on the arbitration award may be entered in any court
          having jurisdiction thereof. In any such arbitration proceeding, the
          Parties shall have the right to conduct all discovery allowed under
          the Texas Code of Civil Procedure. The arbitrator in any such
          arbitration proceeding shall make a determination as to the prevailing
          party and award such prevailing party its reasonable attorneys’ fees,
          expenses, and other litigation costs incurred in bringing or defending
          such arbitration. In the event the dispute to be arbitrated is for
          disputed Fees, the arbitrator shall take into account the amount in
          dispute in determining how much discovery to allow and how extensive
          the briefing and hearings may be. In no event shall the awardable
          attorneys’ fees and costs incurred in such dispute exceed more than
          fifty percent (50%) of the amount in dispute. YOU ACKNOWLEDGE AND
          AGREE THAT YOU AND Lawnber ARE EACH WAIVING THE RIGHT TO A TRIAL
          BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS IN ANY PURPORTED
          CLASS ACTION OR REPRESENTATIVE PROCEEDING. FURTHER, UNLESS BOTH YOU
          AND Lawnber OTHERWISE AGREE IN WRITING, THE ARBITRATOR MAY NOT
          CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, AND MAY NOT OTHERWISE
          PRESIDE OVER ANY FORM OF ANY CLASS OR REPRESENTATIVE PROCEEDING. IF
          THIS SPECIFIC PARAGRAPH IS HELD UNENFORCEABLE, THEN THE ENTIRETY OF
          THIS “DISPUTE RESOLUTION” SECTION WILL BE DEEMED VOID. EXCEPT AS
          PROVIDED IN THE PRECEDING SENTENCE, THIS “DISPUTE RESOLUTION” SECTION
          WILL SURVIVE ANY TERMINATION OF THIS AGREEMENT.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          10.7 Severability: If any provision of this Agreement is determined by
          any court or governmental authority to be unenforceable, the Parties
          intend that this Agreement be enforced as if the unenforceable
          provisions were not present and that any partially valid and
          enforceable provisions be enforced to the extent that they are
          enforceable.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          10.8 Notice: Except as expressly provided herein, all notices,
          requests, demands, and other communications under this Agreement will
          be delivered in writing and will be deemed to have been duly given:
          (i) on the next day if delivered personally to the Party, (ii) on the
          date three days after mailing if mailed by registered or certified
          mail, or (iii) on the next day if delivered by courier. The Parties
          will send all notices to addresses listed on the Platform or at
          another address as may be provided by a party from time to time.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          10.9 Entire Agreement: This Agreement constitutes the complete and
          final agreement of the Parties pertaining to the subject matter herein
          and supersedes the parties’ prior agreements, understandings and
          discussions relating to subject matter herein. No modification of this
          Agreement is binding unless it is in writing and signed by both
          Parties.
        </p>
        <h2 className={classes.h2} style={{ marginTop: "2vh", width: "96%" }}>
          AGREEMENT BETWEEN GENERAL CONTRACTOR AND SUBCONTRACTOR TO ESTABLISH
          INDEPENDENT RELATIONSHIP
        </h2>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          The undersigned General Contractor and the undersigned Subcontractor
          hereby declare that:
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          A. the Subcontractor meets the qualifications of an Independent
          Contractor under Texas Workers’ Compensation Act, Texas Labor Code,
          Section 406.121;
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          B. the Subcontractor is operating as an independent contractor as that
          term is defined under Section 406.121 of the Act;
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          C. the Subcontractor assumes the responsibilities of an employer for
          the performance of work;
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          D. the Subcontractor and Subcontractor’s employees are not employees
          of the General Contractor for purposes of the Act.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%", paddingBottom: "2%" }}>
          Texas Workers’ Compensation Act, Texas Labor Code, Section 406.121(2)
          defines “independent contractor” as follows: (1) “Independent
          contractor” means a person who contracts to perform work or provide a
          service for the benefit of another and who ordinarily: (A) acts as the
          employer of any employee of the contractor by paying wages, directing
          activities, and performing other similar functions characteristic of
          an employer-employee relationship; (B) is free to determine the manner
          in which the work or service is performed, including the hours of
          labor of or method of payment to any employee; (C) is required to
          furnish or have his employees, if any, furnish necessary tools,
          supplies, or materials to perform the work or service; and (D)
          possesses the skills required for the specific work or service
        </p>
      </div>
    </>
  );
}

export default LawnCareAgreement;
