import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import {
	Typography,
	Grid,
	Button,
} from '@material-ui/core';
import CustomAppBar from './AppBar';

// Application Configuration
import config from "../Config";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
	},

	gridContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: {
		marginRight: theme.spacing(1),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		fontFamily: 'Poppins'
	},
	list: {
		width: 250,
	},
	input: {
		fontWeight: 'bold',
		borderRadius: '10px',
		fontFamily: 'Poppins'
	},
	clickButton: {
		fontWeight: "bold",
		borderRadius: "5px",
		border: "none",
		cursor: "pointer",
		minHeight: "20px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		paddingLeft: "15px",
		paddingRight: "15px",
		textAlign: "center",
		textTransform: "none",
		marginLeft: "0.5rem",
		backgroundColor: "#187CAE",
		color: "white",
		fontSize: "1.3vh",
		fontFamily: "Poppins",
	},
	tableHead: {
		fontFamily: 'Poppins',
		fontWeight: 'bold',
		fontSize: '16px',
	},
	tableContent: {
		fontFamily: 'Poppins',
		fontSize: '14px',
	},
}));

const JobComplete = () => {

	const classes = useStyles();
	const [ShowJobComplete, setShowJobComplete] = useState(null);
	const [updateJobCompleteList, setUpdateJobCompleteList] = useState(null);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [isQAToggled, setQAToggled] = useState(
		localStorage.getItem("isQAToggled") === "true" || false
	);

	React.useEffect(async () => {
		localStorage.setItem("isQAToggled", isQAToggled);
		const response = await axios.post(
			config.JOB_COMPLETE_PART2,
			{},
			{
				headers: {
					"Content-Type": "application/json",
					"X-Mode": isQAToggled ? 'QA' : 'PROD', // Set the custom header "X-Mode" with the mode value
				},
			}
		);
		setShowJobComplete([])
		setShowJobComplete(response.data)
		// setShowJobComplete((prevList) => [...prevList, response.data]);
	}, [updateJobCompleteList, isQAToggled]);


	const handleJobCompleteFinal = async (ServiceID) => {
		setIsButtonDisabled(true);
		const response = await axios.post(
			config.PROCESS_JOB_COMPLETE,
			{
				serviceID: ServiceID
			},
			{
				headers: {
					"Content-Type": "application/json",
					"X-Mode": isQAToggled ? 'QA' : 'PROD', // Set the custom header "X-Mode" with the mode value
				}
			},
		)
		if (response.data['status'] === 'Payment Complete') {
			setUpdateJobCompleteList(Math.random().toString(36).slice(2))
			alert('Payment Successful')
		}
		else {
			alert('Payment Failed')
			setIsButtonDisabled(false);
		}
	}


	const handleToggleQA = (value) => {
		setQAToggled(value);
	};

	return (
		<>
			<div className={classes.root}>
				<CustomAppBar isQAToggled={isQAToggled} onToggleQA={handleToggleQA} />
				<Grid container spacing={5} style={{ marginTop: '2%', margin: 'auto' }} className={classes.gridContainer} >
					<>
						<div className={classes.root}>

							<h1 style={{ marginBottom: '2%' }} > {'Lawn Mowing Job Complete List'}</h1>
							{ShowJobComplete && ShowJobComplete.length > 0 ?
								<Table >
									<TableHead>
										<TableRow >
											<TableCell className={classes.tableHead}>Name</TableCell>
											<TableCell className={classes.tableHead}>UID</TableCell>
											<TableCell className={classes.tableHead}>Service ID</TableCell>
											<TableCell className={classes.tableHead}>Subscription</TableCell>
											<TableCell className={classes.tableHead}>Plan</TableCell>
											<TableCell className={classes.tableHead}>Price</TableCell>
											<TableCell className={classes.tableHead}>Verify</TableCell>
										</TableRow>
									</TableHead>
									<TableBody >
										{ShowJobComplete && ShowJobComplete.length > 0 && ShowJobComplete.map((customer) => (
											<TableRow key={customer.UID} style={{ backgroundColor: customer.JobStatus.includes('Job') ? 'white' : 'grey' }}>
												<TableCell className={classes.tableContent}>{customer.UserName}</TableCell>
												<TableCell className={classes.tableContent}>{customer.UID}</TableCell>
												<TableCell className={classes.tableContent}>{customer.ServiceID}</TableCell>
												<TableCell className={classes.tableContent}>{customer.SubscriptionType}</TableCell>
												<TableCell
													className={classes.tableContent}
												>
													{customer.Plan}
												</TableCell>
												<TableCell
													className={classes.tableContent}
												>
													{customer.Price}
												</TableCell>
												<TableCell
													className={classes.tableContent}
													contentEditable={true}
													onBlur={(event) => { customer.VerifyPrice = event.target.innerText; }}
												>
													{customer.VerifyPrice}
												</TableCell>
												<TableCell>
													{customer.JobStatus.includes('Job') && <Button disabled={isButtonDisabled} className={classes.clickButton} variant="contained" color="primary"
														onClick={() => {
															if (customer.VerifyPrice === customer.Price.toString()) {
																handleJobCompleteFinal(customer.ServiceID)
															}
															else {
																alert('Enter Proper Price')
																return;
															}
														}} >
														Charge
													</Button>}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table> :
								<h2 style={{ marginTop: '10%' }} > {'No Jobs'}</h2>}
						</div>
					</>
				</Grid>

			</div>
		</>
	)
}
export default JobComplete;
