import React from "react";
import {
  AppBar,
  Box,
  Button,
  Paper,
  Toolbar,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Divider,
} from "@material-ui/core";
import { stack as Menu } from "react-burger-menu";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { UserContext } from "../../../components/UserContext";
import { NavLink, useHistory } from "react-router-dom";
import { authQA, dbQA } from "../../../components/Firebase";
import useStyles from "../../New Home/Styles";
import { PersonAddAlt1 } from "@mui/icons-material";
import { PersonAdd } from "@material-ui/icons";
import { Alert } from "@mui/material";
import QANavbar from "../../../components/QANavbar";
import { collection, getDocs } from "firebase/firestore";
import axios from "axios";
import config from "../Config";

function Customers() {
  const [userQA, setUserQA] = React.useContext(UserContext);
  const [customerData, setCustomerData] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    function checkUser() {
      localStorage.setItem("userQA", JSON.stringify(userQA));
      onAuthStateChanged(authQA, async (userCred) => {
        if (userCred) {
          setUserQA(userCred);

          const data = await axios
            .post(
              config.PRO_CUSTOMERLIST,
              {
                UID: userCred.uid,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "X-Mode": "QA", // Set the custom header "X-Mode" with the mode value
                },
              }
            )
            .then((response) =>
              setCustomerData((prevState) => [...prevState, response.data])
            );

          console.log("data", data);

          return;
        } else {
          setUserQA(null);
          history.push("/QAadminLogin");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  console.log("customerData", customerData);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <QANavbar pageTitle="Customers" />

      <div style={{ marginLeft: "2%", marginTop: "2%" }}>
        <h6
          style={{
            fontWeight: "bolder",
            display: "flex",
            fontSize: "100%",
            paddingTop: "6vw",
            paddingBottom: "1%",
          }}
        >
          Customer's List
        </h6>

        {customerData[0]?.length > 1 ? (
          customerData[0].map((payload, index) => (
            <Card
              elevation={2}
              key={payload.index}
              style={{
                backgroundColor: "white",
                width: "98.5%",
                marginBottom: "10px",
                padding: "5px",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {payload.SpringCleanup ? (
                    <>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          color: customerData[0][index].New ? "red" : "black",
                        }}
                      >
                     {index+1}.   {payload.SpringCleanup?.ServiceAddr.split(", USA")}
                      </Typography>
                      <Typography style={{ display: "flex" }}>
                        {payload.SpringCleanup?.ServiceCat}[
                        {payload.SpringCleanup?.SubscriptionType}]{" "}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          color: customerData[0][index].New ? "red" : "black",
                        }}
                      >
                       {index+1}. {payload.LawnMowing?.ServiceAddr.split(", USA")}
                      </Typography>
                      <Typography style={{ display: "flex" }}>
                        {payload.LawnMowing?.ServiceCat}[
                        {payload.LawnMowing?.SubscriptionType}]{" "}
                      </Typography>
                    </>
                  )}
                </Box>
              </div>
              <Typography style={{ display: "flex" }}>
                Mow Weekly - Fertilizer + Spot Seeding
              </Typography>
            </Card>
          ))
        ) : (
          <Card
            elevation={2}
            style={{
              backgroundColor: "white",
              width: "98.5%",
              marginBottom: "10px",
              padding: "5px",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ fontWeight: "bold", display: "flex" }}>
                  No customers were found.
                </Typography>
              </Box>
            </div>
          </Card>
        )}

        {/* <Dialog
          style={{ padding: "2%", margin: "0px" }}
          open={open}
          onClose={handleClose}
          fullWidth
        >
          <div className={classes.card}>
            <Alert
              //   severity="success"
              icon={<PersonAddAlt1 />}
              onClose={handleClose}
              sx={{
                "& .MuiAlert-icon": {
                  color: "white",
                  fontSize: "30px",
                  backgroundImage: "linear-gradient(225deg, #1d2122, #181c1d)",
                  boxShadow: "-5px 5px 15px #000000,10px -10px 40px #FFFFFF",
                  padding: "2%",
                  borderRadius: "50%",
                },
                backgroundColor: "transparent",
              }}
            >
              <h2 style={{ color: "white" }}>Add new Customer</h2>
            </Alert>
            <DialogContent style={{ color: "white" }}>
              <form
                className={classes.loginForm}
                style={{ 
                    // maxWidth: "400px", 
                    marginLeft: "0px",
                backgroundColor: "transparent" }}
              >
                <label
                  htmlFor="companyName"
                  style={{
                    marginBottom: "5px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Name
                </label>
                <input
                  required
                  type="text"
                  placeholder="Alltramatic"
                  id="cname"
                  style={{
                    marginBottom: "20px",
                    color: "white",
                    backgroundColor: "white",
                  }}
                  className="signUpInput"
                  //   onChange={(e) =>
                  //     setFormData({ ...formData, companyName: e.target.value })
                  //   }
                ></input>

                <label
                  htmlFor="badress"
                  style={{
                    marginBottom: "5px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Address
                </label>
                <input
                  required
                  autoComplete="disabled"
                  type="text"
                  placeholder="USA"
                  id="businessAddress"
                  style={{
                    marginBottom: "20px",
                    color: "white",
                    backgroundColor: "white",
                  }}
                  //   value={formData.businessAdress}
                  //   onChange={(e) => {
                  //     setFormData({
                  //       ...formData,
                  //       businessAdress: e.target.value,
                  //     });
                  //   }}
                  className="signUpInput"
                ></input>

                <label
                  htmlFor="badress"
                  style={{
                    marginBottom: "5px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Email
                </label>
                <input
                  required
                  autoComplete="disabled"
                  type="text"
                  placeholder="xyz@abc.com"
                  id="businessAddress"
                  style={{
                    marginBottom: "20px",
                    color: "white",
                    backgroundColor: "white",
                  }}
                  //   value={formData.businessAdress}
                  //   onChange={(e) => {
                  //     setFormData({
                  //       ...formData,
                  //       businessAdress: e.target.value,
                  //     });
                  //   }}
                  className="signUpInput"
                ></input>

                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "5vw",
                    }}
                  >
                    <label
                      htmlFor="badress"
                      style={{
                        marginBottom: "5px",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Phone
                    </label>
                    <input
                      required
                      type="tel"
                      placeholder="100-000-000"
                      style={{
                        marginBottom: "20px",
                        color: "white",
                        backgroundColor: "white",
                      }}
                      id="phoneno"
                      //   value={formData.phone}
                      //   onChange={(e) => {
                      //     setFormData({ ...formData, phone: e.target.value });
                      //   }}
                      className="signUpInput"
                    ></input>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label
                      htmlFor="badress"
                      style={{
                        marginBottom: "5px",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Zipcode
                    </label>
                    <input
                      type="tel"
                      placeholder="11791"
                      id="zipcode"
                      //   value={JSON.parse(localStorage.getItem("ZIPCODE"))}
                      style={{
                        marginBottom: "20px",
                        color: "white",
                        backgroundColor: "white",
                      }}
                      className="signUpInput"
                    ></input>
                  </div>
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                variant="outlined"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  padding: "0%",
                  border: "2px solid white",
                }}
                onClick={handleClose}
                autoFocus
              >
                Okay
              </Button>
            </DialogActions>
          </div>
        </Dialog> */}
      </div>
    </>
  );
}

export default Customers;
