import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Typography, Grid, Button } from "@material-ui/core";
import CustomAppBar from "./AppBar";

// Application Configuration
import config from "../Config";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  gridContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: "Poppins",
  },
  list: {
    width: 250,
  },
  input: {
    fontWeight: "bold",
    borderRadius: "10px",
    fontFamily: "Poppins",
  },
  clickButton: {
    fontWeight: "bold",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    minHeight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "3px",
    paddingRight: "3px",
    paddingTop: "1%",
    paddingBottom: "1%",
    textAlign: "center",
    textTransform: "none",
    marginLeft: "0.5rem",
    backgroundColor: "#187CAE",
    color: "white",
    fontSize: "12px",
    fontFamily: "Poppins",
    margin: 0,
  },
  tableHead: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "16px",
  },
  tableContent: {
    fontFamily: "Poppins",
    fontSize: "14px",
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: "1.5%",
    marginBottom: "1.5%",
  },
}));

const AdminPage = ({ refresh }) => {
  const classes = useStyles();
  const history = useHistory();
  const [allCustomers, setAllCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isQAToggled, setQAToggled] = useState(
    localStorage.getItem("isQAToggled") === "true" || false
  );

  const services = [
    "LawnMowing",
    "SpringCleanup",
    "FallCleanup",
    "SnowCleanup",
    "Mulch",
    "HedgeTrimming",
    "AnnualCombo",
  ];

  React.useEffect(async () => {
    // Check if the admin user is logged in
    const admin = JSON.parse(window.localStorage.getItem("isAdmin"));
    localStorage.setItem("isQAToggled", isQAToggled);

    if (admin === false) {
      history.push("/");
    }
    fetchData();
  }, [isQAToggled]); // eslint-disable-line react-hooks/exhaustive-deps

  console.log("data", allCustomers);

  async function fetchData() {
    const { data } = await axios.post(
      config.GET_ALL_CUSTOMERS,
      // "http://localhost:4008/getAllCustomers",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
        },
      }
    );
    setAllCustomers(data);
  }

  const handleToggleQA = (value) => {
    setQAToggled(value);
  };

  return (
    <>
      <div className={classes.root}>
        <CustomAppBar isQAToggled={isQAToggled} onToggleQA={handleToggleQA} />

        <Grid
          container
          spacing={5}
          style={{ marginTop: "2%", margin: "auto" }}
          className={classes.gridContainer}
        >
          {/* Customers */}
          <>
            <div className={classes.root}>
              <h1 style={{ marginBottom: "0%" }}>
                {" "}
                Customers{" "}
                <span>{`(${
                  allCustomers[0] ? allCustomers[0].length : "0"
                })`}</span>
              </h1>
              <h3>
                [
                <span style={{ marginBottom: "1%", color: "green" }}>
                  Active
                  {`(${allCustomers[1] ? allCustomers[1] : "0"})`}
                </span>
                ] [
                <span style={{ color: "red", fontWeight: "600" }}>
                  Cancelled
                  {`(${allCustomers[3] ? allCustomers[3] : "0"})`}
                </span>
                ] [
                <span style={{ color: "black", fontWeight: "500" }}>
                  Un-Committed
                  {`(${allCustomers[4] ? allCustomers[4] : "0"})`}
                </span>
                ] [
                <span style={{ color: "red", fontWeight: "500" }}>
                  Issues
                  {`(${allCustomers[2] ? allCustomers[2] : "0"})`}
                </span>
                ][
                <span style={{ color: "red", fontWeight: "500" }}>
                  Paused
                  {`(${allCustomers[6] ? allCustomers[6] : "0"})`}
                </span>
                ]
              </h3>

              <input
                type="text"
                placeholder="Search User"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  padding: "8px",
                  marginBottom: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1.2rem",
                  fontFamily: "Poppins",
                  width: "600px",
                  fontWeight: "bold",
                  color: "black",
                  marginTop: "1%",
                }}
              />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.tableHead}
                      style={{ maxWidth: "25px" }}
                    >
                      #
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      Reg. Date
                    </TableCell>
                    <TableCell className={classes.tableHead}>Name</TableCell>
                    <TableCell className={classes.tableHead}>
                      Services
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allCustomers[0] &&
                    allCustomers[0]
                      .filter((customer) => {
                        const username = customer.UserName.toLowerCase();
                        const address = customer.Address.toLowerCase();
                        return (
                          username.includes(searchQuery.toLowerCase()) ||
                          address.includes(searchQuery.toLowerCase())
                        );
                      })
                      .map((customer, index) => (
                        <TableRow key={customer.UID}>
                          <TableCell className={classes.tableContent}>
                            {index + 1}{" "}
                          </TableCell>
                          <TableCell className={classes.tableContent}>
                            {customer.RegistrationDate}
                          </TableCell>
                          <TableCell className={classes.tableContent}>
                            <>
                              <span style={{ fontWeight: "bold" }}>
                                {customer.UserName}
                              </span>{" "}
                              <br /> {customer.Address}{" "}
                            </>
                          </TableCell>
                          <TableCell
                            className={classes.tableContent}
                            style={{
                              color: services.some((service) =>
                                customer.Services.includes(service)
                              )
                                ? "black"
                                : "red",
                              fontWeight: services.some((service) =>
                                customer.Services.includes(service)
                              )
                                ? null
                                : "bold",
                            }}
                          >
                            {customer.Services?.join(", ")}
                          </TableCell>
                          <TableCell>
                            {!customer.Services[0].includes(
                              "Something went wrong"
                            ) &&
                              !customer.Services[0].includes("SnowCleanup") && (
                                <Button
                                  className={classes.clickButton}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    const newState = {
                                      Services: customer.Services,
                                      Name: customer.UserName,
                                      Address: customer.Address,
                                    };

                                    const url = `${
                                      window.location.origin
                                    }/customer/${
                                      customer.UID
                                    }?state=${encodeURIComponent(
                                      JSON.stringify(newState)
                                    )}`;

                                    window.open(url, "_blank");
                                  }}
                                >
                                  More
                                  {customer.NotificationCount > 0 && (
                                    <Avatar
                                      size="small"
                                      style={{
                                        position: "absolute",
                                        width: "12px",
                                        height: "12px",
                                        fontSize: "10px",
                                        marginLeft: "3vw",
                                        marginTop: "-2vh",
                                        backgroundColor: "red",
                                        color: "white",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {customer.NotificationCount}
                                    </Avatar>
                                  )}
                                </Button>
                              )}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </div>
          </>
        </Grid>
      </div>
    </>
  );
};

export default AdminPage;
