import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import { Typography, Grid, Button } from "@material-ui/core";
import CustomAppBar from "./AppBar";

// Application Configuration
import config from "../Config";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  gridContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: "Poppins",
  },
  list: {
    width: 250,
  },
  input: {
    fontWeight: "bold",
    borderRadius: "10px",
    fontFamily: "Poppins",
  },
  clickButton: {
    fontWeight: "bold",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    minHeight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "3px",
    paddingRight: "3px",
    paddingTop: "1%",
    paddingBottom: "1%",
    textAlign: "center",
    textTransform: "none",
    marginLeft: "0.5rem",
    backgroundColor: "#187CAE",
    color: "white",
    fontSize: "12px",
    fontFamily: "Poppins",
    margin: 0,
  },
  tableHead: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "16px",
  },
  tableContent: {
    fontFamily: "Poppins",
    fontSize: "14px",
    paddingTop: "0.6%",
    paddingBottom: "0.6%",
  },
}));

const LawnSchedule = () => {
  const classes = useStyles();
  const [segMowServices, setSegMowServices] = useState({});
  const [updateServList, setUpdateServList] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [isQAToggled, setQAToggled] = useState(
    localStorage.getItem("isQAToggled") === "true" || false
  );
  const [weekRanges, setWeekRanges] = useState("");
  const [serviceSelected, setServiceSelected] = useState("Lawn Mowing");

  // A list of days of the week
  const daysOfWeek = [
    "D0_SUN",
    "D1_MON",
    "D2_TUE",
    "D3_WED",
    "D4_THU",
    "D5_FRI",
    "D6_SAT",
  ];

  React.useEffect(async () => {
    localStorage.setItem("isQAToggled", isQAToggled);
    const { data } = await axios.post(
      config.MOWING_SCHEDULE,
      // "http://localhost:4008/mowingSchedule",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
        },
      }
    );
    // console.log(data)
    setSegMowServices(data);
    const currentDate = dayjs();
    // Calculate the week start and end dates
    const currentWeekStart = currentDate.startOf("week");
    const currentWeekEnd = currentDate.endOf("week");

    // Convert dates to ISO Standard
    const currentWeekStartISO = currentWeekStart.format("M-DD-YY");
    const currentWeekEndISO = currentWeekEnd.format("M-DD-YY");
    const dates = `${currentWeekStartISO} to ${currentWeekEndISO}`;
    setWeekRanges(dates);
  }, [updateServList, isQAToggled]);

  const handleToggleQA = (value) => {
    setQAToggled(value);
  };

  const getNextServiceDate = (serviceDate, subscriptionType) => {
    const nextServiceDate = new Date(serviceDate);
    if (subscriptionType === "Weekly") {
      nextServiceDate.setDate(serviceDate.getDate() + 7);
    } else if (subscriptionType === "Bi-Weekly") {
      nextServiceDate.setDate(serviceDate.getDate() + 14);
    }
    return nextServiceDate;
  };

  const handleDateChange = async (event, reqObj) => {
    if (event.key === "Enter") {
      console.log(reqObj);
      event.preventDefault();
      const options = { month: "2-digit", day: "2-digit", year: "numeric" };

      try {
        // Get the current service date and calculate the next service date
        const currentServiceDate = new Date(
          reqObj.ThisWeekServiceDate === "No Service" ||
          reqObj.ThisWeekServiceDate === "Completed"
            ? reqObj.NextServiceDate
            : reqObj.ThisWeekServiceDate
        );

        var nextServiceDate;

        if (reqObj.SubscriptionType.includes("Combo1")) {
          alert("clicked");
          nextServiceDate = new Date(
            getNextServiceDate(currentServiceDate, "Weekly")
          );
        } else if (reqObj.SubscriptionType.includes("Combo2")) {
          nextServiceDate = new Date(
            getNextServiceDate(currentServiceDate, "Bi-Weekly")
          );
        } else if (reqObj.SubscriptionType.includes("Combo3")) {
          nextServiceDate = new Date(
            getNextServiceDate(currentServiceDate, "Weekly")
          );
        } else {
          nextServiceDate = new Date(
            getNextServiceDate(currentServiceDate, reqObj.SubscriptionType)
          );
        }

        console.log(currentServiceDate, nextServiceDate);
        // Format the service dates and update the request object
        reqObj.ThisWeekServiceDate = currentServiceDate
          .toLocaleDateString("en-US", options)
          .replace(/\//g, "-");
        reqObj.NextServiceDate = nextServiceDate
          .toLocaleDateString("en-US", options)
          .replace(/\//g, "-");

        // Get the day of the week for the current and next service dates
        reqObj.ThisWeekServiceDay =
          daysOfWeek[new Date(currentServiceDate).getDay()];
        reqObj.NextServiceDay = daysOfWeek[new Date(nextServiceDate).getDay()];

        // Make a request to update the day and date
        const response = await axios.post(
          // "http://localhost:4008/setDayDate",
          config.SET_DAY_DATE,
          reqObj,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
            },
          }
        );
        const data = response.data;

        // Handle the response
        if (data && data["status"] === "Success") {
          alert("Update complete.");
          setUpdateServList(Math.random().toString(36).slice(2));
        } else {
          alert(data["message"]);
        }
        console.log(reqObj);
      } catch (error) {
        // Perform error handling, such as showing an error message to the user or logging the error
        alert(error.message);
      }
    }
  };

  const handleJobCompletePartOne = async (reqObj) => {
    if (reqObj.ThisWeekServiceDate !== "No Service") {
      const response = await axios.post(config.JOB_COMPLETE_PART1, reqObj, {
        headers: {
          "Content-Type": "application/json",
          "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
        },
      });
      const data = response.data;

      if (data && data["status"] === "Success") {
        alert(data["message"]);
        setUpdateServList(Math.random().toString(36).slice(2));
      } else {
        alert(data["message"]);
      }
    } else {
      alert("You cannot initiate Job Complete for No Service");
    }
  };

  return (
    <>
      <div className={classes.root}>
        <CustomAppBar isQAToggled={isQAToggled} onToggleQA={handleToggleQA} />

        <Grid
          container
          spacing={5}
          style={{ marginTop: "2%", margin: "auto" }}
          className={classes.gridContainer}
        >
          <>
            <div className={classes.root}>
              <Box
                display="flex"
                alignItems="center"
                style={{ margin: "auto", justifyContent: "center" }}
              >
                {["Lawn Mowing", "One-Time"].map((value, index) => (
                  <button
                    id={value}
                    key={index}
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor:
                        value === serviceSelected ? "#187CAE" : "#d3d3d3",
                      color: value === serviceSelected ? "white" : "black",
                      fontWeight: "bold",
                      fontSize: "1.6vh",
                      borderRadius: "5px",
                      border: "none",
                      cursor: "pointer",
                      minHeight: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      textAlign: "center",
                      textTransform: "none",
                      marginLeft: "0.5rem",
                    }}
                    onClick={() => {
                      setServiceSelected(value);
                    }}
                  >
                    {value}
                  </button>
                ))}
              </Box>
              <h1 style={{ marginBottom: "2%" }}>
                {" "}
                {`${serviceSelected} Schedule`} (
                <span
                  style={{
                    fontSize: "1.5rem",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  {weekRanges}
                </span>
                )
              </h1>
              <input
                type="text"
                placeholder="Search User"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  padding: "8px",
                  marginBottom: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: "1.2rem",
                  fontFamily: "Poppins",
                  width: "600px",
                  fontWeight: "bold",
                  color: "black",
                }}
              />
              {serviceSelected === "Lawn Mowing" && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead}>#</TableCell>
                      <TableCell className={classes.tableHead}>
                        Customer Details
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Past Week{" "}
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        This Week
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Next Service
                      </TableCell>
                      <TableCell className={classes.tableHead}></TableCell>
                      <TableCell className={classes.tableHead}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {segMowServices.hasOwnProperty("LawnMowing") &&
                      segMowServices["LawnMowing"]
                        .filter((customer) => {
                          const username = customer.UserName.toLowerCase();
                          const address = customer.UserAddress.toLowerCase();
                          return (
                            username.includes(searchQuery.toLowerCase()) ||
                            address.includes(searchQuery.toLowerCase())
                          );
                        })
                        .map((customer, index) => (
                          <TableRow key={customer.UID}>
                            <TableCell className={classes.tableContent}>
                              {index + 1}
                            </TableCell>
                            <TableCell className={classes.tableContent}>
                              <span style={{ fontWeight: "bold" }}>{`${
                                customer.UserName
                              } [${
                                customer.SubscriptionType.split("_")[0]
                              }]`}</span>
                              <br />
                              {customer.UserAddress}
                            </TableCell>
                            <TableCell className={classes.tableContent}>
                              {
                                <>
                                  <span
                                    style={{
                                      color:
                                        customer.JobStatus === null
                                          ? null
                                          : customer.JobStatus.includes("Job")
                                          ? "Blue"
                                          : customer.JobStatus.includes("Pay")
                                          ? "Green"
                                          : "black",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {customer.JobStatus !== null &&
                                    customer.JobStatus.length > 0
                                      ? customer.JobStatus
                                      : "No status"}
                                  </span>
                                </>
                              }
                            </TableCell>
                            <TableCell
                              className={classes.tableContent}
                              contentEditable={true}
                              onBlur={(event) => {
                                customer.ThisWeekServiceDate =
                                  event.target.innerText;
                              }}
                              onKeyPress={(event) => {
                                const reqObj = {
                                  UID: customer.UID,
                                  ServiceID: customer.ServiceID,
                                  ThisWeekServiceDate: event.target.innerText,
                                  NextServiceDate: customer.NextServiceDate,
                                  ThisWeekServiceDay:
                                    customer.ThisWeekServiceDay,
                                  NextServiceDay: customer.NextServiceDay,
                                  ServiceCat: customer.ServiceCat,
                                  SubscriptionType: customer.SubscriptionType,
                                };
                                handleDateChange(event, reqObj);
                              }}
                              style={{
                                color:
                                  customer.ThisWeekServiceDate === "Completed"
                                    ? "green"
                                    : customer.ThisWeekServiceDate ===
                                      "No Service"
                                    ? "red"
                                    : "black",
                                fontWeight:
                                  customer.ThisWeekServiceDate === "Completed"
                                    ? "600"
                                    : null,
                              }}
                            >
                              {customer.ThisWeekServiceDate}
                            </TableCell>
                            <TableCell
                              className={classes.tableContent}
                              contentEditable={true}
                              onBlur={(event) => {
                                customer.NextServiceDate =
                                  event.target.innerText;
                              }}
                              onKeyPress={(event) => {
                                const reqObj = {
                                  UID: customer.UID,
                                  ServiceID: customer.ServiceID,
                                  ThisWeekServiceDate:
                                    customer.ThisWeekServiceDate,
                                  NextServiceDate: event.target.innerText,
                                  ThisWeekServiceDay:
                                    customer.ThisWeekServiceDay,
                                  NextServiceDay: customer.NextServiceDay,
                                  ServiceCat: customer.ServiceCat,
                                  SubscriptionType: customer.SubscriptionType,
                                };
                                handleDateChange(event, reqObj);
                              }}
                              style={{
                                color:
                                  customer.NextServiceDate === "No Service"
                                    ? "red"
                                    : "black",
                                fontWeight:
                                  customer.NextServiceDate === "No Service"
                                    ? "500"
                                    : null,
                              }}
                            >
                              {customer.NextServiceDate}
                            </TableCell>
                            {/* <TableCell>
                            {dayjs(customer.ThisWeekServiceDate).isValid() && (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                  type="text"
                                  placeholder="MM-DD-YYYY"
                                  style={{
                                    color: 'black',
                                    padding: '4px',
                                    border: '2px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '14px',
                                    fontFamily: 'Poppins',
                                    width: '110px',
                                    marginBottom: '0',
                                    fontWeight: 'bold',
                                    margin: 0,
                                    padding: 0,
                                    textAlign: 'center'

                                  }}
                                  value={customer.JobCompleteDate}
                                  onChange={(e) => {
                                    const updatedJobCompleteDate = e.target.value;
                                    const updatedCustomers = segMowServices['LawnMowing'].map((cust) => {
                                      if (cust.UID === customer.UID) {
                                        return {
                                          ...cust,
                                          JobCompleteDate: updatedJobCompleteDate,
                                        };
                                      }
                                      return cust;
                                    });
                                    setSegMowServices((lawnList) => ({
                                      ...lawnList,
                                      'LawnMowing': updatedCustomers
                                    }));
                                  }}
                                />
                                <Button
                                  className={classes.clickButton}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    const reqObj = {
                                      UID: customer.UID,
                                      ServiceID: customer.ServiceID,
                                      ThisWeekServiceDate: customer.ThisWeekServiceDate,
                                      NextServiceDate: customer.NextServiceDate,
                                      NextServiceDay: customer.NextServiceDay,
                                      SubscriptionType: customer.SubscriptionType,
                                      JobCompleteDate: customer.JobCompleteDate,
                                    };
                                    handleJobCompletePartOne(reqObj);
                                  }}

                                >
                                  Complete
                                </Button>
                              </div>
                            )}
                          </TableCell> */}
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              )}
              {serviceSelected === "One-Time" && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead}>#</TableCell>
                      <TableCell className={classes.tableHead}>
                        Customer Details
                      </TableCell>
                      {/* <TableCell className={classes.tableHead}>
                      Past Week{" "}
                    </TableCell> */}
                      <TableCell className={classes.tableHead}>
                        This Week
                      </TableCell>
                      {/* <TableCell className={classes.tableHead}>
                      Next Service
                    </TableCell> */}
                      <TableCell className={classes.tableHead}></TableCell>
                      <TableCell className={classes.tableHead}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {segMowServices.hasOwnProperty("One-Time") &&
                      segMowServices["One-Time"]
                        .filter((customer) => {
                          const username = customer.UserName.toLowerCase();
                          const address = customer.UserAddress.toLowerCase();
                          return (
                            username.includes(searchQuery.toLowerCase()) ||
                            address.includes(searchQuery.toLowerCase())
                          );
                        })
                        .map((customer, index) => (
                          <TableRow key={customer.UID}>
                            <TableCell className={classes.tableContent}>
                              {index + 1}
                            </TableCell>
                            <TableCell className={classes.tableContent}>
                              <span
                                style={{ fontWeight: "bold" }}
                              >{`${customer.UserName} [${customer.SubscriptionType}] [${customer.ServiceCat}]`}</span>
                              <br />
                              {customer.UserAddress}
                            </TableCell>
                            {/* <TableCell className={classes.tableContent}>
                            {
                              <>
                                <span
                                  style={{
                                    color:
                                      customer.JobStatus === null
                                        ? null
                                        : customer.JobStatus.includes("Job")
                                          ? "Blue"
                                          : customer.JobStatus.includes("Pay")
                                            ? "Green"
                                            : "black",
                                    fontWeight: "500",
                                  }}
                                >
                                  {customer.JobStatus !== null &&
                                    customer.JobStatus.length > 0
                                    ? customer.JobStatus
                                    : "No status"}
                                </span>
                              </>
                            }
                          </TableCell> */}
                            <TableCell
                              className={classes.tableContent}
                              contentEditable={true}
                              onBlur={(event) => {
                                customer.ThisWeekServiceDate =
                                  event.target.innerText;
                              }}
                              onKeyPress={(event) => {
                                const reqObj = {
                                  UID: customer.UID,
                                  ServiceID: customer.ServiceID,
                                  ThisWeekServiceDate: event.target.innerText,
                                  NextServiceDate: customer.NextServiceDate,
                                  ThisWeekServiceDay:
                                    customer.ThisWeekServiceDay,
                                  NextServiceDay: customer.NextServiceDay,
                                  ServiceCat: customer.ServiceCat,
                                  SubscriptionType: customer.SubscriptionType,
                                };
                                handleDateChange(event, reqObj);
                              }}
                              style={{
                                color:
                                  customer.ThisWeekServiceDate === "Completed"
                                    ? "green"
                                    : customer.ThisWeekServiceDate ===
                                      "No Service"
                                    ? "red"
                                    : "black",
                                fontWeight:
                                  customer.ThisWeekServiceDate === "Completed"
                                    ? "600"
                                    : null,
                              }}
                            >
                              {customer.ThisWeekServiceDate}
                            </TableCell>
                            {/* <TableCell
                            className={classes.tableContent}
                            contentEditable={true}
                            onBlur={(event) => {
                              customer.NextServiceDate = event.target.innerText;
                            }}
                            onKeyPress={(event) => {
                              const reqObj = {
                                UID: customer.UID,
                                ServiceID: customer.ServiceID,
                                ThisWeekServiceDate:
                                  customer.ThisWeekServiceDate,
                                NextServiceDate: event.target.innerText,
                                ThisWeekServiceDay:
                                  customer.ThisWeekServiceDay,
                                NextServiceDay: customer.NextServiceDay,
                                SubscriptionType: customer.SubscriptionType,
                              };
                              handleDateChange(event, reqObj)
                            }}
                            style={{
                              color:
                                customer.NextServiceDate === "No Service"
                                  ? "red"
                                  : "black",
                              fontWeight:
                                customer.NextServiceDate === "No Service"
                                  ? "500"
                                  : null,
                            }}
                          >
                            {customer.NextServiceDate}
                          </TableCell> */}
                            {/* <TableCell>
                            {dayjs(customer.ThisWeekServiceDate).isValid() && (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                  type="text"
                                  placeholder="MM-DD-YYYY"
                                  style={{
                                    color: 'black',
                                    padding: '4px',
                                    border: '2px solid #ccc',
                                    borderRadius: '5px',
                                    fontSize: '14px',
                                    fontFamily: 'Poppins',
                                    width: '110px',
                                    marginBottom: '0',
                                    fontWeight: 'bold',
                                    margin: 0,
                                    padding: 0,
                                    textAlign: 'center'

                                  }}
                                  value={customer.JobCompleteDate}
                                  onChange={(e) => {
                                    const updatedJobCompleteDate = e.target.value;
                                    const updatedCustomers = segMowServices.map((cust) => {
                                      if (cust.UID === customer.UID) {
                                        return {
                                          ...cust,
                                          JobCompleteDate: updatedJobCompleteDate,
                                        };
                                      }
                                      return cust;
                                    });
                                    setSegMowServices(updatedCustomers);
                                  }}
                                />
                                <Button
                                  className={classes.clickButton}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    const reqObj = {
                                      UID: customer.UID,
                                      ServiceID: customer.ServiceID,
                                      ThisWeekServiceDate: customer.ThisWeekServiceDate,
                                      NextServiceDate: customer.NextServiceDate,
                                      NextServiceDay: customer.NextServiceDay,
                                      SubscriptionType: customer.SubscriptionType,
                                      JobCompleteDate: customer.JobCompleteDate,
                                    };
                                    handleJobCompletePartOne(reqObj);
                                  }}

                                >
                                  Complete
                                </Button>
                              </div>
                            )}
                          </TableCell> */}
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              )}
            </div>
          </>
        </Grid>
      </div>
    </>
  );
};
export default LawnSchedule;
