import React from "react";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Grid,
  InputBase,
  makeStyles,
  Paper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Navbar from "../../../components/Navbar";
import Logo from "./images/Logo.png";
import bg from "./images/image 6.png";
import whoweare from "./images/Whoarewe.png";
import lawnhelp from "./images/helpLawn.png";
import mobilebg from "./images/mobileBG.png";
import mobileWhoweare from "./images/mob_whoarewe.png";
import icon1 from "./images/icon1.png";
import icon2 from "./images/icon2.png";
import icon3 from "./images/icon3.png";
import icon4 from "./images/icon4.png";
import "./lawnber.css";
import { LocationOn } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { Stack } from "@mui/material";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {},
  },
  h1: {
    fontSize: "48px",
    textAlign: "left",
    color: "#1B1C57",
    fontWeight: "bold",
    lineHeight: "60px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
      lineHeight: "45.5px",
      textAlign: "center",
    },
  },
  p: {
    color: "#626687",
    opacity: "0.8",
    fontSize: "18px",
    textAlign: "left",
    lineHeight: "28px",
    // paddingLeft: "12%",
    maxWidth: "540px",
    [theme.breakpoints.down("sm")]: {
      padding: "2%",
      lineHeight: "24px",
      fontSize: "16px",
      paddingLeft: "5%",
      paddingRight: "5%",
      textAlign: "justify",
    },
  },
  subheading: {
    textAlign: "left",
    textTransform: "uppercase",
    fontWeight: "700",
    letterSpacing: "0.055em",
    fontSize: "16px",
    color: "#331B3B",
    marginTop: "10px",
  },
  card: {
    padding: "1.4%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "350px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  helpContainer: {
    backgroundImage: `url(${lawnhelp})`,
    marginTop: "5%",
    backgroundSize: "1480px 550px",
    backgroundRepeat: "no-repeat",
    //  backgroundPosition: 'center',
    height: "100%",
  },

  searchBox: {
    padding: "3px 3px",
    paddingLeft: "15px",
    marginTop: "20px",
    borderRadius: "32px",
    width: "75%",
    border: "2px solid black", // #E0E3EB
    display: "flex",
    alignItems: "center",
    maxWidth: "550px",
    // width: 400,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      maxHeight: "50px",
    },
  },
  InstantQuoteButton: {
    borderRadius: "32px",
    textTransform: "capitalize",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "10px",
      maxHeight: "40px",
      lineHeight: "15px",
    },
  },
  grid1: {
    display: "grid",
    placeItems: "center",
    height: "45vh",
    marginTop: "12%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5%",
    },
  },
  grid2: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "15%",
    },
  },
  grid3: {
    display: "grid",
    placeItems: "flex-start",
    height: "50vh",
    [theme.breakpoints.down("sm")]: {
      placeItems: "center",
    },
  },
  cardStack: {
    marginTop: "5%",
    marginLeft: "3%",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      placeItems: "center",
    },
  },
}));

function Lawnber() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        {/* Navbar */}
        <AppBar
          color="transparent"
          elevation={0}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "5%",
          }}
          position="fixed"
        >
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <img
                src={Logo}
                width="8%"
                style={{
                  minWidth: "120px",
                  minHeight: "60px",
                  marginLeft: "1%",
                }}
              ></img>

              <Box flexGrow={1} />
              <Button color="primary" variant="contained">
                Login
              </Button>
            </Toolbar>
          </Container>
        </AppBar>

        {/* Hero section */}
        <div className="heroSection">
          {/* Only visible on mobile device */}
          <div className="mobileBG">
            <img src={mobilebg} width="100%"></img>
          </div>

          {/* Grid for heading and main action button */}
          <Grid className={classes.grid1} xs={12} sm={12} lg={5} xl={6}>
            <h1 className={classes.h1}>
              Easy and reliable
              <br />
              <span className="h1Secondary">Professional lawn care</span>
              <br />
              Service
            </h1>
            <p className={classes.p}>
              Everything you need about lawn care with best pricing, fast
              accurate online ordering and dependable service
            </p>
            <Paper
              component="form"
              elevation={false}
              className={classes.searchBox}
            >
              <LocationOn
                style={{ paddingRight: "10px" }}
                fontSize="large"
                aria-label="menu"
                color="primary"
              />
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                style={{ width: "50%" }}
                placeholder="Enter your address"
                inputProps={{ "aria-label": "Enter your address" }}
              />
              <Box sx={{ flex: 1 }} />
              <Button
                color="primary"
                disableElevation
                type="button"
                className={classes.InstantQuoteButton}
                variant="contained"
              >
                Get Instant Quote
              </Button>
            </Paper>
          </Grid>
          <Grid className="heroPic" xs={12} sm={10} md={5} lg={6} xl={6}>
            <img src={bg} width="100%"></img>
          </Grid>
        </div>

        {/* Who are we component */}
        <Grid className={classes.grid2} lg={12} xs={12} sm={12} xl={12}>
          <Grid xs={12} sm={12} lg={6} xl={7} sx={{ display: { sm: "none" } }}>
            <img src={whoweare} className="whoarewePic" width="100%" />
          </Grid>
          <Grid className={classes.grid3} xs={12} sm={12} lg={5} xl={5}>
            <h1 className={classes.h1} style={{ lineHeight: "50px" }}>
              Who <br />
              <span style={{ fontWeight: "500", textTransform: "lowercase" }}>
                are
              </span>{" "}
              <span
                style={{
                  fontWeight: "500",
                  textTransform: "lowercase",
                  textDecoration: "underline",
                  textDecorationColor: "#187CAE",
                  textUnderlineOffset: "-2%",
                }}
              >
                we
              </span>{" "}
            </h1>
            <img
              src={mobileWhoweare}
              className="mobilewhoarewe"
              style={{ padding: "2%", marginTop: "3%" }}
              width="50%"
            />
            <p
              className={classes.p}
              style={{
                textAlign: "justify",
                maxWidth: "500px",
                marginTop: "3%",
              }}
            >
              Lawnber provides the best lawn services to New York and Nassau
              country residents. We make online booking, direct pricing in less
              than 10 seconds. Our experienced lawn maintenance professionals
              are ready to provide weekly lawn maintenance services so that you
              can focus on the rest of your schedule.
            </p>
            <p
              className={classes.p}
              style={{
                textAlign: "justify",
                maxWidth: "500px",
                marginTop: "3%",
              }}
            >
              Simple to use online tools keep you connected with service crews
              and manage your accounts automatically. Our software runs real
              time status updates to help you stay on top of your lawn care
              needs. Signup with us to get all of your lawn care needs taken
              care of right now!
            </p>
          </Grid>
        </Grid>
        {/* Help your Lawn component */}
        <Grid
          className={classes.helpContainer}
          justifyContent="flex-start"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h1
            className={classes.h1}
            style={{
              marginTop: "4%",
              marginLeft: "-5%",
              lineHeight: "50px",
              color: "white",
              textTransform: "none",
              textAlign: "center",
            }}
          >
            How we can <br />
            <span style={{ fontWeight: "300" }}>help your lawns</span>
          </h1>

          <Stack
            direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
            className={classes.cardStack}
            spacing={2}
            justifyContent="center"
          >
            <Card className={classes.card}>
              <img src={icon1} style={{ maxWidth: "52px" }} />
              <Typography
                className={classes.subheading}
                gutterBottom
                variant="h5"
                component="div"
              >
                Quick 3 Minutes Set Up
              </Typography>
              <Typography className={classes.p}>
                View pricing, choose your options and schedule service all done
                in 3 minutes
              </Typography>
            </Card>

            {/* 2nd card */}
            <Card className={classes.card}>
              <img src={icon2} style={{ maxWidth: "52px" }}></img>
              <Typography
                className={classes.subheading}
                gutterBottom
                variant="h5"
                component="div"
              >
                Easy to use online tools
              </Typography>
              <Typography className={classes.p}>
                Payment, scheduling, feedback. All done through convenient
                platforms.
              </Typography>
            </Card>

            {/* 3rdcard */}
            <Card className={classes.card}>
              <img src={icon3} style={{ maxWidth: "52px" }}></img>
              <Typography
                className={classes.subheading}
                gutterBottom
                variant="h5"
                component="div"
              >
                High Quality
              </Typography>
              <Typography className={classes.p}>
                No compromise on quality. We will ensure great reliable service
              </Typography>
            </Card>
            {/* 4th card */}
            <Card className={classes.card}>
              <img src={icon4} style={{ maxWidth: "52px" }}></img>
              <Typography
                className={classes.subheading}
                gutterBottom
                variant="h5"
                component="div"
              >
                Other Lawn Maintenances
              </Typography>
              <Typography className={classes.p}>
                Lawn mowing, trimming, and leaf cleanup, all at one place.
              </Typography>
            </Card>
          </Stack>
        </Grid>
      </div>
    </>
  );
}

export default Lawnber;
