import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@material-ui/core";
import axios from "axios";
import { createUserWithEmailAndPassword } from "firebase/auth";
import React from "react";
import { useHistory } from "react-router-dom";
import { auth, authQA } from "../../../components/Firebase";
import CustomAppBar from "./AppBar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@mui/material";
// Application Configuration
import config from "../Config";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  table: {
    width: "100%",
  },

  gridContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableHead: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "16px",
  },
  tableContent: {
    fontFamily: "Poppins",
    fontSize: "14px",
  },
}));

function AdminProSignup() {
  const classes = useStyles();
  const [fname, setFname] = React.useState("");
  const [lname, setLname] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const history = useHistory();
  const [allPros, setAllPros] = React.useState([]);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [isQAToggled, setQAToggled] = React.useState(
    localStorage.getItem("isQAToggled") === "true" || false
  );


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggleQA = (value) => {
    setQAToggled(value);
  };


  React.useEffect(async () => {
    // Check if the admin user is logged in
    localStorage.setItem("isQAToggled", isQAToggled);
    const admin = JSON.parse(window.localStorage.getItem("isAdmin"));
    if (admin === false) {
      history.push("/");
    }
    // console.log(config)
    const { data } = await axios.post(
      config.GET_PRO_USERS, 
      //"http://localhost:4008/adminGetProUsers",
      {}, {
      headers: {
        "Content-Type": "application/json",
        "X-Mode": isQAToggled ? 'QA' : 'PROD', // Set the custom header "X-Mode" with the mode value
      }
    },);
    setAllPros(data);
    console.log(data, "--------DATA-------");
  }, [isQAToggled]); // eslint-disable-line react-hooks/exhaustive-deps

  //Checks Email Format
  const validateEmail = (email) => {
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  // const sendOTP = async () => {
  //   window.recaptchaVerifier = new RecaptchaVerifier(
  //     "recaptcha-container",
  //     {
  //       size: "invisible",
  //       callback: (response) => {
  //         // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       },
  //     },
  //     authQA
  //   );
  //   let appVerifier = window.recaptchaVerifier;
  //   await signInWithPhoneNumber(authQA, phone, appVerifier)
  //     .then((confirmationResult) => {
  //       window.confirmationResult = confirmationResult;
  //       console.log(confirmationResult, "Result");
  //     })
  //     .catch((error) => console.error(error));
  // };

  // const verifyOtp = async () => {
  //   let confirmationResult = window.confirmationResult;
  //   confirmationResult.confirm(otp).then((result) => {
  //     // User signed in successfully.
  //     const user = result.user;
  //     console.log(user, "User")
  //     alert("User account created in successfully")
  //     // ...
  //   }).catch((error) => {
  //     // User couldn't sign in (bad verification code?)
  //     // ...
  //   });
  // };

  const handleSubmit = async () => {
    if (email.length === 0) {
      toast.error("Please enter the email address");
      return;
    }

    if (validateEmail(email) == false) {
      toast.error("Incorrect Email format");
      return;
    }

    if (fname.length === 0) {
      toast.error("Please enter the First name");
      return;
    }

    if (phone.length === 0) {
      toast.error("Please enter a Phone number");
      return;
    }

    if (password.length < 6) {
      toast.error(
        "Please enter a password or length of the password should be min 6 characters"
      );
      return;
    } else {
      createUserWithEmailAndPassword(isQAToggled ? authQA : auth, email, password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          const uuid = user.uid;

          const newPro = {
            Email: email,
            FirstName: fname,
            LastName: lname,
            Phone: phone,
            ProID: uuid,
          };

          allPros.push(newPro);

          await axios
            .post(config.PRO_SIGNUP, {
              email: email,
              fname: fname,
              lname: lname,
              phone: phone,
              uuid: uuid,
            }, {
              headers: {
                "Content-Type": "application/json",
                "X-Mode": isQAToggled ? 'QA' : 'PROD', // Set the custom header "X-Mode" with the mode value
              }
            },)
            .catch(function (error) {
              console.log("Error in Axios", error);
              return;
            });
          toast.success("Pro Account has been Created");
          setOpen(false);
          return;
        })
        .catch((error) => {
          console.log("Error in Creating", error);
          if (error.code == "auth/email-already-in-use") {
            toast.error(
              "This email is already in use. Please use another email"
            );
          } else {
            toast.error(error.message);
          }
          return;
        });
    }
  };

  return (
    <>
      <div className={classes.root}>
        <CustomAppBar isQAToggled={isQAToggled} onToggleQA={handleToggleQA} />
        <ToastContainer />
        <h2 style={{ marginTop: "1%" }}>Pro List</h2>

        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          style={{ marginTop: "1%" }}
        >
          Create a Pro
        </Button>

        <div style={{ marginTop: "2%" }}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>Email</TableCell>
                <TableCell className={classes.tableHead}>Name</TableCell>
                <TableCell className={classes.tableHead}>Phone</TableCell>
                <TableCell className={classes.tableHead}>ProID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allPros &&
                allPros?.map((pro, index) => (
                  <TableRow key={pro.ProID}>
                    <TableCell>
                      {index + 1}. {pro.Email}
                    </TableCell>
                    <TableCell>
                      {pro.FirstName} {pro.LastName}
                    </TableCell>
                    <TableCell>{pro.Phone}</TableCell>
                    <TableCell>{pro.ProID}</TableCell>
                    <TableCell>
                      <Button
                        className={classes.clickButton}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          const newState = {
                            Services: pro.Services,
                            FirstName: pro.FirstName,
                            LastName: pro.LastName,
                            ProId: pro.ProID,
                          };

                          const url = `${window.location.origin}/pro/${pro.ProID}?state=${encodeURIComponent(JSON.stringify(newState))}`;

                          window.open(url, '_blank');
                        }}
                      >
                        More
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>

      <Dialog
        fullWidth
        style={{ height: "100%", width: "100%" }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Create a Pro</DialogTitle>
        <DialogContent>
          <form autoComplete="disabled" style={{ maxWidth: "400px" }}>
            <label htmlFor="firstname">First Name</label>
            <input
              required
              type="text"
              autoComplete="disabled"
              placeholder="Enter Pro's First Name"
              id="fname"
              value={fname}
              className="signUpInput"
              onChange={(e) => setFname(e.target.value)}
            ></input>

            <label htmlFor="lastname">Last Name</label>
            <input
              required
              type="text"
              autoComplete="disabled"
              placeholder="Enter Pro's Last Name"
              id="lname"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              className="signUpInput"
            ></input>

            <label htmlFor="lastname">Phone Number</label>
            <input
              required
              type="text"
              autoComplete="disabled"
              placeholder="Enter Pro's Phone Number"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="signUpInput"
            ></input>
            {/* <Button
            onClick={(e) => sendOTP()}
            variant="contained"
            color="primary"
            size="small"
            style={{
              fontWeight: "bold",
              display: "flex",
              textTransform: "capitalize",
            }}
          >
            Send Otp
          </Button> */}

            {/* <div id="recaptcha-container"/> */}

            {/* <label htmlFor="lastname">OTP</label>
          <input
            required
            type="text"
            autoComplete="disabled"
            placeholder="Enter Pro's Phone Number"
            id="otp"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            className="signUpInput"
          ></input> */}

            {/* <Button
            onClick={(e) => verifyOtp()}
            variant="contained"
            color="primary"
            size="small"
            style={{
              fontWeight: "bold",
              display: "flex",
              textTransform: "capitalize",
            }}
          >
            Verify otp
          </Button> */}

            <label style={{ marginTop: "4%" }} htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="signUpInput"
              placeholder="Enter pro email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <label htmlFor="password">Password</label>
            <input
              required
              autoComplete="disabled"
              type="password"
              id="password"
              className="signUpInput"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={(e) => handleSubmit()}
            variant="outlined"
            color="primary"
            style={{
              fontWeight: "bold",
            }}
          >
            Add Account
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AdminProSignup;
