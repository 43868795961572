// Schedule.js    Created 8/8/2022    Rev 4.8

// Purpose:
// Pro Schedule page which reflects today's schedule to the pro user.
// Functions:
// 1. Start and stop button which records the start and stop time of the proUser
// 2. Show today's schedule

// Import all the packages
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../../components/UserContext";
import {
  Button,
  Paper,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Grid,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { onAuthStateChanged } from "firebase/auth";

//import styling
import "../ProUser.css";
import useStyles from "../../../New Home/Styles";

//import assets
import { doc, getDoc, updateDoc } from "@firebase/firestore";
import axios from "axios";
import { Stack } from "@mui/material";
import { auth, db } from "../../../../components/Firebase";
import { LocationOn, MoreHoriz, MoreVert } from "@material-ui/icons";
import config from "../../../QA/Config";
import { ScheduleSend } from "@mui/icons-material";

function EnglishSchedule() {
  const [user, setUser] = useContext(UserContext);
  const classes = useStyles();
  const time = null;
  const [uuid, setuuid] = useState();
  const [scheduledquotes, setscheduledquotes] = useState([]);
  const todayDate = new Date().toISOString().slice(0, 10);
  const nextDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24)
    .toISOString()
    .slice(0, 10);
  const [optiondialogboxOpen, setdialogboxOpen] = React.useState();
  const [totalEarned, setTotalEarned] = React.useState();
  const [totalJobsDone, setJobsDone] = React.useState();
  const [userDetails, setUserDetails] = React.useState({});
  var curr = new Date();
  var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
  var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
  const [checkState, setCheckState] = React.useState(false);

  const theme = useTheme();

  // MUI Responsiveness:
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    function checkUser() {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (userCred) => {
        if (userCred) {
          setUser(userCred);

          //code to display the real Pro name from the acct read from FB
          const userInfo = await getDoc(doc(db, "ProCompany", userCred.uid));
          setuuid(userCred.uid);

          //FirstName and LastName are inside ProInfo in db
          setUserDetails({
            name:
              userInfo.data().OwnerInfo.FirstName +
              " " +
              userInfo.data().OwnerInfo.LastName,
          });

          //Go to firestore db and get necessary information
          const { data } = await axios.post(
            config.CURRENT_PRO_SCHEDULE,
            {
              UID: userCred.uid,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "X-Mode": "PROD", // Set the custom header "X-Mode" with the mode value
              },
            }
          );

          setscheduledquotes(data);
          return;
        } else {
          setUser(null);
          console.log("No user");
          return;
        }
      });
    }
    checkUser();
  }, [checkState]); // eslint-disable-line react-hooks/exhaustive-deps

  console.log(scheduledquotes, "scheduledquotes");

  React.useEffect(async () => {
    var jobNumber = 0;
    var toPay = 0;
    scheduledquotes?.forEach((item) => {
      console.log(item.JobStatusPro, "Jobv");
      if (
        item.JobStatusPro === "Complete Paid" ||
        item.JobStatusPro === "Complete Not Paid"
      ) {
        jobNumber += 1;
      }
      
      if (item.JobStatusPro === "Complete Not Paid") {
        toPay += item.ProPrice;
      }
    });

    setJobsDone(jobNumber);
    setTotalEarned(toPay);
  }, [scheduledquotes]);

  const handleToggleChange = async (e, id, index) => {
    console.log(e.target.checked, "checked");
    console.log(id, "ID", index);

    if (e.target.checked === true) {
      const { data } = await axios.post(
        config.PRO_JOBCOMPLETE,
        {
          index: index,
          ProId: uuid,
          ServiceID: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Mode": "QA", // Set the custom header "X-Mode" with the mode value
          },
        }
      );
      console.log(data, "data");
      console.log("Finally completed");
      setCheckState(Math.random().toString(36).slice(2));
      console.log(scheduledquotes, "Inside handleDone");
    } else {
      const { data } = await axios.post(
        config.PRO_JOBCOMPLETE,
        {
          index: index,
          ProId: uuid,
          ServiceID: id,
          undo: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Mode": "QA", // Set the custom header "X-Mode" with the mode value
          },
        }
      );
      console.log(data, "data");
      console.log("Undo completed");
      setCheckState(Math.random().toString(36).slice(2));
      console.log(scheduledquotes, "Inside handleDone");
    }
  };

  return (
    <>
      {/* Grid for Task Summary */}
      <Grid
        container
        xs={12}
        sm={12}
        lg={12}
        xl={12}
        alignItems="center"
        justifyContent="center"
      >
        <Grid xs={11} sm={11} md={5} lg={4} xl={4} style={{ marginLeft: "1%" }}>
          <Stack spacing={3} style={{ marginTop: "3%" }} direction="row">
            <Paper
              style={{
                maxWidth: "500px",
                width: "100%",
                padding: "3%",
                backgroundColor: "#E2F6E9",
              }}
            >
              <h5 style={{ padding: "1%", color: "#596672" }}>
                Week's Earning
              </h5>
              <h2 style={{ color: "#3E995B" }}>
                {totalEarned ? (
                  <span>${totalEarned.toFixed(2)}</span>
                ) : (
                  <span>$0</span>
                )}
              </h2>
            </Paper>

            <Paper
              style={{
                maxWidth: "500px",
                width: "100%",
                padding: "3%",
                backgroundColor: "#E5F6FF",
              }}
            >
              <h5 style={{ padding: "1%", color: "#596672" }}>Job's Done</h5>
              <h2 style={{ color: "#2C71FF" }}>
                {totalJobsDone ? (
                  <span>
                    {totalJobsDone}/{scheduledquotes?.length}
                  </span>
                ) : (
                  <span>0/{scheduledquotes?.length}</span>
                )}
              </h2>
            </Paper>
          </Stack>
        </Grid>
      </Grid>

      {/* Grid for This Week schedule */}
      <Grid
        container
        xs={12}
        sm={12}
        lg={12}
        xl={12}
        style={{
          // padding: "2%",
          display: "flex",
          flexDirection: matchesSm ? "row-reverse" : "",
        }}
        alignItems="baseline"
        justifyContent="center"
      >
        <Grid
          item
          xs={11}
          sm={11}
          md={5}
          lg={4}
          xl={4}
          style={{ marginLeft: "1%" }}
          justifyContent="center"
        >
          <h6
            className={classes.mainHeading}
            style={{
              textTransform: "capitalize",
              marginBottom: "2%",
              textAlign: "center",
              display: "grid",
            }}
          >
            This Week({firstday.getMonth() + 1}-{firstday.getDate()}-2023),{" "}
            {userDetails["name"]} Jobs
          </h6>

          {scheduledquotes ? (
            scheduledquotes.map((payload, index) => (
              <Card
                elevation={3}
                key={payload.ServiceID}
                style={{
                  backgroundColor:
                    scheduledquotes[index].JobStatusPro !== "Service This Week"
                      ? "#D9D9D9"
                      : "white",
                  opacity:
                    scheduledquotes[index].JobStatusPro !== "Service This Week"
                      ? "0.7"
                      : "1",
                  width: "98.5%",
                  marginBottom: "10px",
                  padding: "10px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        display: "flex",
                        color: scheduledquotes[index].New ? "red" : "black",
                      }}
                    >
                      {index + 1}.{" "}
                      {payload.hasOwnProperty("ServiceAddr")
                        ? payload.ServiceAddr?.split(", USA")
                        : payload.Address?.split(", USA")}
                    </Typography>
                    <Typography style={{ display: "flex" }}>
                      {payload.hasOwnProperty("ServiceCategory")
                        ? payload.ServiceCategory
                        : payload.ServiceCat}{" "}
                      [{payload.SubscriptionType}]{" "}
                    </Typography>
                  </Box>
                  <div
                    style={{
                      height: "100%",
                      padding: "5px",
                      fontWeight: "bold",
                      display: "flex",
                    }}
                  >
                    ${payload.ProPrice}
                  </div>
                </div>
                <Typography style={{ display: "flex" }}>
                  {/* Mow Weekly - Fertilizer + Spot Seeding */}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    marginTop: "5px",
                    border: "2px solid #dadbdb",
                    borderRadius: "5px",
                  }}
                >
                  <div>Job Complete?</div>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>No</Typography>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            scheduledquotes[index].JobStatusPro ===
                            "Service This Week"
                              ? false
                              : true
                          }
                          onChange={(e) =>
                            handleToggleChange(e, payload.ServiceID, index)
                          }
                          color="primary"
                        />
                      }
                    />
                    <Typography>Yes</Typography>
                  </Stack>
                </div>
              </Card>
            ))
          ) : (
            <Card
              elevation={2}
              style={{
                backgroundColor: "white",
                width: "98.5%",
                marginBottom: "10px",
                padding: "5px",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ fontWeight: "bold", display: "flex" }}>
                    Sorry No Schedule for this week
                  </Typography>
                </Box>
              </div>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default EnglishSchedule;
