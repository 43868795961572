// This is the javascript file corresponding to index.html.
// This file loads nto an html element with id root.

//include imports here; Sort according to name;
import "./index.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { HunelProvider, HunelCreditCard } from "reactjs-credit-card";

const hunel = new HunelCreditCard();

ReactDOM.render(
  <React.StrictMode>
    <HunelProvider config={hunel}>
      <App />
    </HunelProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
