import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import React from "react";
import { authQA, dbQA } from "../../../components/Firebase";
import { UserContext } from "../../../components/UserContext";

function Notification() {
  const [userQA, setUserQA] = React.useContext(UserContext);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    function checkUser() {
      localStorage.setItem("userQA", JSON.stringify(userQA));
      onAuthStateChanged(authQA, async (userCred) => {
        if (userCred) {
          setUserQA(userCred);

          // Document in which 7 day services are present
          const proCompanyWeeklyServices = doc(
            dbQA,
            "ProCompany",
            userCred.uid,
            "Services",
            "Day7Weekly"
          );

          onSnapshot(
            doc(dbQA, "ProCompany", userCred.uid, "Services", "Day7Weekly"),
            { includeMetadataChanges: true },
            (doc) => {
              data.push(doc.data().D1_MON);
              console.log(doc.data().D1_MON, "docdata");
            }
          );
          //   // Check current Day Schedule
          //   const serviceData = await getDoc(proCompanyServiceOfferings);

          //   if (serviceData.exists()) {
          //     setServices(serviceData.data().Service);
          //     console.log("Data dailyService", serviceData.data());
          //   } else {
          //     console.log("Sorry no data");
          //     return;
          //   }
          return;
        } else {
          setUserQA(null);
          console.log("No user");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  console.log("Data",data);

  return (
    <>
      Notification
      {data
        ? data.map((payload) => <div>{payload[0].Addr}</div>)
        : null}
    </>
  );
}

export default Notification;
