import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  // Backgrounds
  root: {
    padding: theme.spacing(2, 4),
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    paddingTop: "10px",
    paddingBottom: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: "white",
  },
  root2: {
    display: "flex",
    width: "100vw",
    height: "100%",
    flexDirection: "column",
    backgroundColor: "white",
    paddingLeft: "2%",
  },
  paper: {
    padding: theme.spacing * 2,
    textAlign: "center",
  },
  backgroundBG: {
    marginTop: "-2%",
    height: "70vh",
    width: "100vw",
    [theme.breakpoints.down("sm")]: {
      height: "60vh",
      marginTop: "-10%",
    },
  },

  // Button Styling
  buttonPrimary: {
    backgroundColor: "#187CAE",
    fontSize: "100%",
    border: "none",
    borderRadius: "28px",
    margin: "0px",
    padding: "5px 10px",
    color: "white!important",
    fontWeight: "600",
    whiteSpace: "nowrap",
    minWidth: "180px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      minHeight: "45px",
    },
  },
  buttonPrimarySmall: {
    backgroundColor: "#187CAE",
    fontSize: "100%",
    border: "none",
    borderRadius: "28px",
    margin: "0px",
    padding: "5px 10px",
    fontWeight: "600",
    whiteSpace: "nowrap",
    minWidth: "120px",
    color: "white",
    height: "40px",
    fontFamily: "Poppins",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      minHeight: "45px",
      color: "white!important",
    },
  },
  buttonPrimary2: {
    border: "4px solid #187CAE",
    fontSize: "100%",
    color: "black",
    borderRadius: "28px",
    marginLeft: "2%",
    padding: "5px 10px",
    fontWeight: "600",
    whiteSpace: "nowrap",
    minWidth: "120px!important",
    height: "40px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      minHeight: "45px",
      maxHeight: "30px",
    },
  },
  secondaryButton: {
    fontSize: "100%",
    border: "4px solid #187CAE",
    borderRadius: "28px",
    margin: "0px",
    padding: "5px 10px",
    fontWeight: "600",
    width: "30%",
    whiteSpace: "nowrap",
    minWidth: "150px!important",
    [theme.breakpoints.down("sm")]: {
      width: "78%!important",
      minWidth: "280px",
      marginLeft: "40%",
    },
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      paddingTop: "3%",
      paddingBottom: "5%",
    },
  },
  toggleButton: {
    backgroundColor: "#d3d3d3!important",
    fontWeight: "bold!important",
    marginRight: "5vw !important",
    borderRadius: "5px!important",
    border: "none!important",
    minWidth: "100px",
    width: "100%!important",
    color: "white!important",
    "&.Mui-selected": {
      backgroundColor: "#187CAE!important",
      color: "white",
      fontWeight: 600,
    },
  },

  // Stepper Styling
  stepper: {
    width: "90%",
    margin: "2%",
    [theme.breakpoints.down("sm")]: {
      margin: "1%",
      width: "90vw",
    },
  },
  proSignupstepper: {
    width: "90%",
    margin: "2%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  // Texts
  title: {
    padding: theme.spacing(0),
    color: "white",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  subHeading: {
    padding: theme.spacing(0),
    color: "black",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  heading: {
    color: "rgb(62, 106, 225)",
    fontWeight: "bold",
    fontSize: "40px",
  },
  h2: {
    fontSize: "3.5vh",
    color: "#43504B",
  },
  p: {
    fontSize: "2vh",
    color: "#9E9E9E",
  },
  mainHeading: {
    fontWeight: "bolder",
    display: "flex",
    fontSize: "100%",
    paddingTop: "3%",
    // paddingBottom: "2%",
    [theme.breakpoints.down("sm")]: {
      // display: "grid",
      // placeItems: "center",
      marginLeft: "5px",
      fontSize: "18px",
    },
  },

  component: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    paddingTop: "10px",
    paddingBottom: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      paddingBottom: "5px",
      paddingLeft: "5px",
      paddingRight: "5px",
      paddingTop: "10px",
    },
  },

  image: {
    width: "100%",
    maxWidth: "850px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    height: "auto",
  },
  grid: {
    marginTop: "30px",
    marginLeft: "1%",
    marginBottom: "2%",
    width: "100%",
    // backgroundColor: "grey",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0%",
    },
  },

  loginForm: {
    marginTop: "2%",
    color: "black",
    height: "100%",
    background: "white",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "left",
    width: "35vw",
    minWidth: "300px",
  },
  select: {
    backgroundColor: "white",
    border: "2px solid #EFF2F7",
    maxHeight: "45px",
    marginBottom: "10px",
  },
  card: {
    width: "100%",
    height: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundImage: "linear-gradient(45deg, #0079EB 30%, #21CBF3 90%)",
    "&:hover": {
      backgroundPosition: "right",
    },
  },
  quotesCard: {
    backgroundColor: "#EDEEEF",
    padding: "2%",
    width: "100%",
    borderRadius: "8px",
    marginBottom: "2%",
  },
  masterFlexWithSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "small",
    },
  },
  subFlexWithSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingRight: "3%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "small",
      // alignItems: "flex-start",
      alignItems: "center",
      width: "80vw",
      justifyContent: "space-between",
    },
  },
  profileFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "80%",
    paddingRight: "3%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "small",
      // alignItems: "flex-start",
      alignItems: "center",
      width: "80vw",
      justifyContent: "space-between",
    },
  },
  profileFlex2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: "small",
      // alignItems: "flex-start",
      alignItems: "center",
      width: "80vw",
      justifyContent: "space-between",
    },
  },
  rightGrid: {
    paddingTop: "2vh",
    marginLeft: "0% !important",
    backgroundColor: "#233D53",
    fontSize: "30%",
    zIndex: "1",
    padding: "2%",
    height: "100vh",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      padding: "3%!important",
      width: "100vw",
      height: "100%",
      minWidth: "100vw",
    },
  },

  // All the table styling
  table: {
    width: "90vw",
    height: "100%",
  },
  tableContainer: {
    boxShadow: "none",
    // margin: "10px 10px",
    width: "90%",
    height: "100%",
    maxHeight: "70vh",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0px 0px",
      marginTop: "10px",
    },
  },
  tableHeaderCell: {
    fontWeight: "bold",
    color: "black",
    position: "sticky",
    borderBottom: "2px solid #187CAE",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "10px",
    },
  },
  tableHeaderAddressCell: {
    fontWeight: "bold",
    color: "black",
    backgroundColor: "red",
    borderBottom: "2px solid #187CAE",
    [theme.breakpoints.down("sm")]: {
      // minWidth: "20vw",
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  name: {
    fontWeight: "bold",
    color: "#187CAE",
  },
  customTableContainer: {
    overflowX: "initial",
  },
  status: {
    fontWeight: "bold",
    // fontSize: "0.75rem",
    color: "white",
    borderRadius: "10px",
    padding: "3px 10px",
    display: "grid",
    fontSize: "16px",
    placeItems: "center",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "40vw",
      minWidth: "30%",
      fontSize: "16px",
      minHeight: "100%",
    },
  },
  searchBoxLandPage: {
    padding: "3px 3px",
    paddingLeft: "15px",
    marginTop: "20px",
    borderRadius: "32px",
    width: "75%",
    border: "2px solid black", // #E0E3EB
    display: "flex",
    alignItems: "center",
    maxWidth: "550px",
    // width: 400,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      maxHeight: "50px",
    },
  },
  InstantQuoteButtonLandPage: {
    borderRadius: "32px",
    textTransform: "capitalize",
    fontWeight: "bold",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "10px",
      maxHeight: "40px",
      lineHeight: "15px",
      minWidth: "30%",
    },
  },
  Timelinep: {
    marginLeft: "20%",
    marginBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: "100%",
      marginRight: "10px",
    },
  },
}));

export default useStyles;
