import React, { useState, useContext } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// Application Configuration
import config from "../Config";

import {
  Typography,
  Grid,
  Paper,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Avatar,
  Tooltip,
  useTheme,
  useMediaQuery,
  DialogTitle,
} from "@material-ui/core";
import { Delete, Email, Send } from "@material-ui/icons";
import { MarkAsUnread } from "@mui/icons-material";
import AdminPage from "./AdminDashboard";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  gridContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },

  button: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: "Poppins",
  },
  list: {
    width: 250,
  },
  input: {
    fontWeight: "bold",
    borderRadius: "10px",
    fontFamily: "Poppins",
  },
  clickButton: {
    fontWeight: "bold",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    minHeight: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    textAlign: "center",
    textTransform: "none",
    marginLeft: "0.5rem",
    backgroundColor: "#187CAE",
    color: "white",
    fontSize: "1.6vh",
    fontFamily: "Poppins",
  },
  tableHead: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "16px",
  },
  tableContent: {
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  card: {
    marginTop: "1rem",
    marginBottom: "1rem",
    backgroundColor: "#f5f5f5",
    borderRadius: "0px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.3)",
    padding: "1rem",
    marginLeft: "1rem",
  },
  serviceName: {
    marginBottom: "0.5rem",
    fontFamily: "Poppins",
    fontWeight: "bold",
  },
  serviceInfo: {
    marginBottom: "0.25rem",
    display: "inline-block",
    fontFamily: "Poppins",
  },
  itemText: {
    fontFamily: "Poppins",
    color: "Black",
    fontSize: "14px",
    fontWeight: 500,
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    //color: "white", // Set the text and spinner color
  },
}));

const CustomerDetails = () => {
  const classes = useStyles();
  const { uid } = useParams(); // Get the uid parameter from the URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const stateParam = searchParams.get("state");
  const { Services, Name, Address } = JSON.parse(
    decodeURIComponent(stateParam)
  );
  const [ActivityLogs, setActivityLogs] = useState([]);
  const [NotificationCounts, setNotificationCounts] = useState();
  const history = useHistory();
  const [serviceData, setServiceData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [proIDs, setProIDs] = useState([]);
  const [servIDs, setServIDs] = useState([]);
  const [selectButton, setSelectButton] = useState("");
  const [selectValues, setSelectValues] = useState({});
  const [enterPrices, setEnterPrices] = useState({});
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [ip, setIp] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [dialogBoxOpen, setDialogBOxOpen] = useState();
  const [checkState, setCheckState] = React.useState(false);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  let inputRef = React.useRef();

  var tempArray = [];
  const [isQAToggled, setQAToggled] = useState(
    localStorage.getItem("isQAToggled") === "true" || false
  );

  const handleToggleQA = (value) => {
    setQAToggled(value);
  };

  React.useEffect(async () => {
    // Check if the admin user is logged in
    localStorage.setItem("isQAToggled", isQAToggled);
    const admin = JSON.parse(window.localStorage.getItem("isAdmin"));
    if (admin === false) {
      history.push("/");
    }
    fetchData();
  }, [isQAToggled, checkState]);

  async function fetchData() {
    const { data } = await axios.post(
      config.CUSTOMER_DETAILS,
      //'http://localhost:4008/customerDetails',
      {
        UID: uid,
        Services: Services,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
        },
      }
    );
    console.log(data, "Data");
    setNotificationCounts(data.NotificationCount);
    data.Service.forEach((category) => {
      Object.entries(category).forEach(([serviceName, serviceData]) => {
        const serviceID = serviceData["Service ID"];
        const ProPrice = serviceData["Pro Price"];
        const ProID = serviceData["Pro ID"];

        if (serviceID) {
          tempArray.push({ [serviceName]: serviceID });
          setEmail(serviceData["Email"]);
          setPhoneNo(serviceData["Phone"]);
        }
        if (ProPrice) {
          setEnterPrices((prevState) => ({
            ...prevState,
            [serviceName]: ProPrice,
          }));
        }
        if (ProID) {
          setSelectValues((prevState) => ({
            ...prevState,
            [serviceName]: ProID,
          }));
        }
      });
    });
    setServIDs(tempArray);
    setServiceData(data.Service);
    setEmail(data.Email);
    setPhoneNo(data.Phone);
    setIp(data.IP);
    setHistoryData(data.History);
    setProIDs(data.ProIDs);

    const logs = await axios.post(
      "https://be-operation-services.herokuapp.com/QA/GetActivityLog",
      //'http://localhost:4008/QA/GetActivityLog',
      {
        UID: uid,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
        },
      }
    );

    if (logs.data == "No ActivityLogs") {
      var tmp = [];
      setActivityLogs(tmp);
    } else {
      setActivityLogs(logs.data);
    }
  }

  const handleServiceUpdates = async (serviceName, operation) => {
    var serviceID;

    servIDs.forEach((obj) => {
      if (obj.hasOwnProperty(serviceName)) {
        serviceID = obj[serviceName];
      }
    });

    console.log(enterPrices);

    const reqObj = {
      UID: uid,
      ProID: selectValues[serviceName].split(" ")[0].trim(),
      Price: parseFloat(enterPrices[serviceName]),
      ServiceID: serviceID,
      ServiceName: serviceName,
      UserName: Name,
      Operation: operation,
    };
    console.log(reqObj);

    const { data } = await axios.post(
      config.ASSIGN_PRO,
      //"http://localhost:4008/AssignProToCust",
      reqObj,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
        },
      });

    alert(data.message);
  };

  const handleChange_subject = (event) => {
    setSubject(event.target.value);
  };

  const handleChange_text = (event) => {
    setText(event.target.value);
  };

  // This function is used to submit Notification when Submit Icon is pressed
  const handleActivitySubmit = async () => {
    if (text.length > 0) {
      await axios
        .post(
          "https://be-operation-services.herokuapp.com/QA/AddActivityLog",
          //'http://localhost:4008/QA/AddActivityLog',
          {
            UID: uid,
            Message: text,
            Subject: "Notes",
            Status: "Read",
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
            },
          }
        )
        .then(setCheckState(Math.random().toString(36).slice(2)));
      setText("");
    } else {
      alert("Text field is empty");
    }
  };

  // If a Notification is Unread clicking on the bold text changes to "Read"
  const handleActivityRead = (item, i) => {
    if (item.Status === "Unread") {
      axios.post(
        "https://be-operation-services.herokuapp.com/QA/UpdateActivityLog",
        //'http://localhost:4008/QA/UpdateActivityLog',
        {
          UID: uid,
          Status: "Read",
          id: Number(ActivityLogs.length - i - 1),
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
          },
        }
      );
      setCheckState(Math.random().toString(36).slice(2));
    } else {
      const ActivityLogsInput = [...ActivityLogs];
      ActivityLogsInput[i].isOpen = ActivityLogsInput[i].isOpen
        ? !ActivityLogsInput[i].isOpen
        : true;
      setActivityLogs(ActivityLogsInput);
    }
  };

  // This function is used to revert back Read Notification to Unread
  const handleUnread = (item, i) => {
    if (item.Status === "Read") {
      axios.post(
        "https://be-operation-services.herokuapp.com/QA/UpdateActivityLog",
        //'http://localhost:4008/QA/UpdateActivityLog',
        {
          UID: uid,
          Status: "Unread",
          id: Number(ActivityLogs.length - i - 1),
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
          },
        }
      );
      setCheckState(Math.random().toString(36).slice(2));
    } else return;
  };

  // This function is used to Edit the Notification
  const handleEdit = (item, i) => {
    console.log(item, i);

    const ActivityLogsInput = [...ActivityLogs];
    ActivityLogsInput[i].Message = inputRef.current
      ? inputRef.current.value
      : ActivityLogsInput[i].Message;
    setActivityLogs(ActivityLogsInput);
    console.log("ActivityLogsInput", ActivityLogsInput);

    axios.post(
      "https://be-operation-services.herokuapp.com/QA/UpdateActivityLog",
      {
        UID: uid,
        Status: "Edit",
        id: Number(ActivityLogs.length - i - 1),
        Message: ActivityLogs[i].Message,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
        },
      }
    );
    setCheckState(Math.random().toString(36).slice(2));
  };

  // This function is used to Delete the Notification
  const handleDelete = (item, i) => {
    axios.post(
      "https://be-operation-services.herokuapp.com/QA/UpdateActivityLog",
      {
        UID: uid,
        Status: "Delete",
        id: Number(ActivityLogs.length - i - 1),
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-Mode": isQAToggled ? "QA" : "PROD", // Set the custom header "X-Mode" with the mode value
        },
      }
    );
    setCheckState(Math.random().toString(36).slice(2));
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => {
                history.push("/Dashboard");
              }}
            >
              <ExitToAppIcon />
            </IconButton>
            {!matchesSm ? (
              <Typography variant="h6" className={classes.title}>
                {`${Name}` + " " + `(${uid})`}
              </Typography>
            ) : (
              <Typography variant="h6" className={classes.title}>
                {uid.substring(0, 3)}
              </Typography>
            )}

            <Box flexGrow={1} />
            <Button variant="contained" onClick={() => setDialogBOxOpen(true)}>
              Activity Log
              {NotificationCounts > 0 && (
                <Avatar
                  size="large"
                  style={{
                    position: "absolute",
                    width: "24px",
                    height: "24px",
                    fontSize: "18px",
                    marginLeft: "6.5vw",
                    marginTop: "-3vh",
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {NotificationCounts}
                </Avatar>
              )}
            </Button>
          </Toolbar>
        </AppBar>

        {/* Dialog box */}
        <Dialog fullWidth={true} style={{ width: "100%" }} open={dialogBoxOpen}>
          <DialogTitle>
            <Typography
              variant="h5"
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                marginBottom: "-1rem",
                paddingLeft: "10px",
              }}
            >
              Activity Logs
            </Typography>
          </DialogTitle>
          <DialogContent style={{ textAlign: "left", width: "100%" }}>
            <div
              style={{
                height: ActivityLogs.length > 0 ? "500px" : "30px",
                overflowY: "auto",
                width: "100%",
              }}
            >
              {ActivityLogs.length > 0
                ? ActivityLogs?.map((item, index) => (
                  <Card
                    key={index}
                    style={{
                      marginBottom: "1%",
                      cursor: item.Status === "Unread" ? "pointer" : "",
                      maxWidth: "98%",
                    }}
                  >
                    <Box
                      style={{
                        padding: "1%",
                        width: "100%",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "1%",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            // variant="subtitle1"
                            color="text.secondary"
                            component="div"
                            style={{
                              fontWeight:
                                item.Status === "Unread" ? "bold" : "",
                              display: "flex",
                              width: "100%",
                              fontSize: matchesSm ? "12px" : "",
                              minWidth: matchesSm ? "100px" : "10vw",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.Date}
                          </Typography>
                          <Typography
                            component="div"
                            style={{
                              color:
                                item.Subject === "Notes"
                                  ? "#187CAE"
                                  : "black",
                              fontSize: matchesSm ? "12px" : "",
                              fontWeight: "bold",
                              width: !matchesSm ? "100%" : "",
                              marginLeft: matchesSm ? "2vw" : "15%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.Subject}
                          </Typography>
                        </div>
                        {item.Status === "Read" ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "right",
                            }}
                          >
                            <Tooltip title="Mark as Unread">
                              <MarkAsUnread
                                fontSize="10px"
                                color="primary"
                                style={{
                                  padding: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleUnread(item, index)}
                              />
                            </Tooltip>
                            <Tooltip title="Delete the message">
                              <Delete
                                fontSize="10px"
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  color: "red",
                                }}
                                onClick={() => handleDelete(item, index)}
                              />
                            </Tooltip>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "right",
                            }}
                          />
                        )}
                      </Box>

                      {item.isOpen ? (
                        <div>
                          <form>
                            <textarea
                              ref={inputRef}
                              multiline
                              variant="outlined"
                              margin="dense"
                              defaultValue={item.Message}
                              style={{
                                color: "black",
                                width: "100%",
                                minWidth: "100%",
                                fontSize: "14px",
                                height: "100%",
                                border: "3px solid #f4f4f4",
                                fontWeight: "300",
                              }}
                            />
                            <Button
                              size="small"
                              onClick={() => {
                                const ActivityLogsInput = [...ActivityLogs];
                                ActivityLogsInput[index].isOpen =
                                  ActivityLogsInput[index].isOpen
                                    ? !ActivityLogsInput[index].isOpen
                                    : true;
                                setActivityLogs(ActivityLogsInput);
                              }}
                              style={{ color: "red", marginRight: "3%" }}
                            >
                              Back
                            </Button>
                            <Button
                              size="small"
                              onClick={() => handleEdit(item, index)}
                              variant="contained"
                              color="primary"
                            >
                              Update
                            </Button>
                          </form>
                        </div>
                      ) : (
                        <Typography
                          onClick={() => handleActivityRead(item, index)}
                          style={{
                            fontWeight:
                              item.Status === "Unread" ? "bold" : "",
                            width: "100%",
                            height: "100%",
                            fontSize: "16px",
                          }}
                        >
                          {item.Message}
                        </Typography>
                      )}
                    </Box>
                  </Card>
                ))
                : "No Service"}
            </div>
            <Card
              style={{
                display: "flex",
                marginTop: "3%",
                padding: "1%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <input
                required
                autoFocus
                type="text"
                value={text}
                className="greyInput"
                style={{
                  maxWidth: "83%",
                  marginLeft: "1%",
                }}
                placeholder=" Enter the notes here..."
                onChange={handleChange_text}
              ></input>
              <Button
                // size="small"
                onClick={handleActivitySubmit}
                variant="contained"
                color="primary"
                style={{ maxHeight: "33.5px" }}
              >
                <Send />
              </Button>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ color: "red" }}
              onClick={() => setDialogBOxOpen(false)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {email ? (
          <>
            <Grid item xs={12}>
              <Paper className={classes.card} elevation={3}>
                <CardContent style={{ textAlign: "left", fontWeight: "500" }}>
                  <Typography variant="h6" className={classes.serviceName}>
                    {"General Information"}
                  </Typography>
                  <table>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            textAlign: "left",
                            fontWeight: "500",
                            paddingRight: "10px",
                          }}
                        >
                          <Typography className={classes.serviceInfo}>
                            Address:
                          </Typography>
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <Typography className={classes.serviceInfo}>
                            {Address}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "left",
                            fontWeight: "500",
                            paddingRight: "10px",
                          }}
                        >
                          <Typography className={classes.serviceInfo}>
                            Email:
                          </Typography>
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <Typography className={classes.serviceInfo}>
                            {email}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "left",
                            fontWeight: "500",
                            paddingRight: "10px",
                          }}
                        >
                          <Typography className={classes.serviceInfo}>
                            PhoneNo:
                          </Typography>
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <Typography className={classes.serviceInfo}>
                            {phoneNo}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "left",
                            fontWeight: "500",
                            paddingRight: "10px",
                          }}
                        >
                          <Typography className={classes.serviceInfo}>
                            Activity Logs Interactions:
                          </Typography>
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <Typography className={classes.serviceInfo}>
                            {ActivityLogs.length}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "left",
                            fontWeight: "500",
                            paddingRight: "10px",
                          }}
                        >
                          <Typography className={classes.serviceInfo}>
                            IP:
                          </Typography>
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <Typography className={classes.serviceInfo}>
                            {Services.includes("Un-Committed Subscriber") ? (
                              ip
                            ) : (
                              <>
                                {ip[0]}
                                {ip[1] && <br />}
                                {ip[1]}
                              </>
                            )}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CardContent>
              </Paper>
            </Grid>

            <Grid container spacing={4} justifyContent="flex-start">
              {serviceData.length > 0 &&
                !Services.includes("Un-Committed Subscriber") &&
                serviceData.map((category, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Paper className={classes.card}>
                      <CardContent style={{ textAlign: "left" }}>
                        {Object.keys(category).map((serviceName, index) => (
                          <div key={index} style={{ marginBottom: "1rem" }}>
                            <Typography
                              variant="h6"
                              className={classes.serviceName}
                            >
                              {serviceName}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {Object.entries(category[serviceName]).map(
                                ([key, value], index, arr) => (
                                  <>
                                    <Typography
                                      key={key}
                                      className={classes.serviceInfo}
                                    >
                                      <span style={{ fontWeight: 500 }}>
                                        {key}
                                      </span>
                                      :
                                      <span
                                        style={{
                                          color:
                                            key === "Total Amount"
                                              ? "green"
                                              : value === "not assigned"
                                                ? "red"
                                                : "black",
                                          fontWeight:
                                            key === "Total Amount" ||
                                              key === "Service Selected" ||
                                              key === "Subscription Type"
                                              ? "bolder"
                                              : 500,
                                          marginLeft: "2%",
                                        }}
                                      >
                                        {key !== "Pro ID" &&
                                          key !== "Pro Price" &&
                                          value}
                                        {key === "Pro ID" && (
                                          <select
                                            required
                                            id={serviceName}
                                            value={
                                              selectValues[serviceName] !==
                                                undefined
                                                ? selectValues[serviceName]
                                                : value
                                            }
                                            onChange={(e) => {
                                              setSelectValues((prevState) => ({
                                                ...prevState,
                                                [serviceName]: e.target.value,
                                              }));
                                            }}
                                            style={{
                                              width: "70%",
                                              border: "2px solid black",
                                              borderRadius: "5px",
                                              backgroundColor: "#f7f7f7",
                                              color: "black",
                                              fontWeight: "500",
                                              outline: "none",
                                              boxShadow:
                                                "0 2px 4px rgba(0, 0, 0, 0.1)",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            <option value="">{value}</option>
                                            {proIDs &&
                                              proIDs?.map((id, index) => (
                                                <option key={index} value={id}>
                                                  {id}
                                                </option>
                                              ))}
                                          </select>
                                        )}
                                        {key === "Pro Price" && (
                                          <input
                                            required
                                            type="text"
                                            autoComplete="disabled"
                                            placeholder=" Enter Price"
                                            id={serviceName}
                                            value={
                                              enterPrices[serviceName] !==
                                                undefined
                                                ? enterPrices[serviceName]
                                                : value
                                            }
                                            onChange={(e) =>
                                              setEnterPrices((prevState) => ({
                                                ...prevState,
                                                [serviceName]: e.target.value,
                                              }))
                                            }
                                            style={{
                                              width: "50%",
                                              border: "2px solid black",
                                              borderRadius: "5px",
                                              backgroundColor: "#f7f7f7",
                                              color: "black",
                                              outline: "none",
                                              boxShadow:
                                                "0 2px 4px rgba(0, 0, 0, 0.1)",
                                              paddingLeft: "10px",
                                              fontWeight: "500",
                                            }}
                                          ></input>
                                        )}
                                      </span>
                                    </Typography>
                                    {arr.length - 1 === index && (
                                      <select
                                        required
                                        id={serviceName + index}
                                        value={selectButton}
                                        onChange={(e) => {
                                          setSelectButton(e.target.value);
                                          if (e.target.value === "Update") {
                                            handleServiceUpdates(
                                              serviceName,
                                              "Update"
                                            );
                                            setSelectButton(" ");
                                          }
                                          if (e.target.value === "Pause") {
                                            handleServiceUpdates(
                                              serviceName,
                                              "Pause"
                                            );
                                            setSelectButton(" ");
                                          }
                                          if (e.target.value === "Un-Pause") {
                                            handleServiceUpdates(
                                              serviceName,
                                              "Un-Pause"
                                            );
                                            setSelectButton(" ");
                                          }
                                          if (e.target.value === "Cancel") {
                                            handleServiceUpdates(
                                              serviceName,
                                              "Cancel"
                                            );
                                            setSelectButton(" ");
                                          }
                                        }}
                                        style={{
                                          width: "50%",
                                          border: "2px solid black",
                                          borderRadius: "5px",
                                          backgroundColor: "#f7f7f7",
                                          color: "black",
                                          fontWeight: "500",
                                          outline: "none",
                                          boxShadow:
                                            "0 2px 4px rgba(0, 0, 0, 0.1)",
                                          paddingLeft: "10px",
                                          cursor: "pointer",
                                          textAlign: "center",
                                          padding: "8px 12px",
                                        }}
                                      >
                                        <option value="">Select Option</option>
                                        {serviceName === 'LawnMowing'
                                          ? ["Update", "Pause", "Un-Pause", "Cancel"].map((id, index) => (
                                            <option key={index} value={id}>
                                              {id}
                                            </option>
                                          ))
                                          : ["Update", "Cancel"].map((id, index) => (
                                            <option key={index} value={id}>
                                              {id}
                                            </option>
                                          ))}
                                      </select>
                                    )}
                                  </>
                                )
                              )}
                            </div>
                          </div>
                        ))}
                      </CardContent>
                    </Paper>
                  </Grid>
                ))}
            </Grid>

            <Grid item xs={12} justifyContent="flex-start">
              {historyData.length > 0 &&
                !Services.includes("Un-Committed Subscriber") && (
                  <Paper className={classes.card}>
                    <CardContent style={{ textAlign: "left" }}>
                      <Typography
                        variant="h5"
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          // marginBottom: "0.3rem",
                        }}
                      >
                        History
                      </Typography>
                      {historyData.length > 0 ? (
                        <List style={{ marginLeft: 0 }}>
                          {historyData.map((item, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                secondary={`${index + 1}) Service: ${item.Service
                                  } | Date: ${item.Date} | Order ID: ${item.OrderId
                                  } | Pro ID: ${item.ProId} | Price: ${item.Price
                                  }`}
                                classes={{ secondary: classes.itemText }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        "No Service"
                      )}
                    </CardContent>
                  </Paper>
                )}
            </Grid>
          </>
        ) : (
          <div className={classes.loadingContainer}>
            <CircularProgress color="primary" />
            <div
              style={{
                fontSize: 20,
              }}
            >
              Please wait...
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerDetails;
