// Schedule.js    Created 8/8/2022    Rev 4.8

// Purpose:
// Pro Schedule page which reflects today's schedule to the pro user.
// Functions:
// 1. Start and stop button which records the start and stop time of the proUser
// 2. Show today's schedule

// Import all the packages
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../components/UserContext";
import { NavLink, useHistory } from "react-router-dom";
import {
  FormControl,
  Select,
  MenuItem,
  AppBar,
  Toolbar,
  Box,
  Tooltip,
} from "@material-ui/core";
import { stack as Menu } from "react-burger-menu";
import { onAuthStateChanged, signOut } from "firebase/auth";
import EnglishSchedule from "./Components/EnglishSchedule";

//import styling
import "./ProUser.css";
import useStyles from "../../New Home/Styles";

//import assets
import { authQA, dbQA } from "../../../components/Firebase";
import SpanishSchedule from "./Components/SpanishSchedule";
import { Language } from "@material-ui/icons";

function Schedule() {
  const [userQA, setUserQA] = useContext(UserContext);
  const [language, setLanguage] = React.useState(UserContext);
  const history = useHistory();

  const pageTitle = "Daily Schedule";

  useEffect(() => {
    // Select a language
    setLanguage(localStorage.getItem("language"));

    function checkUser() {
      localStorage.setItem("userQA", JSON.stringify(userQA));
      onAuthStateChanged(authQA, async (userCred) => {
        if (userCred) {
          setUserQA(userCred);
          return;
        } else {
          setUserQA(null);
          console.log("No user");
          history.push("/QAadminLogin");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Renders the particular on the current page.
  const PageDisplay = () => {
    if (language === "English") {
      localStorage.setItem("language", language);
      return <EnglishSchedule />;
    } else if (language === "Español") {
      localStorage.setItem("language", language);
      return <SpanishSchedule />;
    }
  };

  const handleChange = (event) => {
    // setChecked(event.target.checked);
    console.log(event.target.value, "lang");

    if (event.target.value === "English") {
      setLanguage("English");
    }
    if (event.target.value === "Español") {
      setLanguage("Español");
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        style={{ backgroundColor: "white", width: "100%", maxWidth: "100vw" }}
      >
        <Toolbar>
          <NavLink
            to="/QA"
            style={{ textDecoration: "none", backgroundColor: "transparent" }}
          >
            <img
              src={
                require("../../../components/assets/images/Logo_SVG_black.svg")
                  .default
              }
              className="logo"
              height="45vh"
              alt="Alltramatic logo"
            />
          </NavLink>

          <h3 style={{ paddingLeft: "30px", color: "black", width: "200px" }}>
            Pro Schedule
          </h3>

          <Box
            flexGrow={1}
            justifyContent="center"
            style={{ marginTop: "10px" }}
          ></Box>

          <div
            className="NavLinks"
            style={{ display: "flex", alignItems: "center" }}
          >
            {userQA ? (
              <>
                {/* Language Selector */}
                <Tooltip title="Choose a Language" placement="left-start">
                  <FormControl style={{ minWidth: "60px" }}>
                    <Select
                      value={language}
                      style={{ fontWeight: "bold" }}
                      className="appbarNavlink"
                      onChange={handleChange}
                      variant="standard"
                      disableUnderline
                    >
                      <MenuItem value="English">🇬🇧 En</MenuItem>
                      <MenuItem value="Español">🇪🇸 Es</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
                <NavLink to="/QAdashboard" style={{ textDecoration: "none" }}>
                  <div className="appbarNavlink">Dashboard</div>
                </NavLink>
                <NavLink to="/QAschedule" style={{ textDecoration: "none" }}>
                  <div className="appbarNavlink">Schedule</div>
                </NavLink>
                <NavLink to="/QAprofile" style={{ textDecoration: "none" }}>
                  <div className="appbarNavlink">Profile</div>
                </NavLink>
                <NavLink to="/QA" style={{ textDecoration: "none" }}>
                  <div
                    className="appbarNavlink"
                    onClick={() => {
                      signOut(authQA)
                        .then(() => {
                          // Sign-out successful.
                          console.log("signed out");
                          setUserQA(null);
                          localStorage.removeItem("userQA");
                        })
                        .catch((error) => {
                          // An error happened.
                          console.log(error);
                        });
                    }}
                  >
                    Logout
                  </div>
                </NavLink>
              </>
            ) : (
              <NavLink
                className="appbarNavlink"
                style={{ textDecoration: "none" }}
                to="/QAlogin"
              >
                Login
              </NavLink>
            )}
          </div>
        </Toolbar>

        <div>
          <Menu right>
            {userQA ? (
              <>
                <div className="bm-item">
                  <NavLink
                    to="/QAdashboard"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Dashboard
                  </NavLink>
                </div>
                <div className="bm-item">
                  <NavLink
                    to="/QAschedule"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Schedule
                  </NavLink>
                </div>

                <div className="bm-item">
                  <NavLink
                    to="/QAprofile"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Profile
                  </NavLink>
                </div>
                <NavLink
                  to="/QA"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  <div
                    onClick={() => {
                      signOut(authQA)
                        .then(() => {
                          // Sign-out successful.
                          console.log("signed out");
                          setUserQA(null);
                          localStorage.removeItem("userQA");
                        })
                        .catch((error) => {
                          // An error happened.
                          console.log(error);
                        });
                    }}
                  >
                    Log out
                  </div>
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  style={{ backgroundColor: "transparent", color: "black" }}
                  to="/QA"
                >
                  Home
                </NavLink>
                <NavLink
                  style={{ backgroundColor: "transparent", color: "black" }}
                  to="/QAlogin"
                >
                  Login
                </NavLink>
              </>
            )}
          </Menu>
        </div>
      </AppBar>
      {PageDisplay()}
    </>
  );
}

export default Schedule;
