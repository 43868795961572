import { AppBar, Button, Toolbar } from "@material-ui/core";
import { AddAlert, InfoRounded, Warning } from "@mui/icons-material";
import React from "react";

const NotFoundPage = () => (
  <div
    style={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "20px",
      fontWeight: "bold",
    }}
  >
    <Warning style={{ fontSize: "50px", color: "red", marginBottom: "1%" }} />
    404! You have reached the end of the rainbow.
    <Button
      style={{ marginTop: "3%", fontWeight: "bolder" }}
      color="primary"
      variant="contained"
      onClick={() => {
        window.location.href="/"
      }}
    >
      Return to homepage
    </Button>
  </div>
);

export default NotFoundPage;
