// ProSignup.js    Created 9/4/2022    Rev 4.8

// Purpose:
// To create a sign up page for pro user.
// This is a parent component and contains subcomponents (in the Component folder)

import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import { useHistory } from "react-router-dom";
import { v4 as unique_id } from "uuid";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../components/Firebase";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";

// Import Subcomponents from component folder:
import PersonalInfo from "./Components/PersonalInfo";
import Qualification from "./Components/Qualification";
import CompanyInformation from "./Components/CompanyInformation";

// Import Styling Files:
import { Stepper, Step, StepLabel, Button, Container } from "@material-ui/core";
import useStyles from "../New Home/Styles";
import "./Login.css";
import Preference from "./Components/Preference";
import Bank from "./Components/Bank";

function ProSignup() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const history = useHistory();
  const u_id = unique_id();
  const uniqID = u_id.slice(0, 8);

  // This is used to save all the details entered in the entire form. Note: Enter the same name in every subfile to reflect in this field.
  const [formData, setFormData] = useState({
    companyName: "",
    businessAdress: "",
    zipcode: "",
    phone: "",
    fName: "",
    lName: "",
    email: "",
    password: "",
    areaofWork: [],
    daysSelected: {},
    entityType: "",
    legalAddress: "",
    ssn: "",
    routingNumber: "",
    accountNumber: "",
  });

  // These are the main headings of the multiple step form. You can add n number of titles
  const FormTitles = [
    "Company Information",
    "Personal Information",
    "Work Schedule",
    "Bank Integration",
  ];

  const nextStep = () => {
    setPage((currPage) => currPage + 1);
  };

  const prevStep = () => {
    setPage((currPage) => currPage - 1);
  };

  // Renders the particular on the current page.
  const PageDisplay = () => {
      if (page === 0) {
      return (
        <CompanyInformation
          nextStep={nextStep}
          prevStep={prevStep}
          formData={formData}
          setFormData={setFormData}
        />
      );
    } else if (page === 1) {
      return (
        <PersonalInfo
          nextStep={nextStep}
          prevStep={prevStep}
          formData={formData}
          setFormData={setFormData}
        />
      );
    } else if (page === 2) {
      return (
        <Preference
          nextStep={nextStep}
          prevStep={prevStep}
          formData={formData}
          setFormData={setFormData}
        />
      );
    } else if (page === 3) {
      return (
        <Bank
          prevStep={prevStep}
          formData={formData}
          setFormData={setFormData}
        />
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className={classes.root2}>
        <div className={classes.component} align="center">
          <Container style={{ width: "100%" }}>
            <Stepper activeStep={page} className={classes.proSignupstepper}>
              {FormTitles.map((label, index) => (
                <Step key={label} completed={page[index]}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Container>
        </div>
        <div className="body">{PageDisplay()}</div>
        <div
          className="footer"
          style={{ paddingBottom: "1%", width: "100%", background: "white" }}
        >
          {page === FormTitles.length - 1 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className={classes.buttonPrimary}
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  marginTop: "1%",
                  minWidth: "180px",
                  borderRadius: "28px",
                  fontSize: "100%",
                  maxWidth: "400px",
                  backgroundColor:
                    !formData.accountNumber ||
                    !formData.entityType ||
                    !formData.legalAddress ||
                    !formData.routingNumber ||
                    !formData.ssn
                      ? "#D3D3D3"
                      : "#187CAE",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  // console.log(
                  //   "Company Name =>",
                  //   formData.companyName,
                  //   "Business Address =>",
                  //   formData.businessAdress,
                  //   "Phone => ",
                  //   formData.phone,
                  //   "Zipcode =>",
                  //   JSON.parse(localStorage.getItem("ZIPCODE")),
                  //   "First Name =>",
                  //   formData.fName,
                  //   "Last Name =>",
                  //   formData.lName,
                  //   "Email =>",
                  //   JSON.parse(localStorage.getItem("EMAIL")),
                  //   "Password",
                  //   formData.password,
                  //   "Days of work",
                  //   formData.daysSelected,
                  //   "Arfea of work => ",
                  //   formData.areaofWork,
                  //   "Entity =>",
                  //   formData.entityType,
                  //   "LAdd",
                  //   formData.legalAddress,
                  //   "SSN =>",
                  //   formData.ssn,
                  //   "Acct Route",
                  //   formData.routingNumber,
                  //   "Acct",
                  //   formData.accountNumber
                  // );

                  if (
                    !formData.accountNumber ||
                    !formData.entityType ||
                    !formData.legalAddress ||
                    !formData.routingNumber ||
                    !formData.ssn
                  ) {
                    alert("Fill all the details!");
                    return;
                  } else {
                    createUserWithEmailAndPassword(
                      auth,
                      JSON.parse(localStorage.getItem("EMAIL")),
                      formData.password
                    )
                      .then(async (userCredential) => {
                        const user = userCredential.user;
                        const uuid = user.uid;
                        const proCompanyRef = doc(db, "ProCompany", uuid);
                        setDoc(proCompanyRef, {
                          AreasOfWork: formData.areaofWork,
                          CompanyInfo: {
                            Address: formData.businessAdress,
                            Name: formData.companyName,
                            Phone: formData.phone,
                            Zipcode: JSON.parse(
                              localStorage.getItem("ZIPCODE")
                            ),
                          },
                          DaysOfWork: formData.daysSelected,
                          Email: JSON.parse(localStorage.getItem("EMAIL")),
                          ProID: uuid,
                          OwnerInfo: {
                            Email: JSON.parse(localStorage.getItem("EMAIL")),
                            FirstName: formData.fName,
                            LastName: formData.lName,
                            Phone: formData.phone,
                          },
                          CreationDate: serverTimestamp(),
                        }).catch((err) => {
                          alert(err);
                          return;
                        });

                        const proCompanyBankRef = doc(
                          db,
                          "ProCompany",
                          uuid,
                          "BankIntegration",
                          "BankDetails"
                        );
                        setDoc(proCompanyBankRef, {
                          SSN: formData.ssn,
                          CompanyType: formData.entityType,
                          AccountNumber: formData.accountNumber,
                          RoutingNumber: formData.routingNumber,
                          LegalAddress: formData.legalAddress,
                        }).catch((err) => {
                          alert(err);
                          return;
                        });
                        alert("Account Has Been Created!");
                      })
                      .catch((err) => {
                        alert(err);
                        return;
                      })
                      .finally(() => {
                        history.push("/proSchedule");
                        return;
                      });
                  }
                }}
              >
                Submit
              </Button>

              <Button
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  maxWidth: "400px",
                  marginTop: "1%",
                  border: "2px solid #ED9278",
                  backgroundColor: "white",
                  color: "#ED9278",
                  borderRadius: "28px",
                  fontSize: "100%",
                  padding: "5px 10px",
                  whiteSpace: "nowrap",
                  minWidth: "180px!important",
                  minHeight: "45px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  prevStep();
                }}
              >
                Previous
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default ProSignup;
