// Import all the packages
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../components/UserContext";
import { NavLink, useHistory } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Grid,
  CardContent,
  CardMedia,
  Switch,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import {
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardHeader,
  Avatar,
  Typography,
} from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { stack as Menu } from "react-burger-menu";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { authQA, db, dbQA } from "../../../components/Firebase";
import useStyles from "../../New Home/Styles";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { AttachMoney, Info, InfoSharp } from "@mui/icons-material";
import lawnMaintain from "../../../components/assets/images/LawnMaintainence.png";
import mulch from "../../../components/assets/images/Mulch.png";
import snowRemoval from "../../../components/assets/images/SnowRemoval.png";
import spring from "../../../components/assets/images/Spring.png";
import { Alert } from "@mui/material";
import QANavbar from "../../../components/QANavbar";

function Services() {
  const [userQA, setUserQA] = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const [services, setServices] = useState([]);
  const [serviceSelected, setServiceSelected] = useState();
  const [uuid, setUuid] = useState();
  const [seePrice, setSeePrice] = useState(false);

  const myRef = React.useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    function checkUser() {
      localStorage.setItem("userQA", JSON.stringify(userQA));
      onAuthStateChanged(authQA, async (userCred) => {
        if (userCred) {
          setUserQA(userCred);
          setUuid(userCred.uid);

          const proCompanyServiceOfferings = doc(
            dbQA,
            "ProCompany",
            userCred.uid,
            "ServiceOfferings",
            "Services"
          );

          // Check current Day Schedule
          const serviceData = await getDoc(proCompanyServiceOfferings);

          if (serviceData.exists()) {
            setServices(serviceData.data().Service);
            console.log("Data dailyService", serviceData.data());
          } else {
            console.log("Sorry no data");
            return;
          }
          return;
        } else {
          setUserQA(null);
          console.log("No user");
          history.push("/QAadminLogin");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAlertOpen = () => {
    setSeePrice(true);
  };

  const handleAlertClose = () => {
    setSeePrice(false);
  };

  return (
    <>
      <QANavbar pageTitle="Service Offerings" />

      {/* The Left Container */}
      <Grid
        container
        sm={12}
        xs={12}
        md={12}
        lg={12}
        xl={12}
        spacing={0}
        justify="space-around"
        style={{ width: "100%" }}
      >
        <Grid item sm={12} xs={12} md={8} lg={8}>
          <h6 className={classes.mainHeading} style={{ paddingLeft: "2%" }}>
            {new Date()
              .toLocaleString("default", { weekday: "long" })
              .slice(0, 3)}
            , {Date().toLocaleString("en-EN", { month: "long" }).slice(4, 10)}
          </h6>

          <p
            className={classes.p}
            style={{ marginBottom: "2%", marginTop: "10px", margin: "10px" }}
          >
            Update the services you want to offer and see average pay and
            details
          </p>

          <TableContainer
            component={Paper}
            className={classes.tableContainer}
            style={{ height: "100%", marginBottom: "20px" }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650, maxHeight: 440 }}
              aria-label="simple table"
              size="small"
              style={{ paddingBottom: "20px" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCell} align="left">
                    Service
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell} align="center">
                    Offerings
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    align="center"
                    style={{
                      width: "50px",
                    }}
                  >
                    Pay
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {services?.map((payload, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: "none" },
                    }}
                  >
                    <TableCell
                      className={classes.name}
                      align="left"
                      style={{
                        width: "100%",
                        maxLines: "2",
                        lineHeight: "16px",
                        maxHeight: "32px",
                      }}
                    >
                      <Typography
                        style={{ color: "black", textTransform: "capitalize" }}
                      >
                        {index + 1}. {payload.ServiceName}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <FormControl
                        sx={{ m: 1, minWidth: 120 }}
                        size="small"
                        variant="outlined"
                        color="primary"
                      >
                        <Select
                          defaultValue={payload.Status}
                          value={serviceSelected}
                          // onChange={handleChange}
                          inputProps={{ "aria-label": "Without label" }}
                          onChange={(event) => {
                            setServiceSelected(event.target.value);
                            services[index].Status = event.target.value;

                            const proCompanyServiceOfferings = doc(
                              dbQA,
                              "ProCompany",
                              uuid,
                              "ServiceOfferings",
                              "Services"
                            );

                            updateDoc(
                              proCompanyServiceOfferings,
                              {
                                Service: services,
                              },
                              { merge: true }
                            ).catch((err) => alert(err));
                          }}
                        >
                          <MenuItem value="Enabled">Enabled</MenuItem>
                          <MenuItem value="Disabled">Disabled</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        width: "50px",
                      }}
                    >
                      $50
                      <Button
                        color="primary"
                        size="small"
                        style={{
                          fontSize: "12px",
                          textTransform: "capitalize",
                          padding: "0",
                          textDecoration: "underline",
                        }}
                        onClick={handleAlertOpen}
                      >
                        See Price
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Box flexGrow={1} />

        {/* The Right Container */}
        <Grid
          xs={8}
          md="auto"
          lg="auto"
          xl="auto"
          className={classes.rightGrid}
          style={{ height: "100%" }}
        >
          <h6
            style={{
              fontWeight: "bolder",
              color: "white",
              fontSize: "2vh",
            }}
          >
            Information
          </h6>

          {/* 1. Lawn maintanence */}
          <Card
            ref={myRef}
            style={{
              display: "flex",
              width: "100%",
              maxWidth: "400px",
              marginTop: "5%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent style={{ flex: "1 0 auto" }}>
                <Typography
                  style={{
                    color: "black",
                    fontSize: "1.8vh",
                    textAlign: "justify",
                    lineHeight: "18px",
                  }}
                >
                  1. Lawn Maintenance payout varies by the size of the lawn and
                  additional surchanges for lawns with special conditions.
                </Typography>
                <p
                  className={classes.p}
                  style={{
                    marginTop: "8px",
                    textAlign: "justify",
                    fontSize: "1.8vh",
                  }}
                >
                  A typical 40x100 ft property payout is $38.00
                </p>
              </CardContent>
            </Box>
            <CardMedia
              component="img"
              src={lawnMaintain}
              style={{ width: 120, objectFit: "fill" }}
            />
          </Card>

          {/* 2. Spring */}
          <Card
            style={{
              display: "flex",
              width: "100%",
              maxWidth: "400px",
              marginTop: "5%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography
                  style={{
                    color: "black",
                    fontSize: "1.8vh",
                    textAlign: "justify",
                  }}
                >
                  2. Spring and Fall payout vary by the size of the lawn and
                  additional surchanges for lawns with special conditions
                </Typography>
                <p
                  className={classes.p}
                  style={{
                    marginTop: "8px",
                    textAlign: "justify",
                    fontSize: "1.8vh",
                  }}
                >
                  A typical 40x100 ft proerty payout is $200
                </p>
              </CardContent>
            </Box>
            <CardMedia
              component="img"
              src={spring}
              style={{ width: 120, objectFit: "fill" }}
            />
          </Card>

          {/* 3. Snow */}
          <Card
            style={{
              display: "flex",
              width: "100%",
              maxWidth: "400px",
              marginTop: "5%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography
                  style={{
                    color: "black",
                    fontSize: "1.8vh",
                    textAlign: "justify",
                  }}
                >
                  3. Snow removal payout varies by the size of the driveway and
                  pathways. Additional surchanges for special conditions.
                </Typography>
                <p
                  className={classes.p}
                  style={{
                    marginTop: "8px",
                    textAlign: "justify",
                    fontSize: "1.8vh",
                  }}
                >
                  A typical 2-car driveway payout is $150. A 1-car driveway
                  payout is $150. Add-on 40ft Sidewalk is $60. Add-on 20ft
                  pathway $50. Add-on 1 flight of stairs is $50.
                </p>
              </CardContent>
            </Box>
            <CardMedia
              component="img"
              src={snowRemoval}
              style={{ width: 120, objectFit: "fill" }}
            />
          </Card>

          {/* 4. Ground Mulching */}
          <Card
            style={{
              display: "flex",
              width: "100%",
              maxWidth: "400px",
              marginTop: "5%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography
                  style={{
                    color: "black",
                    fontSize: "1.8vh",
                    textAlign: "justify",
                  }}
                >
                  4. Ground Mulching payout varies by the size of the area and
                  types of mulch. Additional surchanges for lawns with special
                  conditions.
                </Typography>
                <p
                  className={classes.p}
                  style={{
                    marginTop: "8px",
                    textAlign: "justify",
                    fontSize: "1.8vh",
                  }}
                >
                  A typical 40x100 area with black mulch payout is $300.
                </p>
              </CardContent>
            </Box>
            <CardMedia
              component="img"
              src={mulch}
              style={{ width: 120, objectFit: "fill" }}
            />
          </Card>
        </Grid>
      </Grid>

      {/* Dialog Box for See prices */}
      <Dialog
        style={{ padding: "0px", margin: "0px" }}
        open={seePrice}
        fullWidth
        onClose={handleAlertClose}
      >
        <div className={classes.card} style={{ alignItems: "center" }}>
          <Alert
            severity="warning"
            role="button"
            icon={<InfoSharp />}
            // onClose={handleAlertClose}
            closeText="Doesn't Work!"
            sx={{
              "& .MuiAlert-icon": {
                color: "white",
                fontSize: "40px",
                backgroundImage: "linear-gradient(225deg, #1d2122, #181c1d)",
                boxShadow: "-5px 5px 15px #000000,10px -10px 40px #FFFFFF",
                padding: "4%",
                borderRadius: "50%",
              },
              backgroundColor: "transparent",
            }}
          >
            <h2 style={{ color: "white", margin: "10px" }}>Prices</h2>
          </Alert>
          <DialogContent style={{ color: "white" }}>
            <Paper
              elevation={true}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "2%",
              }}
            >
              <Typography>1 car driveway</Typography>
              <Typography style={{ fontWeight: "bold" }}>$120</Typography>
            </Paper>

            <Paper
              elevation={true}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "2%",
                marginTop: "2%",
              }}
            >
              <Typography>2 car driveway</Typography>
              <Typography style={{ fontWeight: "bold" }}>$150</Typography>
            </Paper>

            <Paper
              elevation={true}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "2%",
                marginTop: "2%",
              }}
            >
              <Typography>Circular driveway</Typography>
              <Typography style={{ fontWeight: "bold" }}>$250</Typography>
            </Paper>

            <Typography style={{ fontWeight: "bold", marginTop: "3%" }}>
              Add-On
            </Typography>

            <Paper
              elevation={true}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "2%",
                marginTop: "2%",
              }}
            >
              <Typography>Short Sidewalk</Typography>
              <Typography style={{ fontWeight: "bold" }}>$15</Typography>
            </Paper>

            <Paper
              elevation={true}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "2%",
                marginTop: "2%",
              }}
            >
              <Typography>Corner Sidewalk</Typography>
              <Typography style={{ fontWeight: "bold" }}>$50</Typography>
            </Paper>

            <Paper
              elevation={true}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "2%",
                marginTop: "2%",
              }}
            >
              <Typography>50ft pathway</Typography>
              <Typography style={{ fontWeight: "bold" }}>$15</Typography>
            </Paper>

            <Paper
              elevation={true}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "2%",
                marginTop: "2%",
              }}
            >
              <Typography>100ft pathway</Typography>
              <Typography style={{ fontWeight: "bold" }}>$15</Typography>
            </Paper>

            <Paper
              elevation={true}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "2%",
                marginTop: "2%",
              }}
            >
              <Typography>1 fly of stairs</Typography>
              <Typography style={{ fontWeight: "bold" }}>$25</Typography>
            </Paper>

            <Paper
              elevation={true}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "2%",
                marginTop: "2%",
              }}
            >
              <Typography>Slope greater than 15 degree</Typography>
              <Typography style={{ fontWeight: "bold" }}>$5</Typography>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              style={{
                color: "white",
                fontWeight: "bold",
                padding: "0%",
                border: "2px solid white",
              }}
              onClick={handleAlertClose}
              autoFocus
            >
              Okay
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}

export default Services;
