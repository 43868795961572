// App.js
// Purpose: This is the file for App Component.
// App Component is the main component in React which acts as a container for all other components.
// Routes to the other pages are also included
//include imports here; Sort according to name;

/// ----------------- Imports ------------------------------///
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { auth, authQA } from "./components/Firebase";
import { UserContext } from "./components/UserContext";
import { onAuthStateChanged } from "firebase/auth";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import AdminLogin from "./pages/QA/Admin/AdminLogin";
import AdminPage from "./pages/QA/Admin/AdminDashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.css";
import { useHistory } from "react-router-dom";

/// ------------ PROD PAGE IMPORTS --------------------///
import LandingPage from "./pages/PROD/Landing Page/LandingPage";
import Login from "./pages/PROD/Login/Login";
import ProProfile from "./pages/PROD/Profile/ProProfile";
import Dashboard from "./pages/PROD/Dashboard/Dashboard";
import Schedule from "./pages/PROD/Schedule/Schedule";

import ProSignup from "./pages/Login/ProSignup";

import Eula from "./pages/Login/Components/Eula";
import LawnCareAgreement from "./pages/Login/Components/LawnCareAgreement";

/// ------------ QA PAGE IMPORTS --------------------///
import Dashboard_QA from "./pages/QA/Dashboard/Dashboard";
import LandingPage_QA from "./pages/QA/Landing Page/LandingPage";
import Login_QA from "./pages/QA/Login/Login";
import ProProfile_QA from "./pages/QA/Profile/ProProfile";
import Schedule_QA from "./pages/QA/Schedule/Schedule";
import ProSignup_QA from "./pages/QA/Login/ProSignup";
import Lawnber from "./pages/QA/Lawnber/Lawnber";
import Customers_QA from "./pages/QA/Customers/Customers";
import Services_QA from "./pages/QA/Services/Services";
import NotFoundPage from "./components/NotFoundPage";
import Notification from "./pages/QA/Notification/Notification";
import LawnberDashboard from "./pages/QA/Lawnber/Dashboard/LawnberDashboard";
import CustomerDetails from "./pages/QA/Admin/CustomerDetails";
import LawnSchedule from "./pages/QA/Admin/LawnSchedule";
import JobComplete from "./pages/QA/Admin/JobComplete";
import AdminProSignup from "./pages/QA/Admin/AdminProSignup";
import ProDetails from "./pages/QA/Admin/ProDetails";
import Customers from "./pages/PROD/Customers/Customers";
import BlogCreate from "./pages/QA/Admin/BlogCreate";

function App() {
  const [user, setUser] = useState(null);
  const value = useMemo(() => [user, setUser], [user, setUser]);
  const [userQA, setUserQA] = useState(null);
  const valueQA = useMemo(() => [userQA, setUserQA], [userQA, setUserQA]);
  const [language, setLanguage] = useState("English");
  const lang = useMemo(() => [language, setLanguage], [language, setLanguage]);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const history = useHistory();
  const [subdomain, setSubdomain] = useState("");

  useEffect(() => {
    console.log(window.location.hostname.split(".")[0]);
    setSubdomain(window.location.hostname.split(".")[0]);
  }, []);

  useEffect(() => {
    function checkUser() {
      localStorage.setItem("language", language);
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUser(user);
          userHasAuthenticated(true);
          return;
        } else {
          setUser(null);
          console.log("No user");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    function checkUserQA() {
      localStorage.setItem("userQA", JSON.stringify(userQA));
      onAuthStateChanged(authQA, async (userQA) => {
        if (userQA) {
          setUserQA(userQA);
          return;
        } else {
          setUserQA(null);
          console.log("No userQA");
          return;
        }
      });
    }
    checkUserQA();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const theme = createTheme({
    palette: {
      textColor: "#000000",
      primary: {
        main: "#187CAE",
      },
      secondary: {
        main: "#187CAE",
      },
    },
  });

  return (
    <>
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <UserContext.Provider
                isAuthenticated={isAuthenticated}
                value={value}
                valueQA={valueQA}
                lang={lang}
              >
                {/* PRODUCTION ROUTES  */}
                {/* <Route exact path="/" component={LandingPage} user={user} /> */}
                <Route exact path="/login" component={Login} />
                <Route exact path="/EULA" component={Eula} />
                <Route exact path="/customer" component={Customers} />
                <Route
                  exact
                  path="/lawn-care-services-agreement"
                  component={LawnCareAgreement}
                />
                <Route
                  exact
                  path="/proschedule"
                  component={Schedule}
                  user={user}
                  appProps={{ isAuthenticated }}
                />

                <Route
                  exact
                  path="/"
                  render={() =>
                    subdomain === "admin" ? <AdminLogin /> : <LandingPage />
                  }
                  user={user}
                  appProps={{ isAuthenticated }}
                />

                {subdomain === "admin" && (
                  <Route exact path="/Dashboard" component={AdminPage} />
                )}

                {subdomain === "admin" && (
                  <Route exact path="/LawnSchedule" component={LawnSchedule} />
                )}

                {subdomain === "admin" && (
                  <Route exact path="/JobComplete" component={JobComplete} />
                )}

                {subdomain === "admin" && (
                  <Route
                    exact
                    path="/customer/:uid"
                    component={CustomerDetails}
                  />
                )}

                {subdomain === "admin" && (
                  <Route
                    exact
                    path="/AdminProSignup"
                    component={AdminProSignup}
                  />
                )}

                {subdomain === "admin" && (
                  <Route exact path="/pro/:uid" component={ProDetails} />
                )}

                {subdomain === "admin" && (
                  <Route exact path="/BlogCreate" component={BlogCreate} />
                )}

                <Route
                  exact
                  path="/profile"
                  component={ProProfile}
                  user={user}
                  appProps={{ isAuthenticated }}
                />
                <Route
                  exact
                  path="/prodashboard"
                  component={Dashboard}
                  user={user}
                  appProps={{ isAuthenticated }}
                />
                <Route exact path="/prosignup" component={ProSignup} />

                {/* QA ROUTES */}

                <Route
                  exact
                  path="/lawnber"
                  component={Lawnber}
                  userQA={userQA}
                />
                <Route
                  exact
                  path="/QA"
                  component={LandingPage_QA}
                  userQA={userQA}
                />
                <Route
                  exact
                  path="/QAdashboard"
                  component={Dashboard_QA}
                  userQA={userQA}
                  language={language}
                />
                <Route
                  exact
                  path="/QAlogin"
                  component={Login_QA}
                  userQA={userQA}
                />
                <Route
                  exact
                  path="/QAprofile"
                  component={ProProfile_QA}
                  userQA={userQA}
                />
                <Route
                  exact
                  path="/QAschedule"
                  component={Schedule_QA}
                  userQA={userQA}
                  language={language}
                />
                <Route
                  exact
                  path="/QAsignup"
                  component={ProSignup_QA}
                  userQA={userQA}
                />
                <Route
                  exact
                  path="/QAcustomer"
                  component={Customers_QA}
                  userQA={userQA}
                />
                <Route
                  exact
                  path="/QAservice"
                  component={Services_QA}
                  userQA={userQA}
                />
                <Route
                  exact
                  path="/QAnotification"
                  component={Notification}
                  userQA={userQA}
                />
                <Route
                  exact
                  path="/QAlawnberDashboard"
                  component={LawnberDashboard}
                  userQA={userQA}
                />
              </UserContext.Provider>
              <Route component={NotFoundPage} />
            </Switch>
          </BrowserRouter>
        </MuiThemeProvider>
      </div>
    </>
  );
}
export default App;
