import React from "react";
import { stack as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { UserContext } from "./UserContext";
import { AppBar, Box, Toolbar } from "@material-ui/core";
import { authQA } from "./Firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";

function Navbar({ pageTitle }) {
  const [userQA, setUserQA] = React.useContext(UserContext);

  React.useEffect(() => {
    function checkUser() {
      localStorage.setItem("userQA", JSON.stringify(userQA));
      onAuthStateChanged(authQA, async (user) => {
        if (user) {
          setUserQA(user);
        } else {
          setUserQA(null);
          console.log("No user");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppBar
      position="fixed"
      style={{ backgroundColor: "white", width: "100%", maxWidth: "100vw" }}
    >
      <Toolbar>
        <NavLink
          to="/QA"
          style={{ textDecoration: "none", backgroundColor: "transparent" }}
        >
          <img
            src={require("./assets/images/Logo_SVG_black.svg").default}
            className="logo"
            height="45vh"
            alt="Alltramatic logo"
          />
        </NavLink>

        {pageTitle ? (
          <h3 style={{ paddingLeft: "3%", color: "black" }}>{pageTitle}</h3>
        ) : (
          <h3 style={{ paddingLeft: "3%", color: "black" }}>Pro Dashboard</h3>
        )}

        <Box
          flexGrow={1}
          justifyContent="center"
          style={{ marginTop: "10px" }}
        ></Box>

        <div
          className="NavLinks"
          style={{ display: "flex", alignItems: "center" }}
        >
          {userQA ? (
            <>
              <NavLink to="/QAdashboard" style={{ textDecoration: "none" }}>
                <div className="appbarNavlink">Dashboard</div>
              </NavLink>
              <NavLink to="/QAschedule" style={{ textDecoration: "none" }}>
                <div className="appbarNavlink">Schedule</div>
              </NavLink>
              <NavLink to="/QAservice" style={{ textDecoration: "none" }}>
                <div className="appbarNavlink">Services</div>
              </NavLink>
              <NavLink to="/QAcustomer" style={{ textDecoration: "none" }}>
                <div className="appbarNavlink">Customers</div>
              </NavLink>

              <NavLink to="/QAprofile" style={{ textDecoration: "none" }}>
                <div className="appbarNavlink">Profile</div>
              </NavLink>
              <NavLink to="/QA" style={{ textDecoration: "none" }}>
                <div
                  className="appbarNavlink"
                  onClick={() => {
                    signOut(authQA)
                      .then(() => {
                        // Sign-out successful.
                        console.log("signed out");
                        setUserQA(null);
                        localStorage.removeItem("userQA");
                      })
                      .catch((error) => {
                        // An error happened.
                        console.log(error);
                      });
                  }}
                >
                  Logout
                </div>
              </NavLink>
            </>
          ) : (
            <NavLink
              className="appbarNavlink"
              style={{ textDecoration: "none" }}
              to="/QAlogin"
            >
              Login
            </NavLink>
          )}
        </div>
      </Toolbar>

      <div style={{ backgroundColor: "white" }}>
        <Menu right>
          <NavLink
            style={{ backgroundColor: "transparent", color: "black" }}
            to="/QA"
          >
            Home
          </NavLink>

          {userQA ? (
            <>
              <div className="bm-item">
                <NavLink
                  to="/QAdashboard"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  Dashboard
                </NavLink>
              </div>

              <div>
                <NavLink
                  to="/QAservice"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="bm-item">Services</div>
                </NavLink>
              </div>

              <div>
                <NavLink
                  to="/QAschedule"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  <div className="bm-item">Schedule</div>
                </NavLink>
              </div>

              <div>
                <NavLink
                  to="/QAcustomer"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  <div className="bm-item">Customers</div>
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/QAprofile"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  <div className="bm-item">Profile</div>
                </NavLink>
              </div>
              <NavLink
                to="/QA"
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                  textDecoration: "none",
                }}
              >
                <div
                className="bm-item"
                  onClick={() => {
                    signOut(authQA)
                      .then(() => {
                        // Sign-out successful.
                        console.log("signed out");
                        setUserQA(null);
                        localStorage.removeItem("userQA");
                      })
                      .catch((error) => {
                        // An error happened.
                        console.log(error);
                      });
                  }}
                >
                  Log out
                </div>
              </NavLink>
            </>
          ) : (
            <NavLink
              style={{ backgroundColor: "transparent", color: "black" }}
              to="/QAlogin"
            >
              Login
            </NavLink>
          )}
        </Menu>
      </div>
    </AppBar>
  );
}

export default Navbar;
