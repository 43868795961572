// EULA.js    Created 9/4/2022    Rev 4.8

// Purpose:
// This is a subcomponent for Prosignup page.
//  This code provides UI for EULA agreement.

import React from "react";
import Navbar from "../../../components/Navbar";
import useStyles from "../../New Home/Styles";

function Eula() {
  const classes = useStyles();
  return (
    <>
      <Navbar />
      <div className={classes.root2}>
        <h2 className={classes.h2} style={{ marginTop: "2vh" }}>
          End User License Agreement
        </h2>
        <p
          className={classes.p}
          style={{ textAlign: "justify", width: "97%", marginTop: "20px" }}
        >
          BY CLICKING THE “I ACCEPT” BUTTON OR OTHERWISE ACCEPTING THIS PLATFORM
          END USER LICENSE AGREEMENT (THE “AGREEMENT”), YOU AGREE TO FOLLOW AND
          BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU ARE
          ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL
          ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY
          TO THE TERMS AND CONDITIONS OF THIS AGREEMENT AND, IN SUCH EVENT,
          “YOU” AND “YOUR” AS USED IN THIS AGREEMENT WILL REFER TO SUCH ENTITY,
          IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE TO ALL THE
          TERMS AND CONDITIONS IN THIS AGREEMENT, YOU MUST SELECT THE “I
          DECLINE” BUTTON AND MAY NOT USE THE PLATFORM.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          1. Agreement Definitions.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          “You” and “your” refers to the individual or entity that desires to
          access and use the software as a service from Alltramatic, Inc.’s
          (“Alltramatic”) software and platform (“Platform”). The term “program
          documentation” refers to the program user manual as well as any other
          materials provided or made available by Alltramatic as part of the
          Platform. The term “Alltramatic programs” refers to the software
          products owned or distributed by Alltramatic to which Alltramatic
          grants you access as part of the Platform, including program
          documentation, and any program updates provided as part of the
          Platform. The term “users” will mean those individuals authorized by
          you or on your behalf to use the Platform. The term “your data” refers
          to the data provided by you that resides on the Platform and expressly
          excludes any data, including customer data, provided or otherwise made
          available to you by Alltramatic.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          2. Rights Granted.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          Subject to the terms and conditions of this
          Agreement, Alltramatic hereby grants you a nonexclusive,
          non-assignable, royalty free, limited right to access and
          use the Platform solely for your internal business operations. You may
          allow your users to access and use the Platform for this purpose and
          you are responsible for your users’ compliance with this Agreement. 
          Upon termination of this Agreement,
          your right to access or use the Platform and any Alltramatic programs
          will terminate.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          3. Fees
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          Alltramatic may charge a fee for your access to and use of the
          Platform. If Alltramatic charges a fee for your
          access to and use of the Platform, you hereby
          agree to pay the fee on the terms set forth by Alltramatic or
          Alltramatic may terminate this Agreement and your access to and use of
          the Platform. This termination may result in the forfeiture and
          destruction of all of your data, subject to Section 7.3.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          4. Ownership and Restrictions.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          4.1
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          You retain all ownership and intellectual property rights in and to
          your data. Alltramatic or its licensors retain all ownership and
          intellectual property rights to the Platform and any data, including
          customer data, that it provides or otherwise makes available to you on
          the Platform. Alltramatic retains all ownership and intellectual
          property rights to anything developed and delivered under this
          Agreement.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          4.2
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          Third party technology that may be appropriate or necessary for use
          with some Alltramatic programs is specified in the program
          documentation or on the Platform, as applicable. Your right to use
          such third party technology is governed by the terms of the third
          party technology license agreement specified by Alltramatic and not
          under this Agreement.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          4.3
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          You may not: remove or modify any program markings or any notice of
          Alltramatic’s or its licensors’ proprietary rights; make the
          Alltramatic programs, data, or materials resulting from the Platform
          available in any manner to any third party for use in the third
          party’s business operations; modify, make derivative works of,
          disassemble, reverse compile, or reverse engineer any part of the
          Platform (the foregoing prohibition includes but is not limited to
          review of data structures or similar materials produced by programs),
          or access or use the Platform in order to build or support, and/or
          assist a third party in building or supporting, products or services
          competitive to Alltramatic; license, sell, rent, lease, transfer,
          assign, distribute, display, host, outsource, disclose, permit
          timesharing or service bureau use, or otherwise commercially exploit
          or make the Platform, Alltramatic programs or materials available, to
          any third party other than, as expressly permitted under the terms of
          this Agreement; except as expressly provided herein, copy, reproduce,
          distribute, republish, download, display, post or transmit in any form
          or by any means, including but not limited to electronic, mechanical,
          photocopying, recording, or other means the Platform; and allow
          unauthorized third parties from accessing the Platform.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          4.4
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          Payment processing services for providers on Alltramatic are provided
          by Stripe and are subject to the Stripe Connected Account Agreement ,
          which includes the Stripe Terms of Service (collectively, the “Stripe
          Services Agreement”). By agreeing to this Agreement or continuing to
          operate as a provider on Alltramatic, you agree to be bound by the
          Stripe Services Agreement, as the same may be modified by Stripe from
          time to time. As a condition of Alltramatic enabling payment
          processing services through Stripe, you agree to provide Alltramatic
          accurate and complete information about you and your business, and you
          authorize Alltramatic to share it and transaction information related
          to your use of the payment processing services provided by Stripe.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          5. Warranties, Disclaimers and Exclusive Remedies.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          5.1
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          Alltramatic warrants that the Platform will perform in all material
          respects in accordance with the program documentation. If the Platform
          in any given month during the Term do not perform as warranted, you
          must provide written notice to Alltramatic no later than five business
          days after the last day of the applicable month.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          5.2
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          Alltramatic DOES NOT GUARANTEE THAT THE PLATFORM WILL BE ERROR-FREE OR
          UNINTERRUPTED, OR THAT Alltramatic WILL CORRECT ALL ERRORS. YOU
          ACKNOWLEDGE THAT Alltramatic DOES NOT CONTROL THE TRANSFER OF DATA
          OVER COMMUNICATIONS FACILITIES, INCLUDING THE INTERNET, AND THAT THE
          SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS
          INHERENT IN THE USE OF SUCH COMMUNICATIONS FACILITIES. Alltramatic IS
          NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE
          RESULTING FROM THESE PROBLEMS.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          5.3
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          Should an element of the Platform become, or be likely to become, in
          Alltramatic’s opinion, the subject of infringement of a third party
          intellectual property right, Alltramatic will (a) procure for you the
          right to continue using the same, or (b) replace or modify it to make
          it non-infringing, provided that the replacement or modification
          performs the same functions and matches or exceeds the performance and
          functionality of the Platform. In the event that Alltramatic
          reasonably determines that neither (a) nor (b) above is commercially
          practicable, Alltramatic may terminate this Agreement.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          5.4
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          WITH THE EXCEPTION OF THOSE EXPRESS WARRANTIES MADE IN THIS SECTION,
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, Alltramatic DISCLAIMS ALL
          WARRANTIES WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT
          LIMITED TO WARRANTIES OF TITLE, NON-INFRINGEMENT, AND THE IMPLIED
          WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          6. Indemnification.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          6.1
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          Each party (“Indemnifying Party”) hereby releases and will defend,
          hold harmless, the other party and its subsidiaries, affiliates,
          directors, officers, employees, agents, successors and assigns
          (collectively “Indemnified Parties”), from any third party allegation
          or claim and against any loss, damage, settlement, cost, expense and
          any other liability (including reasonable attorneys’ fees incurred
          and/or those necessary to successfully establish the right to
          indemnification) (“collectively “Claims”), to the extent arising from
          or related to the Indemnifying Party’s breach of its representations
          or warranties under this Agreement, gross negligence, strict liability
          or willful misconduct. Indemnifying Party’s duty to defend is
          independent of its duty to indemnify.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          6.2
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          The Indemnified Party will give the Indemnifying Party prompt written
          notice of any claim subject to indemnification. The Indemnifying Party
          will use counsel reasonably satisfactory to the Indemnified Party to
          defend each Claim, and the Indemnified Party will cooperate (at the
          Indemnifying Party’s expense) with the Indemnifying Party in the
          defense. The Indemnifying Party will not consent to the entry of any
          judgment or enter into any settlement without the Indemnified Party’s
          prior written consent, which may not be unreasonably withheld. At its
          discretion and expense, the Indemnified Party may participate in the
          defense, any appeals, and settlement with counsel of its own choosing.
          If at any time the Indemnified Party reasonably determines that the
          Indemnifying Party is not effectively and diligently defending any
          Claim, the Indemnified Party may take control of the defense of the
          Claim at the Indemnifying Party’s expense (without limiting the
          Indemnifying Party’s indemnification obligations).
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          7. Term and Termination.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          7.1
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          This Agreement will commence as of your date of acceptance and will
          continue until terminated in accordance with termination provisions
          herein (“Term”).
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          7.2
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          Alltramatic may terminate your access to and use of all or any part of
          the Platform at any time, with or without cause, with or without
          notice, effective immediately, which may result in the forfeiture and
          destruction of all of your data. If you wish to terminate your access
          to and use of the Platform, you may do so by contacting us or through
          the account settings.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          7.3
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          At your request, and for a period of up to 30 days after the
          termination of this Agreement, Alltramatic may permit you to access
          the Platform solely to the extent necessary for you to retrieve a file
          of your data then in the Platform environment. You acknowledge agree
          that Alltramatic has no obligation to retain your data and that your
          data may be irretrievably deleted after 30 days following termination
          of this Agreement.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          7.4
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          You agree that you have not relied on the future availability of any
          Platform, programs or updates in entering into this Agreement.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          8. Confidential Information.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          8.1
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          By virtue of this Agreement, the parties may have access to
          information that is confidential to one another (“Confidential
          Information”). We each agree to disclose only information that is
          required for the performance of obligations under the agreement.
          Confidential Information will be limited to the terms of this
          Agreement, your data residing in the Platform environment, and all
          information clearly identified as confidential at the time of
          disclosure.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          8.2
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          A party’s Confidential Information will not include information that:
          (a) is or becomes a part of the public domain through no act or
          omission of the other party; (b) was in the other party’s lawful
          possession prior to the disclosure and had not been obtained by the
          other party either directly or indirectly from the disclosing party;
          (c) is lawfully disclosed to the other party by a third party without
          restriction on the disclosure; or (d) is independently developed by
          the other party without use of or reference to the other party’s
          Confidential Information.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          8.3
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          We each agree to hold each other’s Confidential Information in
          confidence for a period of one year from the date of disclosure.
          Alltramatic will protect the confidentiality of your data
          residing in the Platform environment in accordance with the
          Alltramatic security practices specified in the [Alltramatic Privacy
          Policy], which is incorporated herein by reference. Nothing will
          prevent either party from disclosing the other party’s Confidential
          Information in connection with any legal proceeding arising from or in
          connection with this Agreement or from disclosing the Confidential
          Information to a governmental entity as required by law. You agree
          that Alltramatic will communicate with you by email, telephone, push
          notification, and SMS or text message.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          9. Limitation of Liability.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          NEITHER PARTY WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          PUNITIVE, OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OF REVENUE OR PROFITS
          (EXCLUDING FEES UNDER THE AGREEMENT), DATA, OR DATA USE. Alltramatic’S
          MAXIMUM LIABILITY FOR ANY DAMAGES ARISING OUT OF OR RELATED TO THIS
          AGREEMENT, WHETHER IN CONTRACT OR TORT, OR OTHERWISE, WILL IN NO EVENT
          EXCEED, IN THE AGGREGATE, THE GREATER OF (I) THE TOTAL AMOUNTS
          ACTUALLY PAID TO YOU BY Alltramatic IN THE TWELVE (12) MONTH PERIOD
          IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM, OR (II)
          $100.00.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          10. Your Data.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          10.1
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          With respect to the collection, use and disclosure of your data,
          Alltramatic will comply with the Alltramatic Privacy Policy. You agree
          to provide any notices and obtain any consents related to your access
          or use of the Platform and Alltramatic’s provision of the Platform,
          including those related to the collection, use, processing, transfer
          and disclosure of personal information. You will have sole
          responsibility for the accuracy, quality, integrity, legality,
          reliability, appropriateness and ownership of all of your data.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          10.2
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          You hereby grant to Alltramatic nonexclusive, perpetual,
          royalty-free, paid-up license to use, copy, modify and make derivative
          works of your data for Alltramatic’s internal business purposes.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          11. Restrictions on Use of the Platform.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          You agree not to use or permit use of the Platform, including by
          emailing and posting any material that may (a) harass any person or
          cause damage or injury to any person or property, (b) involve the
          publication of any material that is defamatory, harassing or
          obscene, (c) promote bigotry, racism, hatred
          or harm, Alltramatic will have no liability to you
          in the event that Alltramatic terminates this Agreement. You agree to defend
          and indemnify Alltramatic against any claim arising out of a violation
          of your obligations under this section.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          12. Customer Reference.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          You agree (a) that Alltramatic may identify you as a user of the
          Platform and use your logo in sales presentations, marketing materials
          and press releases, and (b) to develop a brief customer profile for
          use by Alltramatic on Alltramatic.com for promotional purposes.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          13. General.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          13.1 Non-Solicitation.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          During the Term and for a period of one year thereafter, each party
          agrees that it will not: (a) directly or indirectly induce any
          customers or clients of the other party to patronize that party or any
          similar business; (b) directly or indirectly request or advise any
          customer or client of the other party to withdraw, curtail, or cancel
          that customer's or client's business with the other party; or (c) make
          any statement disparaging the other party, any member, principal,
          officer, director, shareholder, employee or agent thereof, to any
          person, firm, corporation or other business organization whatsoever.
          Nothing herein will prohibit either party from performing services for
          or providing products to customers or clients who (x) respond to
          general advertising by a party, (y) voluntarily withdraw or cancel
          their business with a party, or (z) contact a party directly.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          13.2 Relationship of the Parties.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          The relationship of you and Alltramatic established by this Agreement
          is that of independent parties. Nothing contained in this Agreement in
          intended, or is to be construed, to constitute the party’s as partners
          in the legal sense. Neither party has any express or implied right or
          authority to assume or create any obligations on behalf of or in the
          name of the other party or to bind the other party to any contract,
          agreement, or undertaking with any third party.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          13.3 Governing Law, Venue, and Jurisdiction.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          This Agreement is governed by New York law, excluding its conflicts of
          law rules. Developer irrevocably submits to venue and exclusive
          personal jurisdiction in the federal and state courts in Nassau County, 
          New York, for any dispute arising out of this Agreement, and
          waives all objections to jurisdiction and venue of such courts.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          13.4 Severability.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          If any provision of this Agreement is determined by any court or
          governmental authority to be unenforceable, the parties intend that
          this Agreement be enforced as if the unenforceable provisions were not
          present and that any partially valid and enforceable provisions be
          enforced to the extent that they are enforceable.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          13.5 Notice.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          Except as expressly provided herein, all notices, requests, demands,
          and other communications under this Agreement will be delivered in
          writing and will be deemed to have been duly given: (i) on the next
          day if delivered personally to the party, (ii) on the date three days
          after mailing if mailed by registered or certified mail, or (iii) on
          the next day if delivered by courier. The parties will send all
          notices to addresses listed in the preamble above or at another
          address as may be provided by a party from time to time.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          13.6 Survival.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%" }}>
          The following provisions of this Agreement survive termination or
          expiration: 1, 4-6, 7.3, 8-10, 12, and 13.
        </p>
        <p className={classes.p} style={{ fontWeight: "bold", textAlign: "left", paddingTop: "2%", paddingBottom: "1%" }}>
          13.7 Entire Agreement.
        </p>
        <p className={classes.p} style={{ textAlign: "justify", width: "97%", paddingBottom: "2%" }}>
          This Agreement constitutes the complete and final agreement of the
          parties pertaining to the subject matter herein and supersedes the
          parties’ prior agreements, understandings and discussions relating to
          subject matter herein. No modification of this Agreement is binding
          unless it is in writing and signed by both parties.
        </p>
      </div>
    </>
  );
}

export default Eula;
