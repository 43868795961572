/***************************************************************************************************
ProProfile.js           Created: 09/28/2022         Rev 4.6

This file is to edit profile details of the user. 

***************************************************************************************************/

// import all the necessary packages
import React from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import {
  onAuthStateChanged,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../components/UserContext";
import useStyles from "../../New Home/Styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../../components/Navbar";
import { auth, db } from "../../../components/Firebase";
import { Autocomplete } from "@mui/material";
import Zipcodedata from "../../../Constant/ZIPCODE_JSON_DATA.json";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  List,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

export default function ProProfile() {
  const [user, setUser] = React.useContext(UserContext);
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [savePressed, setSavePressed] = useState(false);
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("xxxxxx");
  const [oldPassword, setOldPassword] = useState("yyyyyy");
  const [checkPassword, setCheckPassword] = useState("zzzzzz");
  const [zipcode, setZipcode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const history = useHistory();
  // const [daysSelected, setDaysSelected] = React.useState({
  //   D0_SUN: false,
  //   D1_MON: false,
  //   D2_TUE: false,
  //   D3_WED: false,
  //   D4_THU: false,
  //   D5_FRI: false,
  //   D6_SAT: false,
  // });
  const [areasWorking, setAreasWorking] = React.useState([]);
  const [validateData, setValidateData] = useState({});


  useEffect(() => {
    getUserDetails();
  }, [savePressed]); // eslint-disable-line react-hooks/exhaustive-deps

  // Funtion to check if user has logged in or out
  useEffect(() => {
    function checkUser() {
      localStorage.setItem("user", JSON.stringify(user));
      onAuthStateChanged(auth, async (userCred) => {
        if (userCred) {
          setUser(userCred);
          setUserId(userCred.uid);
          return;
        } else {
          setUser(null);
          console.log("No user");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  

  // const handleDaysSelected = (event) => {
  //   setDaysSelected({
  //     ...daysSelected,
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  //  Function used to get all the details from the user
  const getUserDetails = () => {
    localStorage.setItem("user", JSON.stringify(user));
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userInfo = await getDoc(doc(db, "ProCompany", user.uid));
        if (userInfo.exists()) {
          setFirstName(userInfo.data().OwnerInfo["FirstName"]);
          setLastName(userInfo.data().OwnerInfo["LastName"]);
          setEmail(userInfo.data().OwnerInfo["Email"]);
          // setDaysSelected(userInfo.data()["DaysOfWork"]);
          // setCompanyName(userInfo.data().CompanyInfo["Name"]);
          // setAddress(userInfo.data().CompanyInfo["Address"]);
          // setZipcode(userInfo.data().CompanyInfo["Zipcode"]);
          // setPhone(userInfo.data().CompanyInfo["Phone"]);
          // setAreasWorking(userInfo.data()["AreasOfWork"]);
          var dictValidation = {
            firstName: userInfo.data().OwnerInfo["FirstName"],
            lastName: userInfo.data().OwnerInfo["LastName"],
            // daysSelected: userInfo.data()["DaysSelected"],
            // companyName: userInfo.data().CompanyInfo["Name"],
            // address: userInfo.data().CompanyInfo["Address"],
            // zipcode: userInfo.data().CompanyInfo["Zipcode"],
            // phone: userInfo.data().CompanyInfo["Phone"],
            // areasOfWork: userInfo.data()["AreasOfWork"],
          };
          setValidateData(dictValidation);
        } else {
          console.log("No such document");
        }
      } else {
        console.log("No user");
      }
    });
  };

  // Function to save the changed details
  const handleSave = async () => {
    // toast.warning(`Saving changes`, { autoClose: 2000 });
    if (
      firstName === validateData.firstName &&
      lastName === validateData.lastName &&
      phone === validateData.phone &&
      companyName === validateData.companyName &&
      zipcode === validateData.zipcode &&
      // JSON.stringify(daysSelected) ===
      //   JSON.stringify(validateData.daysSelected) &&
      address === validateData.address &&
      areasWorking === validateData.areasOfWork
    ) {
      console.log("not changed");
      return;
    } else {
      const Info = await doc(db, "ProCompany", userId);
      setDoc(
        Info,
        {
          CompanyInfo: {
            Name: companyName,
            Address: address,
            Zipcode: zipcode,
            Phone: phone,
          },
          OwnerInfo: {
            FirstName: firstName,
            LastName: lastName,
          },
          // DaysOfWork: daysSelected,
          AreasOfWork: areasWorking,
        },
        { merge: true }
      );
      toast.info("Information updated");
      setSavePressed(Math.random().toString(36).slice(2));
    }

    if (oldPassword !== password) {
      if (password === checkPassword) {
        if (checkPassword.length > 0) {
          const credential = EmailAuthProvider.credential(
            user.email,
            oldPassword
          );
          reauthenticateWithCredential(user, credential)
            .then(() => {
              updatePassword(user, checkPassword)
                .then(() => {
                  console.log("Password updated");
                  console.log(checkPassword);
                  signOut(auth)
                    .then(() => {
                      // Sign-out successful.
                      console.log("signed out");
                      setUser(null);
                      localStorage.removeItem("user");
                      history.push("/login");
                    })
                    .catch((error) => {
                      // An error happened.
                      console.log(error);
                    });
                })
                .catch((error) => {
                  // An error ocurred
                  // ...
                  console.log("Error occurred");
                  console.log(error);
                });
              // User re-authenticated.
            })
            .catch((error) => {
              // An error ocurred
              // ...
            });
        }
      } else {
        return;
      }
      // setSavePressed(Math.random().toString(36).slice(2));
    } else {
      setOldPassword(" ");
      setPassword(" ");
      setCheckPassword(" ");
      toast.error(`old and New password are similar`, { autoClose: 2000 });
    }
  };

  const handleCancel = () => {
    getUserDetails();
    setSavePressed(false);
  };

  return (
    <>
      <Navbar />
      <ToastContainer />
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h4
          style={{
            fontSize: "30px",
            margin: "0px",
            paddingTop: "30px",
            fontWeight: "bolder",
          }}
        >
          Profile{" "}
        </h4>
        <p
          className={classes.p}
          style={{
            marginBottom: "3%",
          }}
        >
          Update your personal details
        </p>

        {/* <h4
          style={{
            marginTop: "3%",
            marginBottom: "3%",
            textAlign: "left",
            marginLeft: "17vw",
          }}
        >
          Hello,
          <span
            style={{
              marginLeft: "5px",
            }}
          >
            {email}
          </span>
        </h4> */}

        <form
          style={{ display: "grid", placeItems: "center", marginLeft: "2%" }}
        >
          <Grid container xs={10} lg={8} spacing={3}>
            <Grid
              style={{ flexDirection: "column", display: "flex" }}
              xs={12}
              sm={6}
              item
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                First Name
              </label>
              <input
                required
                type="text"
                placeholder=""
                id="fname"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="greyInput"
              ></input>
            </Grid>
            <Grid
              style={{ flexDirection: "column", display: "flex" }}
              xs={12}
              sm={6}
              item
            >
              <label
                htmlFor="lastname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Last Name
              </label>
              <input
                required
                type="text"
                placeholder=""
                id="lname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="greyInput"
              ></input>
            </Grid>

            <Grid
              style={{ flexDirection: "column", display: "flex" }}
              xs={12}
              sm={6}
              item
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Pro Email
              </label>
              <input
                readonly
                type="text"
                placeholder=""
                id="fname"
                value={email}
                // onChange={(e) => setEmail(e.target.value)}
                className="greyInput"
              ></input>
            </Grid>

            <Grid
              style={{ display: "flex", flexDirection: "column" }}
              item
              lg={12}
              sm={12}
              xs={12}
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Old password
              </label>
              <input
                autoComplete="off"
                type="password"
                placeholder="Enter old password"
                id="fname"
                // value={fname}
                onChange={(e) => setOldPassword(e.target.value)}
                className="greyInput1"
              ></input>
            </Grid>

            <Grid
              style={{ flexDirection: "column", display: "flex" }}
              xs={12}
              sm={6}
              item
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                New Password
              </label>
              <input
                type="password"
                placeholder="Enter the new password"
                id="fname"
                onChange={(e) => setPassword(e.target.value)}
                className="greyInput"
              ></input>
            </Grid>

            <Grid
              style={{ flexDirection: "column", display: "flex" }}
              xs={12}
              sm={6}
              item
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Re-enter Password
              </label>
              <input
                type="password"
                placeholder="Re-enter the password"
                id="fname"
                onChange={(e) => setCheckPassword(e.target.value)}
                className="greyInput"
              ></input>
            </Grid>

            <Grid
              style={{ display: "flex", flexDirection: "column" }}
              item
              lg={12}
              sm={12}
              xs={12}
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Company Phone
              </label>
              <input
                required
                type="text"
                id="fname"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="greyInput1"
              ></input>
            </Grid>

            <Grid
              style={{ display: "flex", flexDirection: "column" }}
              item
              lg={12}
              sm={12}
              xs={12}
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Company Name
              </label>
              <input
                required
                type="text"
                placeholder=""
                id="fname"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className="greyInput1"
              ></input>
            </Grid>

            {/* <Grid
              style={{ display: "flex", flexDirection: "column" }}
              item
              lg={12}
              sm={12}
              xs={12}
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Zipcode
              </label>
              <input
                required
                type="text"
                placeholder=""
                id="fname"
                value={zipcode}
                onChange={(e) => setZipcode(e.target.value)}
                className="greyInput1"
              ></input>
            </Grid> */}

            <Grid
              style={{ display: "flex", flexDirection: "column" }}
              item
              lg={12}
              sm={12}
              xs={12}
            >
              <label
                htmlFor="firstname"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Business Address
              </label>
              <input
                required
                type="text"
                placeholder=""
                id="fname"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="greyInput1"
              ></input>
            </Grid>

            {/* <Grid>
              <List
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                    marginLeft: "12px",
                  }}
                >
                  Select minimum 4 days of the week do you operate?
                </label>
                <FormControl
                  sx={{ m: 1 }}
                  error={error}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={D0_SUN}
                          onChange={handleDaysSelected}
                          name="D0_SUN"
                          color="secondary"
                        />
                      }
                      label="Sunday"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={D1_MON}
                          onChange={handleDaysSelected}
                          name="D1_MON"
                        />
                      }
                      label="Monday"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={D2_TUE}
                          onChange={handleDaysSelected}
                          name="D2_TUE"
                        />
                      }
                      label="Tuesday"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={D3_WED}
                          onChange={handleDaysSelected}
                          name="D3_WED"
                        />
                      }
                      label="Wednesday"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={D4_THU}
                          onChange={handleDaysSelected}
                          name="D4_THU"
                        />
                      }
                      label="Thursday"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={D5_FRI}
                          onChange={handleDaysSelected}
                          name="D5_FRI"
                        />
                      }
                      label="Friday"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={D6_SAT}
                          onChange={handleDaysSelected}
                          name="D6_SAT"
                        />
                      }
                      label="Saturday"
                    />
                  </FormGroup>
                  {error ? (
                    <div style={{ color: "red" }}>Select atleast 4 days</div>
                  ) : null}
                </FormControl>
              </List>
            </Grid> */}
            <Grid
              item
              lg={12}
              sm={12}
              xs={12}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {/* <div style={{ display: "grid", placeItems: "center" }}> */}
              <label
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Area of Work
              </label>
              <Autocomplete
                className="signUpInput"
                multiple={true}
                variant="standard"
                id="tags-outlined"
                color="primary"
                value={areasWorking}
                placeholder="Enter all accessible zipcodes"
                style={{ width: "100%" }}
                options={Zipcodedata}
                onChange={(event, value) => {
                  setAreasWorking(value);
                }}
                renderInput={(params) => <TextField required {...params} />}
              />
              {/* </div> */}
            </Grid>
          </Grid>
        </form>
        <div>
          <button
            className={classes.buttonPrimarySmall}
            id="submit"
            onClick={handleSave}
          >
            <span>Save</span>
          </button>

          <button
            className={classes.buttonPrimary2}
            id="submit"
            onClick={handleCancel}
            style={{
              fontSize: "16px",
              marginTop: "3%",
              marginLeft: "3%",
              fontFamily: "Poppins",
            }}
          >
            <span>Cancel</span>
          </button>
        </div>
      </div>
    </>
  );
}
