//                      Login.js
// Purpose: This is the file for Login page.
// Also included with form validator which checks the access level and navigates to the page accordingly

// importing React native components
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

//import functionalities
import { UserContext } from "../../../components/UserContext";
import QANavbar from "../../../components/QANavbar";
import { authQA } from "../../../components/Firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "@firebase/firestore";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import useStyles from "../../New Home/Styles";

//import styling components
import "./Login.css";
import { Alert, AlertTitle } from "@mui/material";
import { Typography } from "@material-ui/core";

function Login() {
  const [userQA, setUserQA] = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [open, setOpen] = React.useState(false);
  const [alertopen, setAlertOpen] = React.useState(true);
  const [error, setError] = React.useState();
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    function checkUser() {
      localStorage.setItem("userQA", JSON.stringify(userQA));
      onAuthStateChanged(authQA, async (userCred) => {
        if (userCred) {
          setUserQA(userCred);
          localStorage.setItem("userQA", JSON.stringify(userQA));
          history.push("/QAdashboard");
          return;
        } else {
          setUserQA(null);
          // history.push("/QAadminLogin");
          return;
        }
      });
    }
    checkUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAlert = () => {
    setAlertOpen(!open);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setError(false);
  };

  //Checks Email Format
  const validateEmail = (email) => {
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  // const validatePassword = (password) => {
  //   if (
  //     password.match(
  //       /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{6,}$/
  //     )
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const handleForgotPassword = () => {
    const auth = getAuth();
    if (validateEmail(recoveryEmail) === false) {
      toast.error("Wrong Email format", {
        autoClose: 1000,
      });
      setOpen(false);
      return;
    } else {
      sendPasswordResetEmail(auth, recoveryEmail)
        .then(() => {
          setOpen(false);
          toast.info("Password reset email sent", {
            autoClose: 1000,
          });
        })
        .catch((error) => {
          const errorMessage = error.message;
          alert(errorMessage);
        });
    }
  };

  //Function executed when signIn button is pressed
  async function SignInButton(e) {
    e.preventDefault();
    if (validateEmail(email) === true 
    // && validatePassword(password) === true
    ) 
    {
    setUserQA(userQA);
    signInWithEmailAndPassword(authQA, email, password)
      .then((userCredential) => {
        history.push("/QAdashboard");
      })
      .catch((err) => {
        switch (err.code) {
          case "auth/Invalid-email":
          case "auth/user-disabled":
          case "auth/user-not-found":
          case "auth/wrong-password":
            setError("Check your email and password");
            break;
          default:
        }
      });
  }
  else if(validateEmail(email)===false){
    // toast.error("Wrong Email format");
    setError("Wrong Email format");
  }
  // else if(validatePassword(password)===false){
  //   // toast.error("Wrong Password format");
  //   setError("Wrong Password format");
  // }
}

  return (
    <div className="login">
      <QANavbar />
      <ToastContainer />
      <div className="login_info">
        <h1 style={{ marginBottom: "50px", marginTop: "20px" }}>Login</h1>
        <form className="login_form">
          <label htmlFor="email" style={{ textAlign: "left" }}>
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            style={{
              maxWidth: "300px",
              fontWeight: "600",
              minWidth: "200px",
              width: "90%",
              borderRadius: "28px",
              minHeight: "40px",
              paddingLeft: "4%",
            }}
            placeholder="Enter the Email"
            onChange={(e) => setEmail(e.target.value)}
          ></input>

          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            placeholder="Enter the Password (Min. 6 letter)"
            style={{
              maxWidth: "300px",
              fontWeight: "600",
              minWidth: "200px",
              width: "90%",
              borderRadius: "28px",
              minHeight: "40px",
              paddingLeft: "4%",
            }}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <button
            className="buttonPrimary"
            style={{
              width: "30%",
              maxWidth: "200px",
              backgroundColor: "#187CAE",
              marginBottom: "10px",
            }}
            onClick={SignInButton}
          >
            {" "}
            Log in{" "}
          </button>
          <div>
            <div
              onClick={handleClickOpen}
              style={{ cursor: "pointer", marginLeft: "10px" }}
            >
              <Typography
                style={{ fontWeight: "550" }}
                variant="body2"
                color="primary"
              >
                {" "}
                Forgot Password?{" "}
              </Typography>
            </div>

            {/* Dialog Box for errors */}
            <Dialog
              style={{ padding: "0px", margin: "0px" }}
              open={error}
              onClose={handleAlertClose}
            >
              <div className={classes.card}>
                <Alert
                  severity="warning"
                  role="button"
                  // icon={<Acc />}
                  onClose={handleAlertClose}
                  closeText="Doesn't Work!"
                  sx={{
                    "& .MuiAlert-icon": {
                      color: "white",
                      fontSize: "30px",
                      backgroundImage:
                        "linear-gradient(225deg, #1d2122, #181c1d)",
                      boxShadow:
                        "-5px 5px 15px #000000,10px -10px 40px #FFFFFF",
                      padding: "4%",
                      borderRadius: "50%",
                    },
                    backgroundColor: "transparent",
                  }}
                >
                  <h2 style={{ color: "white" }}>Alert!</h2>
                </Alert>
                <DialogContent style={{ color: "white" }}>
                  {error}
                </DialogContent>
                <DialogActions>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      padding: "0%",
                      border: "2px solid white",
                    }}
                    onClick={handleAlertClose}
                    autoFocus
                  >
                    Okay
                  </Button>
                </DialogActions>
              </div>
            </Dialog>

            {/* Dialog box for Forgot password */}
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>
                <h3>Reset Password</h3>
              </DialogTitle>
              <DialogContent>
                <h4>
                  Enter your email address below and we'll send you a link to
                  reset your password
                </h4>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: {
                      fontWeight: "600",
                      fontSize: "50",
                    },
                  }}
                  InputProps={{
                    style: {
                      fontWeight: "500",
                      fontSize: "40",
                    },
                  }}
                  onChange={(e) => setRecoveryEmail(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  style={{
                    fontWeight: "600",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    fontWeight: "600",
                  }}
                  onClick={handleForgotPassword}
                >
                  Send
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
