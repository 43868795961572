import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Box,
	Button,
	Switch,

} from '@material-ui/core';

import config from "../Config";

import {
	Menu as MenuIcon,
	AccountCircle as AccountCircleIcon,
	GroupAdd,
	Code,
} from '@material-ui/icons';
import ServiceIcon from '@material-ui/icons/RoomService';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CodeIcon from '@material-ui/icons/Code';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	menuButton: {
		marginRight: theme.spacing(2),
		fontSize: '2rem'
	},

	ToggleButton: {
		margin: 'auto',
		fontSize: '2rem'
	},

	title: {
		// flexGrow: 1,
		fontFamily: 'Poppins',
		fontSize: '1.6rem',
		fontWeight: 'bold'
	},
	list: {
		width: 250,
	},
	listItemText: {
		fontFamily: 'Poppins',
		fontSize: '1rem',
		fontWeight: 'bold',
		color: 'black',
	}
}));

function CustomAppBar({ isQAToggled, onToggleQA }) {
	const classes = useStyles();
	const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
	const history = useHistory();

	const toggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};

	const handleDrawerClose = () => {
		setIsDrawerOpen(false);
	};

	const handleToggle = () => {
		onToggleQA(!isQAToggled);
	};

	return (
		<>
			<AppBar position="static">
				<Toolbar>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="menu"
						onClick={toggleDrawer}
					>
						<MenuIcon fontSize="large" />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						Admin Dashboard {isQAToggled ? <span style={{ marginRight: '2rem' }}>QA</span> : <span style={{ marginRight: '2rem' }}>Prod</span>}
					</Typography>
					<Switch style={{ color: 'yellow' }} checked={isQAToggled} onChange={handleToggle} color="default" />

				</Toolbar>
			</AppBar>
			<Drawer open={isDrawerOpen} onClose={handleDrawerClose} style={{ fontSize: '2rem' }}>
				<List className={classes.list} style={{ fontFamily: 'Poppins', fontSize: '1.4rem', fontWeight: 'bold' }}>
					<ListItem
						button
						onClick={() => {
							history.push('/dashboard')
						}}
					>
						<ListItemIcon style={{ color: 'grey', fontSize: '2rem' }}>
							<SupervisorAccountIcon />
						</ListItemIcon>
						<ListItemText primary="Customers" primaryTypographyProps={{ className: classes.listItemText }}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => {
							history.push('/LawnSchedule')
						}}
					>
						<ListItemIcon style={{ color: 'grey', fontSize: '2rem' }}>
							<ServiceIcon />
						</ListItemIcon>
						<ListItemText primary="Service Schedule" primaryTypographyProps={{ className: classes.listItemText }}
						/>
					</ListItem>
					{/* <ListItem
						button
						onClick={() => {
							history.push('/JobComplete')
						}}
					>
						<ListItemIcon style={{ color: 'Green', fontSize: '50px', fontWeight: 'bolder' }}>
							<AttachMoneyIcon />
						</ListItemIcon>
						<ListItemText primary="Job Complete" primaryTypographyProps={{ className: classes.listItemText }}
						/>
					</ListItem> */}
					<ListItem button component={RouterLink} to="/BlogCreate" onClick={() => { setIsDrawerOpen(false); }}>
						<ListItemIcon style={{ color: 'black', fontSize: '2rem' }}>
							<MenuBookIcon />
						</ListItemIcon>
						<ListItemText primary="Create Blog" primaryTypographyProps={{ className: classes.listItemText }}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => {
							window.open('https://lawnber.com/QAUserImpersonation', '_blank');
						}}
					>
						<ListItemIcon style={{ color: 'grey', fontSize: '2rem' }}>
							<SwapHorizIcon />
						</ListItemIcon>
						<ListItemText primary="Login as User" primaryTypographyProps={{ className: classes.listItemText }}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => {
							history.push('/AdminProSignup')
						}}
					>
						<ListItemIcon style={{ fontSize: '50px', fontWeight: 'bolder' }}>
							<GroupAdd />
						</ListItemIcon>
						<ListItemText primary="Pro List" primaryTypographyProps={{ className: classes.listItemText }}
						/>
					</ListItem>
					<ListItem button
						onClick={async () => {
							const { data } = await axios.post(
								config.PRO_SCHEDULE_API,
								{
									"service": "LawnMowing,SpringCleanup,Mulch"
								},
								{
									headers: {
										"Content-Type": "application/json",
										"X-Mode": isQAToggled ? 'QA' : 'PROD',
									},
								}
							)
							alert('API Initiated..');
							setIsDrawerOpen(false);

						}}>
						<ListItemIcon style={{ color: 'black', fontSize: '2rem' }}>
							<CodeIcon />
						</ListItemIcon>
						<ListItemText primary="Weekly API" primaryTypographyProps={{ className: classes.listItemText }}
						/>
					</ListItem>

					<ListItem button component={RouterLink} to="/" onClick={() => { setIsDrawerOpen(false); }}>
						<ListItemIcon style={{ color: 'black', fontSize: '2rem' }}>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary="Logout" primaryTypographyProps={{ className: classes.listItemText }}
						/>
					</ListItem>

				</List>
			</Drawer >
		</>
	);
}

export default CustomAppBar;
